import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss'],
})
export class InformationModalComponent {
  @Output() exit = new EventEmitter<void>();

  close(): void {
    this.exit.emit();
  }
}
