import { AuthService } from '@app/core/services';
import { APP_INITIALIZER, Optional } from '@angular/core';
import { void$ } from '@app/shared/utils';
import { firstValueFrom, switchMap } from 'rxjs';
import { UrlTree } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/store/reducers';
import { CookieToken, CoreRoute } from '@app/core/constants';
import { AuthActions, UserActions } from './core/store/actions';
import { environment } from '@environments/environment';
import { selectUser } from '@app/core/store/selectors/user.selectors';
import { filter, first } from 'rxjs/operators';

export function initializeApp(
  store: Store<AppState>,
  cookieService: CookieService,
  authService?: AuthService
): () => Promise<void | UrlTree> {
  return () => {
    if (authService) {
      const token = cookieService.get(CookieToken) || authService.getToken();
      if (token) {
        authService.setToken(token);
        if (environment.editorPreviewMode) {
          store.dispatch(AuthActions.loginWithToken({ token }));
        } else {
          store.dispatch(UserActions.updateUser());
        }
      }
      if (environment.editorPreviewMode) {
        return firstValueFrom(
          store.select(selectUser).pipe(
            filter((user) => !!user?.externalId),
            first(),
            switchMap(() => void$())
          )
        );
      }
    }
    return firstValueFrom(void$());
  };
}

export function resolveAuthService(): typeof AuthService | Optional {
  return [CoreRoute.Demos, CoreRoute.Infographics].some((coreRoute) =>
    location.href.includes(coreRoute)
  )
    ? new Optional()
    : AuthService;
}

export const appInitializer = {
  provide: APP_INITIALIZER,
  useFactory: initializeApp,
  deps: [Store, CookieService, [resolveAuthService()]],
  multi: true,
};
