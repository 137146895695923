import { createReducer, on } from '@ngrx/store';
import { SelectedTextbookActions } from '../actions';
import { DownloadRegistryUnit } from './download-registry.reducer';

export const selectedTextbookFeatureKey = 'selectedTextbook';

export type SelectedTextbook = DownloadRegistryUnit | null;

export interface SelectedTextbookState {
  textbook: SelectedTextbook;
  isOutdated: boolean;
}

const initialState: SelectedTextbookState = {
  textbook: null as SelectedTextbook,
  isOutdated: false,
};

export const selectedTextbookReducer = createReducer(
  initialState,
  on(SelectedTextbookActions.setSelectedTextbook, (_state, { textbook, isOutdated }) => ({
    textbook,
    isOutdated: !!textbook && !!isOutdated,
  }))
);
