import { createReducer, on } from '@ngrx/store';
import { SystemMaintenanceActions } from '@app/core/store/actions';
import { AppMessage } from '@gwo/textbook-api-client';
import { AppMessagesUtil } from '@app/core/utils/app-messages.util';

export const systemMaintenanceFeatureKey = 'systemMaintenance';

export interface SystemMaintenanceState {
  locked: boolean;
  warningModalVisible: boolean;
  appMessage: AppMessage | null;
}

export const initialState: SystemMaintenanceState = {
  locked: false,
  warningModalVisible: false,
  appMessage: null,
};

export const systemMaintenanceReducer = createReducer(
  initialState,
  on(SystemMaintenanceActions.setAppMessage, (state, { message }) => ({
    ...state,
    appMessage: message,
    locked: AppMessagesUtil.isAppLocked(message),
    warningModalVisible: AppMessagesUtil.isAppLocked(message) ? false : state.warningModalVisible,
  })),
  on(SystemMaintenanceActions.setWarningModalVisibility, (state, { visible }) => ({
    ...state,
    warningModalVisible: visible,
  })),
  on(SystemMaintenanceActions.updateLockStatus, (state, { locked }) => ({
    ...state,
    locked,
    warningModalVisible: locked ? false : state.warningModalVisible,
  }))
);
