import { Textbook } from '@gwo/textbook-api-client';

export class TextbookPageNumberUtil {
  private static readonly LEGEND_PAGE_ORDER = 1;

  public static parseFirstPageNumber(textbook: Textbook): number {
    return TextbookPageNumberUtil.isLegendPageAvailable(textbook)
      ? textbook.firstPageNumber - TextbookPageNumberUtil.LEGEND_PAGE_ORDER
      : textbook.firstPageNumber;
  }

  public static getOriginalFirstPageNumber(parsedTextbook: Textbook): number {
    return TextbookPageNumberUtil.isLegendPageAvailable(parsedTextbook)
      ? parsedTextbook.firstPageNumber + TextbookPageNumberUtil.LEGEND_PAGE_ORDER
      : parsedTextbook.firstPageNumber;
  }

  private static isLegendPageAvailable(textbook: Textbook): boolean {
    return !textbook?.infographic;
  }
}
