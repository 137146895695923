<app-modal-layout
  [maxWidth]="!!optionalOtion ? '32rem' : '22rem'"
  marginTop="2.5rem"
  (backdropClick)="onExit()"
>
  <div class="content">
    <button class="close-btn" (click)="onExit()">
      <app-icon name="close"></app-icon>
    </button>

    <p class="header"><ng-content select="#header"></ng-content></p>

    <p class="question">
      <ng-content select="#question"></ng-content>
    </p>

    <div>
      <button class="yes" (click)="onExit(UpdateUserDataConfirmation.YES)">Tak</button>
      <button class="no" (click)="onExit(UpdateUserDataConfirmation.NO)">{{ declineLabel }}</button>
      <button
        *ngIf="!!optionalOtion"
        class="no"
        (click)="onExit(UpdateUserDataConfirmation.OPTIONAL_OPTION)"
      >
        {{ optionalOtion }}
      </button>
    </div>
  </div>
</app-modal-layout>
