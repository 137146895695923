import {
  TextbookClientEndpoint,
  TextbookClientEndpointEnum,
  UrlParams as TextbookClientUrlParams,
} from '@gwo/textbook-api-client';
import { HttpRequest } from '@angular/common/http';
import { environment } from '@environments/environment';
import { URL_PARTS_SEPARATOR } from '@app/textbook/utils/url-manipulation-util';

export const ACCESS_ID_QUERY_PARAM = 'accessId';

export const OFFLINE_CONTENT_ENDPOINTS = {
  resource: [TextbookClientEndpointEnum.GET_RESOURCE],
  drawings: [TextbookClientEndpointEnum.GET_ACTION_DRAWINGS],
  actionNotes: [TextbookClientEndpointEnum.GET_ACTION_NOTE],
  users: [
    TextbookClientEndpointEnum.GET_AUTHENTICATED_USER,
    TextbookClientEndpointEnum.RECREATE_AUTHENTICATED_USER,
  ],
  textualPages: [TextbookClientEndpointEnum.GET_TEXTBOOK_TEXTUAL_PAGES],
  pages: [TextbookClientEndpointEnum.GET_TEXTBOOK_PAGES],
  markers: [TextbookClientEndpointEnum.GET_TEXTBOOK_MARKERS],
  notes: [TextbookClientEndpointEnum.GET_TEXTBOOK_NOTES],
  actionTriggers: [TextbookClientEndpointEnum.GET_TEXTBOOK_ACTION_TRIGGERS],
  legend: [TextbookClientEndpointEnum.GET_TEXTBOOK_LEGEND],
  textbooks: [TextbookClientEndpointEnum.GET_TEXTBOOKS, TextbookClientEndpointEnum.GET_TEXTBOOK],
  action: [TextbookClientEndpointEnum.GET_TEXTBOOK_ACTION_BY_ID],
  groups: [TextbookClientEndpointEnum.GET_TEXTBOOK_ACTION_GROUPS],
};

export const MUTATION_ENDPOINTS = {
  markerPost: TextbookClientEndpointEnum.CREATE_MARKER,
  markerDelete: TextbookClientEndpointEnum.DELETE_MARKER,
  notePost: TextbookClientEndpointEnum.CREATE_NOTE,
  noteDelete: TextbookClientEndpointEnum.DELETE_NOTE,
  notePatch: TextbookClientEndpointEnum.UPDATE_NOTE,
  drawingsPost: TextbookClientEndpointEnum.CREATE_DRAWING,
  drawingsPatch: TextbookClientEndpointEnum.UPDATE_DRAWING,
  drawingsDelete: TextbookClientEndpointEnum.DELETE_DRAWING,
  actionNotesPost: TextbookClientEndpointEnum.CREATE_ACTION_NOTE,
  actionNotesPatch: TextbookClientEndpointEnum.UPDATE_ACTION_NOTE,
  actionNotesDelete: TextbookClientEndpointEnum.DELETE_ACTION_NOTE,
};

export const MUTABLE_USER_CONTENT_ENDPOINTS: TextbookClientEndpoint[] = [
  ...OFFLINE_CONTENT_ENDPOINTS.notes,
  ...OFFLINE_CONTENT_ENDPOINTS.markers,
  ...OFFLINE_CONTENT_ENDPOINTS.drawings,
  ...OFFLINE_CONTENT_ENDPOINTS.actionNotes,
];

export const PROJECTION_ENDPOINTS: TextbookClientEndpoint[] = [
  ...OFFLINE_CONTENT_ENDPOINTS.users,
  TextbookClientEndpointEnum.GET_TEXTBOOKS,
];

export const DOWNLOAD_IN_BACKGROUND_ENDPOINTS: TextbookClientEndpoint[] = [
  TextbookClientEndpointEnum.GET_RESOURCES,
  TextbookClientEndpointEnum.GET_TEXTBOOK_ACTIONS,
  TextbookClientEndpointEnum.GET_TEXTBOOK_PAGES,
  TextbookClientEndpointEnum.GET_TEXTBOOK_TEXTUAL_PAGES,
  TextbookClientEndpointEnum.GET_TEXTBOOK_ACTION_GROUPS,
  TextbookClientEndpointEnum.GET_TEXTBOOK_ACTION_TRIGGERS,
  TextbookClientEndpointEnum.GET_TEXTBOOK_LEGEND,
  TextbookClientEndpointEnum.GET_TEXTBOOK_MARKERS,
  TextbookClientEndpointEnum.GET_TEXTBOOK_NOTES,
  TextbookClientEndpointEnum.GET_TEXTBOOK_ACTION_NOTES,
  TextbookClientEndpointEnum.GET_TEXTBOOK_DRAWINGS,
];

export class OfflineContentUtil {
  private static readonly QUERY_PARAMS_START_CHAR = '?';

  public static isAnyMatching(
    request: HttpRequest<unknown>,
    endpoints: TextbookClientEndpoint[]
  ): boolean {
    return endpoints.some((endpoint) => OfflineContentUtil.isMatching(request, endpoint));
  }

  public static isMatching(
    request: HttpRequest<unknown>,
    endpoint: TextbookClientEndpoint
  ): boolean {
    if (request.url.startsWith(environment.textbookApiUrl) && request.method === endpoint.method) {
      const requestUrl = request.url.split(OfflineContentUtil.QUERY_PARAMS_START_CHAR)[0];
      const requestEndpointUrl = requestUrl.replace(environment.textbookApiUrl, '');
      const splittedRequestEndpointUrl = requestEndpointUrl.split(URL_PARTS_SEPARATOR);
      const splittedEndpointUrl = endpoint.url.split(URL_PARTS_SEPARATOR);
      return (
        splittedRequestEndpointUrl.length === splittedEndpointUrl.length &&
        OfflineContentUtil.isEndpointUrlMatching(splittedRequestEndpointUrl, splittedEndpointUrl)
      );
    }
    return false;
  }

  private static isEndpointUrlMatching(
    splittedRequestEndpointUrl: string[],
    splittedEndpointUrl: string[]
  ): boolean {
    return !splittedEndpointUrl.some(
      (urlPart, index) =>
        !OfflineContentUtil.isUrlPartMatching(urlPart, splittedRequestEndpointUrl[index])
    );
  }

  private static isUrlPartMatching(clientEndpointUrlPart: string, requestUrlPart: string): boolean {
    return (
      OfflineContentUtil.isTextbookClientParam(clientEndpointUrlPart) ||
      clientEndpointUrlPart == requestUrlPart
    );
  }

  private static isTextbookClientParam(textbookClientEndpointUrlPart: string): boolean {
    return Object.values(TextbookClientUrlParams).some(
      (param) => param === textbookClientEndpointUrlPart
    );
  }
}
