export enum CoreRoute {
  Shelf = '',
  Auth = 'auth',
  Textbooks = 'textbooks',
  Demos = 'demos',
  Infographics = 'infographics',
  LegacyInfographicRedirect = 'player-centrefold',
  Error = 'error',
}

export enum CoreRouteParam {
  TextbookId = 'textbookId',
}

export type PlayerType = CoreRoute.Demos | CoreRoute.Infographics | CoreRoute.Textbooks;
