import { TextbookRecord } from '@app/shelf/models/textbook.model';
import { createReducer, on } from '@ngrx/store';
import { TextbookActions } from '../actions';

export const textbookFeatureKey = 'textbooks';

export interface TextbooksState {
  textbooks: TextbookRecord;
}

const initialState: TextbooksState = {
  textbooks: {},
};

export const textbookReducer = createReducer(
  initialState,
  on(TextbookActions.setTextbooks, (state, { textbooks }) => ({ ...state, textbooks })),
  on(TextbookActions.setTextbook, (state, { index, textbook }) => ({
    ...state,
    textbooks: {
      ...state.textbooks,
      [index]: textbook,
    },
  }))
);
