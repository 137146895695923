<app-shell>
  <router-outlet main></router-outlet>
</app-shell>
<app-full-screen-modal *ngIf="(appLocked$ | async) === true" [zIndex]="9999">
  <div class="app-lock-container flex-center">
    <div class="message-container" [innerHTML]="htmlMessage$ | async"></div>
    <div class="buttons-container">
      <button
        *ngIf="(applicationStoreButtonVisible$ | async) === true"
        data-cy="app-update-button"
        class="app-message-button"
        type="button"
        (click)="openMobileStore()"
      >
        Aktualizuj
      </button>
    </div>
  </div>
</app-full-screen-modal>
<app-info-modal-layout
  *ngIf="(warningModalVisible$ | async) === true"
  [closeDisabled]="(warningModalCloseDisabled$ | async) === true"
  (closeClick)="onWarningClose()"
>
  <ng-container main>
    <div class="message-container" [innerHTML]="htmlMessage$ | async"></div>
  </ng-container>
  <ng-container footer>
    <div class="buttons-container flex-around">
      <button
        *ngIf="(applicationStoreButtonVisible$ | async) === true"
        data-cy="app-update-button"
        class="app-message-button"
        type="button"
        (click)="openMobileStore()"
      >
        Aktualizuj
      </button>
      <button
        data-cy="warning-close-button"
        class="app-message-button"
        type="button"
        [disabled]="(warningModalCloseDisabled$ | async) === true"
        [class.message-button-disabled]="(warningModalCloseDisabled$ | async) === true"
        (click)="onWarningClose()"
      >
        Zamknij
        <span *ngIf="warningModalCloseTimerValue$ | async as warningModalCloseTimerValue">
          ({{ warningModalCloseTimerValue }})
        </span>
      </button>
    </div>
  </ng-container>
</app-info-modal-layout>
