import { environment } from '@environments/environment';

// Maksymalnie prosta implementacja po to żeby tylko zamknąć sterowanie logami w jednym miejscu.
export class Logger implements Partial<Console> {
  log(...data: unknown[]): void {
    if (environment.logging) {
      console.log(...data);
    }
  }

  info(...data: unknown[]): void {
    if (environment.logging) {
      const styles = ['color: red', 'background: yellow'].join(';');
      console.log('%c%s', styles, ...data);
    }
  }

  warn(...data: unknown[]): void {
    if (environment.logging) {
      console.warn(...data);
    }
  }

  error(...data: unknown[]): void {
    if (environment.logging) {
      console.error(...data);
    }
  }

  group(...label: unknown[]): void {
    if (environment.logging) {
      console.group(...label);
    }
  }

  groupEnd(): void {
    if (environment.logging) {
      console.groupEnd();
    }
  }
}

export const logger = new Logger();
