import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { MobileDetectToken } from '@app/core/providers/mobile-detect.provider';
import * as MobileDetect from 'mobile-detect';
import { Subject } from 'rxjs';
import { MobileDetectUtil } from '@app/core/utils/mobile-detect.util';
import { UtilitiesService } from '@app/core/services';

@Component({
  selector: 'app-mobile-portrait-blocker',
  templateUrl: './mobile-portrait-blocker.component.html',
  styleUrls: ['./mobile-portrait-blocker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobilePortraitBlockerComponent implements AfterViewInit, OnDestroy {
  @ViewChild('mobileOrientationBlocker')
  public readonly mobileOrientationBlockerEl?: ElementRef<HTMLElement>;
  private intersectionObserver!: IntersectionObserver;

  constructor(
    @Inject(MobileDetectToken) public mobileDetect: MobileDetect,
    private utilitiesService: UtilitiesService
  ) {}

  readonly verticalViewRatio = 1.7777777777777777;
  verticalViewAllowed$ubject = new Subject<boolean>();

  get isIOSRequestingDesktopVersion(): boolean {
    return MobileDetectUtil.isIOSRequestingDesktopVersion();
  }

  ngAfterViewInit(): void {
    const { availWidth, availHeight } = window.screen;
    const [width, height] = [availWidth, availHeight].sort((a, b) => b - a);
    const ratio = width / height;
    this.verticalViewAllowed$ubject.next(ratio <= this.verticalViewRatio);

    this.updateMobileOrientationBlockerVisibility();
  }

  private updateMobileOrientationBlockerVisibility(): void {
    this.intersectionObserver = new IntersectionObserver(
      (mutationsList: IntersectionObserverEntry[]) => {
        const isVisibleMobileOrientationBlocker = mutationsList[0].isIntersecting;
        this.utilitiesService.updateMobileBlockerVisibility(isVisibleMobileOrientationBlocker);
      }
    );

    if (this.mobileOrientationBlockerEl) {
      this.intersectionObserver.observe(this.mobileOrientationBlockerEl.nativeElement);
    }
  }

  public ngOnDestroy(): void {
    this.intersectionObserver.disconnect();
  }
}
