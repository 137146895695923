import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userFeatureKey, UserState } from '@app/core/store/reducers/user.reducer';
import { AppState } from '@app/core/store/reducers';
import { User } from '@gwo/textbook-api-client/lib/interface/user.model';
import { selectTextbookAccessId } from '@app/core/store/selectors/router-state.selectors';
import { AccessLevel } from '@gwo/textbook-api-client/lib/interface/access-level.model';
import { TextbookAccess } from '@gwo/textbook-api-client/lib/interface/textbook-access.model';

export const selectUserState = createFeatureSelector<UserState>(userFeatureKey);

export const selectUser = createSelector<AppState, UserState, User>(
  selectUserState,
  ({ user }) => user
);

export const selectAllAccesses = createSelector<AppState, User, TextbookAccess[]>(
  selectUser,
  ({ textbookAccesses }) => textbookAccesses
);

export const selectAccess = createSelector<AppState, User, number, TextbookAccess | undefined>(
  selectUser,
  selectTextbookAccessId,
  (user, id) => {
    return user?.textbookAccesses.find(({ accessId }) => accessId === id);
  }
);

export const selectAccessLevel = createSelector<AppState, TextbookAccess | undefined, AccessLevel>(
  selectAccess,
  (access) => {
    return access ? access.accessLevel : AccessLevel.Teacher;
  }
);

export const selectIsPremiumAccess = createSelector<AppState, AccessLevel, boolean>(
  selectAccessLevel,
  (accessLevel) => accessLevel === AccessLevel.Teacher || accessLevel === AccessLevel.StudentPremium
);
