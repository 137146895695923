import { Injectable } from '@angular/core';
import { nullify } from '@app/shared/utils/nullify';
import { TextbookMorph, TextbookRecord } from '@app/shelf/models/textbook.model';
import { Store } from '@ngrx/store';
import { first, map, Observable } from 'rxjs';
import { DownloadRegistryActions } from '../actions';
import {
  DownloadRegistry,
  DownloadRegistryUnit,
  DownloadUnitStatus,
  ShelfState,
} from '../reducers';
import { DownloadRegistrySelectors } from '../selectors';
import { User } from '@gwo/textbook-api-client/lib/interface/user.model';

@Injectable()
export class DownloadRegistryFacade {
  constructor(private readonly store: Store<ShelfState>) {}

  initialize(textbooks: TextbookRecord, userExternalId: User['externalId']): void {
    this.store.dispatch(DownloadRegistryActions.initialize({ textbooks, userExternalId }));
  }

  startDownload(textbook: TextbookMorph): void {
    this.store.dispatch(DownloadRegistryActions.startDownload({ textbook }));
  }

  stopDownload(index: string): void {
    this.store.dispatch(DownloadRegistryActions.stopDownload({ index }));
  }

  downloadStopped(index: string): void {
    this.store.dispatch(DownloadRegistryActions.downloadStopped({ index }));
  }

  updateUserData(textbook: TextbookMorph): void {
    this.store.dispatch(DownloadRegistryActions.updateUserData({ textbook }));
  }

  updateTextbookData(textbook: TextbookMorph): void {
    this.store.dispatch(DownloadRegistryActions.updateTextbookData({ textbook }));
  }

  deleteTextbook(textbook: TextbookMorph): void {
    this.store.dispatch(DownloadRegistryActions.deleteTextbook({ textbook }));
  }

  setDownloadRegistry(registry: DownloadRegistry): void {
    this.store.dispatch(DownloadRegistryActions.setDownloadRegistry({ registry }));
  }

  selectDownloadRegistry$(): Observable<DownloadRegistry> {
    return this.store.select(DownloadRegistrySelectors.selectDownloadRegistry);
  }

  selectDownloadRegistryUnit$(index: string): Observable<DownloadRegistryUnit> {
    return this.selectDownloadRegistry$().pipe(map((registry) => registry[index]));
  }

  getDownloadRegistry(): Observable<DownloadRegistry> {
    return this.store.select(DownloadRegistrySelectors.selectDownloadRegistry).pipe(first());
  }

  getDownloadRegistryUnit(index: string): Observable<DownloadRegistryUnit> {
    return this.getDownloadRegistry().pipe(map((registry) => registry[index]));
  }

  isLoadedRegistryUnit(accessId: string): Observable<boolean> {
    return this.getDownloadRegistryUnit(accessId).pipe(
      map((registry: DownloadRegistryUnit) => registry?.status === DownloadUnitStatus.downloaded)
    );
  }

  setDownloadRegistryUnit(unit: DownloadRegistryUnit): Observable<void> {
    return this.selectDownloadRegistry$().pipe(
      first(),
      map((registry) => this.setDownloadRegistry({ ...registry, [unit.textbookIndex]: unit }))
    );
  }

  selectDownloadingTextbook$(): Observable<null | DownloadRegistryUnit> {
    return this.selectDownloadRegistry$().pipe(
      map((registry) =>
        Object.values(registry).find((unit) => unit.status === DownloadUnitStatus.process)
      ),
      nullify()
    );
  }

  getDownloadingTextbook(): Observable<null | DownloadRegistryUnit> {
    return this.selectDownloadingTextbook$().pipe(first());
  }
}
