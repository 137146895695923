import { Injectable } from '@angular/core';
import { Textbook, TextbookAction, TextbookClient, TextbookPage } from '@gwo/textbook-api-client';
import { Observable, of } from 'rxjs';
import { TextbookLegend } from '@gwo/textbook-api-client/lib/interface/textbook-legend.model';
import { ImageSize } from '@gwo/textbook-api-client/lib/interface/image-size';
import { TextbookActionGroup } from '@gwo/textbook-api-client/lib/interface/textbook-action-group.model';
import { Drawing } from '@gwo/textbook-api-client/lib/interface/drawing.model';
import { BaseManagerApi } from '@app/core/providers/textbook-manager.provider';
import { TextbookTextualPage } from '@gwo/textbook-api-client/lib/interface/textbook-textual-page.model';
import { ActionTrigger } from '@gwo/textbook-api-client/lib/actions';
import { Marker } from '@gwo/textbook-api-client/lib/interface/marker.model';
import { PageNote } from '@gwo/textbook-api-client/lib/interface/page-note.model';
import { ActionNote } from '@gwo/textbook-api-client/lib/interface/action-note.model';
import { SingleNote } from '@gwo/textbook-api-client/lib/interface/single-note.model';

@Injectable()
export class TextbookInfographicManagerService implements BaseManagerApi {
  constructor(private textbookClient: TextbookClient) {}

  getTextbook$(textbookId: string): Observable<Textbook> {
    return this.textbookClient.getInfographic$(textbookId);
  }

  getTextbookPages$(textbookId: string, textbookVersion: number): Observable<TextbookPage[]> {
    return this.textbookClient.getInfographicPages$(textbookId, textbookVersion);
  }

  getTextbookTextualPages$(
    textbookId: string,
    textbookVersion: number
  ): Observable<TextbookTextualPage[]> {
    return this.textbookClient.getInfographicTextualPages$(textbookId, textbookVersion);
  }

  getTextbookActions$(
    textbookId: string,
    textbookVersion: number
  ): Observable<{ [actionId: string]: TextbookAction }> {
    return this.textbookClient.getInfographicActions$(textbookId, textbookVersion);
  }

  //  TODO: Do upewnienia się czy nie potrzeba osobnego endpointu na infografiki/demo
  getTextbookActionById$(
    textbookId: string,
    actionId: string,
    textbookVersion: number
  ): Observable<TextbookAction> {
    return this.textbookClient.getInfographicActionById$(textbookId, actionId, textbookVersion);
  }

  getResource$(resourceId: string, imageSize: ImageSize | null = null): Observable<Blob> {
    return this.textbookClient.getResource$(resourceId, imageSize);
  }

  getResourceUrl$(resourceId: string): Observable<string> {
    return of(this.textbookClient.getResourceUrl(resourceId));
  }

  // @TODO: remove after backend implementation
  getTextbookPageByTextbookIdAndPageNumber$(
    textbookId: string,
    textbookVersion: number,
    pageNumber: number
  ): Observable<TextbookPage> {
    return this.textbookClient.getTextbookPageByTextbookIdAndPageNumber$(
      textbookId,
      textbookVersion,
      pageNumber
    );
  }

  getTextbookLegend$(textbookId: string, textbookVersion: number): Observable<TextbookLegend> {
    return this.textbookClient.getInfographicLegend$(textbookId, textbookVersion);
  }

  createDrawing$(accessId: number, actionId: string, content: string): Observable<Drawing> {
    return this.textbookClient.createDrawing$(accessId, actionId, content);
  }

  updateDrawing$(drawingId: string, accessId: number, content: string): Observable<Drawing> {
    return this.textbookClient.updateDrawing$(drawingId, accessId, content);
  }

  deleteDrawing$(drawingId: string, accessId: number): Observable<unknown> {
    return this.textbookClient.deleteDrawing$(drawingId, accessId);
  }

  createActionNote$(
    accessId: number,
    actionId: string,
    notes: SingleNote[]
  ): Observable<ActionNote> {
    return this.textbookClient.createActionNote$(accessId, actionId, notes);
  }

  updateActionNote$(
    actionNoteId: string,
    accessId: number,
    notes: SingleNote[]
  ): Observable<ActionNote> {
    return this.textbookClient.updateActionNote$(actionNoteId, accessId, notes);
  }

  deleteActionNote$(noteId: string, accessId: number): Observable<unknown> {
    return this.textbookClient.deleteActionNote$(noteId, accessId);
  }

  getTextbookPremiumRedirection$(textbookId: string): Observable<{ link: string }> {
    return this.textbookClient.getTextbookPremiumRedirection$(textbookId);
  }

  getTextbookActionTriggers$(
    textbookId: string,
    textbookVersion: number
  ): Observable<ActionTrigger[]> {
    return this.textbookClient.getInfographicActionTriggers$(textbookId, textbookVersion);
  }

  getTextbookMarkers$(textbookId: string): Observable<Marker[]> {
    return this.textbookClient.getTextbookMarkers$(textbookId);
  }

  getTextbookNotes$(textbookId: string): Observable<PageNote[]> {
    return this.textbookClient.getTextbookNotes$(textbookId);
  }

  getTextbookActionGroups$(
    textbookId: string,
    textbookVersion: number
  ): Observable<TextbookActionGroup[]> {
    return this.textbookClient.getInfographicActionGroups$(textbookId, textbookVersion);
  }
}
