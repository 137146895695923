import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ShelfState } from '../reducers';
import { shelfFeatureKey } from '../shelf-store.module';

const selectSelectedTextbookState = createFeatureSelector<ShelfState>(shelfFeatureKey);

export const selectSelectedTextbook = createSelector(
  selectSelectedTextbookState,
  ({ selectedTextbook }) => selectedTextbook.textbook
);

export const isSelectedTextbookOutdated = createSelector(
  selectSelectedTextbookState,
  ({ selectedTextbook }) => selectedTextbook.isOutdated
);
