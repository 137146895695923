<svg [attr.viewBox]="viewBox">
  <circle
    fill="transparent"
    cx="50%"
    cy="50%"
    [attr.stroke]="color"
    [attr.stroke-width]="thickness"
    [attr.r]="radius"
    [attr.stroke-dasharray]="strokeDasharray"
    [attr.stroke-dashoffset]="strokeDashoffset$ | async"
  />

  <text
    x="50%"
    y="50%"
    text-anchor="middle"
    alignment-baseline="middle"
    font-size="26"
    font-weight="bold"
    [attr.fill]="color"
  >
    {{ percent$ | async }}
  </text>
</svg>
