export const URL_PARTS_SEPARATOR = '/';

const QUERY_PARAMS_SEPARATOR = '?';
const HASH_CHAR = '#';

export class UrlManipulationUtil {
  public static isUrlContainingMultipleHashChars(url: string): boolean {
    const queryParamsIndex = url.indexOf(QUERY_PARAMS_SEPARATOR);
    const coreUrl = url.slice(0, queryParamsIndex);

    const splittedUrl = coreUrl.split(URL_PARTS_SEPARATOR);
    const lastPartOfUrl = splittedUrl[splittedUrl.length - 1];

    return (lastPartOfUrl.match(new RegExp(HASH_CHAR, 'g')) || []).length > 1;
  }

  public static truncateToSingleHashChar(url: string): string {
    if (!UrlManipulationUtil.isUrlContainingMultipleHashChars(url)) {
      return url;
    }

    const queryParamsIndex = url.indexOf(QUERY_PARAMS_SEPARATOR);
    const coreUrl = queryParamsIndex !== -1 ? url.slice(0, queryParamsIndex) : url;
    const queryParams = queryParamsIndex !== -1 ? url.slice(queryParamsIndex) : null;

    const splittedUrl = coreUrl.split(URL_PARTS_SEPARATOR);
    const lastPartOfUrl = splittedUrl[splittedUrl.length - 1];

    const lastPartSplittedByHash = lastPartOfUrl.split(HASH_CHAR);
    const truncatedLastPartOfUrl =
      lastPartSplittedByHash[0] + HASH_CHAR + lastPartSplittedByHash[1];
    const resultUrl = [...splittedUrl];
    resultUrl[resultUrl.length - 1] = truncatedLastPartOfUrl;
    return resultUrl.join(URL_PARTS_SEPARATOR) + (queryParams?.length ? `${queryParams}` : '');
  }
}
