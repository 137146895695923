import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

export function fadeInOut(
  enterDuration = '100ms',
  leaveDuration = enterDuration
): AnimationTriggerMetadata {
  return trigger('fadeInOut', [
    transition(':enter', [style({ opacity: 0 }), animate(enterDuration, style({ opacity: 1 }))]),
    transition(':leave', [style({ opacity: 1 }), animate(leaveDuration, style({ opacity: 0 }))]),
  ]);
}
