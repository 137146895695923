import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DownloadRegistryFacade } from '@app/shelf/store/facade';
import { DownloadRegistryUnit, DownloadUnitStatus } from '@app/shelf/store/reducers';
import { Observable, shareReplay } from 'rxjs';
import { TextbookMorph } from '@app/shelf/models/textbook.model';
import * as dayjs from 'dayjs';
import { AccessStatus } from '@gwo/textbook-api-client/lib/interface/access-status.model';
import { map } from 'rxjs/operators';
import { TextbookStatusUtil } from '@app/textbook/utils/textbook-status-util';

@Component({
  selector: 'app-cover',
  templateUrl: './cover.component.html',
  styleUrls: ['./cover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverComponent implements OnInit, OnChanges {
  constructor(private readonly downloadRegistryFacade: DownloadRegistryFacade) {}

  @Input() textbook!: TextbookMorph;
  @Input() src!: string;
  @Input() isConnected = true;

  @Output() openTextbook = new EventEmitter<void>();
  @Output() stopDownload = new EventEmitter<void>();
  @Output() download = new EventEmitter<void>();
  @Output() showDetails = new EventEmitter<string>();
  @Output() downloadModalVisible = new EventEmitter<void>();
  @Output() deleteDownloaded = new EventEmitter<void>();
  @Output() showExhaustedDownloadLimitModal = new EventEmitter<void>();
  @Output() updateUserData = new EventEmitter<void>();

  textbookRegistry$?: Observable<DownloadRegistryUnit>;
  canBeDownloaded$?: Observable<boolean>;
  canBeUpdated$?: Observable<boolean | undefined>;
  isExhaustedDownloadLimit = false;
  isExpired = false;

  readonly downloadUnitStatus = DownloadUnitStatus;
  readonly accessStatusFinished = AccessStatus.Finished;
  isDownloaded$?: Observable<boolean>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.textbook && changes.textbook.currentValue) {
      this.checkDownloadLimit(changes.textbook.currentValue);
    }
  }

  checkDownloadLimit(textbook: TextbookMorph): void {
    this.isExhaustedDownloadLimit = textbook.access.downloads >= textbook.access.downloadsLimit;
  }

  ngOnInit(): void {
    this.checkIfTextbookExpired();
    this.textbookRegistry$ = this.downloadRegistryFacade
      .selectDownloadRegistryUnit$(this.textbook.index)
      .pipe(shareReplay(1));

    this.isDownloaded$ = this.textbookRegistry$?.pipe(
      map((unit) => unit?.status === DownloadUnitStatus.downloaded)
    );

    this.canBeUpdated$ = this.downloadRegistryFacade
      .selectDownloadRegistryUnit$(this.textbook.index)
      .pipe(
        map((unit) => {
          if (TextbookStatusUtil.isFullUpdateAvailable(unit, this.textbook)) {
            return true;
          }
          return false;
        })
      );

    this.canBeDownloaded$ = this.downloadRegistryFacade
      .selectDownloadRegistry$()
      .pipe(
        map(
          (registry) =>
            !Object.values(registry).find(
              (unit) =>
                unit.status === DownloadUnitStatus.process ||
                unit.status === DownloadUnitStatus.markedForDrop ||
                unit.status === DownloadUnitStatus.updating
            )
        )
      );
  }

  onOpenTextbook(): void {
    this.checkIfTextbookExpired();
    this.openTextbook.emit();
  }

  tryDownloadTextbook(): void {
    this.isExhaustedDownloadLimit ? this.showExhaustedLimitModal() : this.showDownloadModal();
  }

  showExhaustedLimitModal(): void {
    this.showExhaustedDownloadLimitModal.emit();
  }

  showDownloadModal(): void {
    this.downloadModalVisible.emit();
  }

  onStopDownload(): void {
    this.stopDownload.emit();
  }

  continueDownload(): void {
    this.download.emit();
  }

  showInfo(): void {
    this.showDetails.emit(this.textbook.index);
  }

  onUpdate(): void {
    this.updateUserData.emit();
  }

  delete(): void {
    this.deleteDownloaded.emit();
  }

  private checkIfTextbookExpired(): void {
    const textbookEndDate = this.textbook.access.endDate;
    const parseTextbookEndDate = dayjs(textbookEndDate);
    const presentTime = dayjs();
    this.isExpired =
      parseTextbookEndDate.isBefore(presentTime) ||
      this.textbook.access.accessStatus === AccessStatus.Finished;
  }
}
