import { Drawing } from '@gwo/textbook-api-client/lib/interface/drawing.model';

import { ImageSize } from '@gwo/textbook-api-client/lib/interface/image-size';
import { Marker } from '@gwo/textbook-api-client/lib/interface/marker.model';
import { Textbook, TextbookAction, TextbookPage } from '@gwo/textbook-api-client';
import { Observable } from 'rxjs';
import { PageNote } from '@gwo/textbook-api-client/lib/interface/page-note.model';
import { TextbookLegend } from '@gwo/textbook-api-client/lib/interface/textbook-legend.model';
import { TextbookActionGroup } from '@gwo/textbook-api-client/lib/interface/textbook-action-group.model';
import { ClassProvider, InjectionToken } from '@angular/core';
import { ActionNote } from '@gwo/textbook-api-client/lib/interface/action-note.model';
import { TextbookDemoManagerService } from '@app/core/services/textbook-demo-manager.service';
import { TextbookManagerService } from '@app/core/services/textbook-manager.service';
import { TextbookInfographicManagerService } from '@app/core/services/textbook-infographic-manager.service';
import { TextbookTextualPage } from '@gwo/textbook-api-client/lib/interface/textbook-textual-page.model';
import { ActionTrigger } from '@gwo/textbook-api-client/lib/actions';
import { SingleNote } from '@gwo/textbook-api-client/lib/interface/single-note.model';
import { CoreRoute } from '@app/core/constants';
import { isInactiveDemoRoute } from '@app/errors/constants/routes';
import { URL_PARTS_SEPARATOR } from '@app/textbook/utils/url-manipulation-util';

export interface TextbookManagerApi extends BaseManagerApi {
  updateMarker$(
    markerId: string,
    accessId: number,
    distanceFromTop: number,
    distanceFromLeft: number,
    width: number,
    height: number
  ): Observable<Marker>;
  deleteMarker$(markerId: string, accessId: number): Observable<unknown>;
  getTextbooks$(): Observable<Textbook[]>;
  createMarker$(
    accessId: number,
    pageId: string,
    distanceFromTop: number,
    distanceFromLeft: number,
    width: number,
    height: number
  ): Observable<Marker>;
  createNote$(
    accessId: number,
    pageId: string,
    distanceFromTop: number,
    distanceFromLeft: number,
    content: string
  ): Observable<PageNote>;
  updateNote$(
    noteId: string,
    accessId: number,
    distanceFromTop: number,
    distanceFromLeft: number,
    content?: string
  ): Observable<PageNote>;
  deleteNote$(noteId: string, accessId: number): Observable<unknown>;
  downloadTextbookAccess$(accessId: number): Observable<unknown>;
  uninstallManyTextbookAccesses$(accessIds: number[]): Observable<unknown>;
  runTextbookAccess$(accessId: number): Observable<unknown>;
  getActionNote$(actionId: string): Observable<ActionNote>;
  getAllActionNotes$(): Observable<ActionNote[]>;
  getDrawings$(): Observable<Drawing[]>;
  getActionDrawing$(actionId: string): Observable<Drawing>;
}
export interface BaseManagerApi {
  getTextbook$(textbookId: string, accessId?: number): Observable<Textbook>;
  getTextbookPages$(
    textbookId: string,
    textbookVersion: number,
    accessId?: number
  ): Observable<TextbookPage[]>;
  getTextbookActions$(
    textbookId: string,
    textbookVersion: number,
    accessId?: number
  ): Observable<{ [actionId: string]: TextbookAction }>;
  getTextbookActionById$(
    textbookId: string,
    actionId: string,
    textbookVersion: number
  ): Observable<TextbookAction>;
  getResource$(resourceId: string, imageSize: ImageSize | null): Observable<Blob>;
  getResourceUrl$(resourceId: string): Observable<string>;
  getTextbookPageByTextbookIdAndPageNumber$(
    textbookId: string,
    pageNumber: number,
    accessId?: number
  ): Observable<TextbookPage>;
  getTextbookLegend$(
    textbookId: string,
    textbookVersion: number,
    accessId?: number
  ): Observable<TextbookLegend>;
  getTextbookActionGroups$(
    textbookId: string,
    textbookVersion: number,
    accessId?: number
  ): Observable<TextbookActionGroup[]>;
  getTextbookPremiumRedirection$(textbookId: string): Observable<{ link: string }>;
  getTextbookTextualPages$(
    textbookId: string,
    textbookVersion: number
  ): Observable<TextbookTextualPage[]>;
  createDrawing$(accessId: number, actionId: string, content: string): Observable<Drawing>;
  updateDrawing$(drawingId: string, accessId: number, content: string): Observable<Drawing>;
  deleteDrawing$(drawingId: string, accessId: number): Observable<unknown>;
  createActionNote$(
    accessId: number,
    actionId: string,
    notes: SingleNote[]
  ): Observable<ActionNote>;
  updateActionNote$(
    actionNoteId: string,
    accessId: number,
    notes: SingleNote[]
  ): Observable<ActionNote>;
  deleteActionNote$(noteId: string, accessId: number): Observable<unknown>;
  getTextbookActionTriggers$(
    textbookId: string,
    textbookVersion: number,
    accessId?: number
  ): Observable<ActionTrigger[]>;
  getTextbookMarkers$(textbookId: string): Observable<Marker[]>;
  getTextbookNotes$(textbookId: string): Observable<PageNote[]>;
}

const TEXTBOOK_MANAGER_TOKEN = new InjectionToken<TextbookManagerApi>('TEXTBOOK_MANAGER_TOKEN');

const TextbookDemoManagerProvider: ClassProvider = {
  provide: TEXTBOOK_MANAGER_TOKEN,
  useClass: TextbookDemoManagerService,
};

const TextbookManagerProvider: ClassProvider = {
  provide: TEXTBOOK_MANAGER_TOKEN,
  useClass: TextbookManagerService,
};
const TextbookInfographicManagerProvider: ClassProvider = {
  provide: TEXTBOOK_MANAGER_TOKEN,
  useClass: TextbookInfographicManagerService,
};

export {
  TEXTBOOK_MANAGER_TOKEN,
  TextbookDemoManagerProvider,
  TextbookInfographicManagerProvider,
  TextbookManagerProvider,
};

export function resolveService():
  | typeof TextbookDemoManagerProvider
  | typeof TextbookManagerProvider
  | typeof TextbookInfographicManagerProvider {
  let [, playerType] = location.pathname.split(URL_PARTS_SEPARATOR);
  if (isInactiveDemoRoute(location.pathname)) {
    playerType = CoreRoute.Demos;
  }
  switch (playerType) {
    case CoreRoute.Demos:
      return TextbookDemoManagerProvider;
    case CoreRoute.Infographics:
      return TextbookInfographicManagerProvider;
    default:
      return TextbookManagerProvider;
  }
}
