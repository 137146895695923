import { Injectable } from '@angular/core';
import { UserFacade } from '@app/core/store/facade/user.facade';
import { Encoding } from '@capacitor/filesystem';
import { ActionTrigger, Textbook, TextbookAction, TextbookPage } from '@gwo/textbook-api-client';
import { AccessLevel } from '@gwo/textbook-api-client/lib/interface/access-level.model';
import { Marker } from '@gwo/textbook-api-client/lib/interface/marker.model';
import { PageNote } from '@gwo/textbook-api-client/lib/interface/page-note.model';
import { TextbookLegend } from '@gwo/textbook-api-client/lib/interface/textbook-legend.model';
import { TextbookTextualPage } from '@gwo/textbook-api-client/lib/interface/textbook-textual-page.model';
import { User } from '@gwo/textbook-api-client/lib/interface/user.model';
import { filter, first, from, lastValueFrom, map, Observable } from 'rxjs';
import {
  DATA_CLEANER_META,
  ERROR_LOGS_DIR,
  RESOURCES_DIR,
  TEMPORARY_DIR,
  TEXTBOOK_ACTIONS_DIR,
  TEXTBOOK_ACTIONS_FILE,
  TEXTBOOK_ACTIONS_GROUPS_FILE,
  TEXTBOOK_ACTIONS_TRIGGERS_FILE,
  TEXTBOOK_LEGEND_FILE,
  TEXTBOOK_META_FILE,
  TEXTBOOK_PAGES_FILE,
  TEXTBOOK_TEXTUAL_PAGES_FILE,
  TEXTBOOKS_DIR,
  TEXTBOOKS_PAYLOAD,
  USER_ACTION_DRAWINGS_DIR,
  USER_ACTION_NOTES_DIR,
  USER_MARKERS_FILE,
  USER_NOTES_FILE,
  USER_PAYLOAD,
  USER_TASKS_FILE,
  USERS_DIR,
} from '../constants/filesystem-path';
import { TextbookMorph, TextbookRecord } from '../models/textbook.model';
import { DownloadRegistry, DownloadRegistryUnit, DownloadUnitStatus } from '../store/reducers';
import { FilesystemService } from './filesystem.service';
import { blobToString } from '@app/shared/utils/blobToString';
import { Task } from '../models/task.model';
import { Drawing } from '@gwo/textbook-api-client/lib/interface/drawing.model';
import { ActionNote } from '@gwo/textbook-api-client/lib/interface/action-note.model';
import * as dayjs from 'dayjs';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { TextbookActionGroup } from '@gwo/textbook-api-client/lib/interface/textbook-action-group.model';
import { cloneDeep as _cloneDeep } from 'lodash';

export enum TextbookMetaScope {
  textbook,
  user,
  access,
}

type TextbookActionMap = { [actionId: string]: TextbookAction };

@Injectable()
export class RuntimeService {
  constructor(
    private readonly filesystemService: FilesystemService,
    private readonly userFacade: UserFacade
  ) {}

  async getCurrentUserId(): Promise<string> {
    const user = await lastValueFrom(
      this.userFacade.selectUser$.pipe(
        filter((user) => !!user.externalId),
        first()
      )
    );
    return user.externalId.toString();
  }

  //@INJECTOR SHORTCUT
  get fs(): FilesystemService {
    return this.filesystemService;
  }

  async rename(from: string, to: string): Promise<string> {
    await this.fs.rename(from, to).catch(() => undefined);
    return to;
  }

  getFreeMemory(): Promise<number> {
    return this.fs.getFreeMemory();
  }

  async initDownloadRegistry(textbooks: TextbookRecord): Promise<DownloadRegistry> {
    const registry: DownloadRegistry = {};
    const userId = await this.getCurrentUserId();
    for (const textbook of Object.values(textbooks)) {
      const unit = await this.readTextbookMeta(textbook);
      if (unit && unit?.requestedBy[userId] && unit.textbookIndex === textbook.index) {
        const textbookLevelUnit = await this.readTextbookSharedMeta(textbook.id);
        const accessLevelUnit = await this.readTextbookAccessLevelMeta(textbook);
        if (
          !!textbookLevelUnit &&
          this.isDownloadProgressUpdateRequired(unit, textbookLevelUnit, accessLevelUnit)
        ) {
          await this.updateUnitsDownloadProgress([unit], textbookLevelUnit, accessLevelUnit);
        } else if (unit.status === DownloadUnitStatus.process) {
          unit.status = DownloadUnitStatus.paused;
        } else if (unit.status === DownloadUnitStatus.updating) {
          unit.status = DownloadUnitStatus.downloaded;
        }
        registry[textbook.access.accessId.toString()] = unit;
      }
    }
    return registry;
  }

  async isUpdateRequired(textbook: TextbookMorph): Promise<boolean> {
    const textbookLevelUnit = await this.readTextbookSharedMeta(textbook.id);
    return !!textbookLevelUnit && textbookLevelUnit.version < textbook.version;
  }

  async refreshTextbookDownloadRegistry(
    updatedIndex: string,
    registry: DownloadRegistry
  ): Promise<DownloadRegistry> {
    const registryClone = _cloneDeep(registry);
    const upToDateUnit = registry[updatedIndex];
    const textbookLevelUnit = await this.readTextbookSharedMeta(upToDateUnit?.textbookId);
    if (!!upToDateUnit && !!textbookLevelUnit) {
      const unitsToRefresh = this.getUnitsToRefresh(upToDateUnit, textbookLevelUnit, registryClone);
      await this.updateUnitsDownloadProgress(unitsToRefresh, textbookLevelUnit, null);
    }
    return registryClone;
  }

  private getUnitsToRefresh(
    upToDateUnit: DownloadRegistryUnit,
    textbookLevelUnit: DownloadRegistryUnit,
    registry: DownloadRegistry
  ): DownloadRegistryUnit[] {
    return Object.keys(registry)
      .filter((textbookIndex) => textbookIndex !== upToDateUnit.textbookIndex)
      .filter((textbookIndex) => registry[textbookIndex].textbookId === upToDateUnit.textbookId)
      .map((textbookIndex) => registry[textbookIndex])
      .filter((unit) => {
        return this.isDownloadProgressUpdateRequired(unit, textbookLevelUnit, null);
      });
  }

  private async updateUnitsDownloadProgress(
    unitsToRefresh: DownloadRegistryUnit[],
    textbookLevelUnit: DownloadRegistryUnit,
    accessLevelUnit: DownloadRegistryUnit | null
  ): Promise<void> {
    for (const unit of unitsToRefresh) {
      const downloadProgress = this.getDownloadProgress(unit, textbookLevelUnit, accessLevelUnit);
      await this.updateDownloadProgress(unit, downloadProgress);
    }
  }

  private isDownloadProgressUpdateRequired(
    accessUnit: DownloadRegistryUnit,
    textbookLevelUnit: DownloadRegistryUnit,
    accessLevelUnit: DownloadRegistryUnit | null
  ): boolean {
    const versionUpdateAvailable = textbookLevelUnit.version > accessUnit.version;
    const sharedLevelProgressUpdateAvailable =
      textbookLevelUnit.version === accessUnit.version &&
      textbookLevelUnit.downloadPercent > accessUnit.downloadPercent;
    const accessLevelProgressUpdateAvailable =
      !!accessLevelUnit &&
      accessLevelUnit.version === accessUnit.version &&
      accessLevelUnit.downloadPercent > accessUnit.downloadPercent;
    return (
      versionUpdateAvailable ||
      sharedLevelProgressUpdateAvailable ||
      accessLevelProgressUpdateAvailable
    );
  }

  private getDownloadProgress(
    accessUnit: DownloadRegistryUnit,
    textbookLevelUnit: DownloadRegistryUnit,
    accessLevelUnit: DownloadRegistryUnit | null
  ): DownloadRegistryUnit {
    if (!accessLevelUnit || accessLevelUnit.version < textbookLevelUnit.version) {
      return textbookLevelUnit;
    } else if (!!accessLevelUnit && accessLevelUnit.version === textbookLevelUnit.version) {
      return accessLevelUnit;
    }
    return accessUnit;
  }

  // TODO Po wdrożeniu mechanizmu aktualizacji treści użytkownika wymagana aktualizacja stanu pobrania
  //  podręcznika w folderze użytkownika (zawierającym user content)
  private async updateDownloadProgress(
    accessUnit: DownloadRegistryUnit,
    currentProgress: DownloadRegistryUnit
  ): Promise<void> {
    accessUnit.version = currentProgress.version;
    accessUnit.downloadPercent = currentProgress.downloadPercent;
    accessUnit.downloadQueueLength = currentProgress.downloadQueueLength;
    accessUnit.downloadingChunk = currentProgress.downloadingChunk;
    accessUnit.isUserContentModified = true;
    accessUnit.status =
      currentProgress.status === DownloadUnitStatus.downloaded
        ? DownloadUnitStatus.downloaded
        : DownloadUnitStatus.paused;
    await this.writeTextbookMetaAccessScope(
      accessUnit.textbookId,
      accessUnit.textbookIndex,
      accessUnit
    );
  }

  //@BASIC DATA -> USER | TEXTBOOKS
  writeUserPayload(user: User): Promise<void> {
    return this.fs.writeJSONToDataDir(this.fs.join(TEMPORARY_DIR, USER_PAYLOAD), user);
  }

  async readUserPayload(): Promise<User> {
    const data = await this.fs.readJSONDataFromDataDir<User>(
      this.fs.join(TEMPORARY_DIR, USER_PAYLOAD)
    );
    if (!data) return {} as User;
    return data;
  }

  writeTextbooksPayload(textbooks: Textbook[]): Promise<void> {
    return this.fs.writeJSONToDataDir(this.fs.join(TEMPORARY_DIR, TEXTBOOKS_PAYLOAD), textbooks);
  }

  async readTextbooksPayload(): Promise<Textbook[]> {
    const data = await this.fs.readJSONDataFromDataDir<Textbook[]>(
      this.fs.join(TEMPORARY_DIR, TEXTBOOKS_PAYLOAD)
    );
    if (!data) return [];
    return data;
  }

  async writeTemporaryResource(resourceId: string, blob: Blob): Promise<void> {
    const data = await blobToString(blob);
    return await this.fs.writeToDataDir(this.fs.join(TEMPORARY_DIR, resourceId), data);
  }

  async readTemporaryResource(resourceId: string): Promise<Blob | null> {
    const data = await this.fs.readDataAsBlob(this.fs.join(TEMPORARY_DIR, resourceId));
    return data;
  }

  async clearTemporaryData(): Promise<void> {
    try {
      return await this.fs.rmDir(this.fs.join(TEMPORARY_DIR));
    } catch {
      return undefined;
    }
  }

  //@TEXTBOOK META
  getTextbookFolder(id: string): string {
    return this.fs.join(TEXTBOOKS_DIR, id);
  }

  async writeTextbookMetaData(
    id: string,
    meta: DownloadRegistryUnit,
    scope: TextbookMetaScope = TextbookMetaScope.textbook
  ): Promise<void> {
    let path: string = this.fs.join(TEXTBOOKS_DIR, id, TEXTBOOK_META_FILE);
    if (scope === TextbookMetaScope.user) {
      path = this.fs.join(USERS_DIR, await this.getCurrentUserId(), id, TEXTBOOK_META_FILE);
    } else if (scope === TextbookMetaScope.access) {
      path = this.fs.join(TEXTBOOKS_DIR, id, meta.textbookAccessLevel, TEXTBOOK_META_FILE);
    }
    return await this.fs.writeJSONToDataDir(path, meta);
  }

  writeTextbookMetaAccessScope(
    id: string,
    accessId: string,
    meta: DownloadRegistryUnit
  ): Promise<void> {
    return this.fs.writeJSONToDataDir(
      this.fs.join(TEXTBOOKS_DIR, id, accessId, TEXTBOOK_META_FILE),
      meta
    );
  }

  readTextbookSharedMeta(id: string): Promise<DownloadRegistryUnit | null> {
    return this.fs.readJSONDataFromDataDir<DownloadRegistryUnit>(
      this.fs.join(TEXTBOOKS_DIR, id, TEXTBOOK_META_FILE)
    );
  }

  readTextbookAccessLevelMeta(textbook: TextbookMorph): Promise<DownloadRegistryUnit | null> {
    return this.fs.readJSONDataFromDataDir<DownloadRegistryUnit>(
      this.fs.join(TEXTBOOKS_DIR, textbook.id, textbook.access.accessLevel, TEXTBOOK_META_FILE)
    );
  }

  readTextbookAccessLevelMetaById(
    id: string,
    accessLevel: AccessLevel
  ): Promise<DownloadRegistryUnit | null> {
    return this.fs.readJSONDataFromDataDir<DownloadRegistryUnit>(
      this.fs.join(TEXTBOOKS_DIR, id, accessLevel, TEXTBOOK_META_FILE)
    );
  }

  async readTextbookUserMeta(textbook: TextbookMorph): Promise<DownloadRegistryUnit | null> {
    return await this.fs.readJSONDataFromDataDir<DownloadRegistryUnit>(
      this.fs.join(USERS_DIR, await this.getCurrentUserId(), textbook.id, TEXTBOOK_META_FILE)
    );
  }

  async readTextbookUserMetaById(id: string): Promise<DownloadRegistryUnit | null> {
    return await this.fs.readJSONDataFromDataDir<DownloadRegistryUnit>(
      this.fs.join(USERS_DIR, await this.getCurrentUserId(), id, TEXTBOOK_META_FILE)
    );
  }

  readTextbookMeta(textbook: TextbookMorph): Promise<DownloadRegistryUnit | null> {
    return this.fs.readJSONDataFromDataDir<DownloadRegistryUnit>(
      this.fs.join(
        TEXTBOOKS_DIR,
        textbook.id,
        textbook.access.accessId.toString(),
        TEXTBOOK_META_FILE
      )
    );
  }

  readTextbookMetaById(textbookId: string, accessId: string): Promise<DownloadRegistryUnit | null> {
    return this.fs.readJSONDataFromDataDir<DownloadRegistryUnit>(
      this.fs.join(TEXTBOOKS_DIR, textbookId, accessId, TEXTBOOK_META_FILE)
    );
  }

  async getTextbookAccessLevelProfiles(textbookId: string): Promise<string[]> {
    const files = await this.fs.readDataDir(this.fs.join(TEXTBOOKS_DIR, textbookId));
    return files.filter((dir) =>
      [AccessLevel.StudentBasic, AccessLevel.StudentPremium, AccessLevel.Teacher]
        .map((level) => level.toString())
        .includes(dir)
    );
  }

  async getTextbookAccessProfilesRequestedByCurrentUser(textbookId: string): Promise<string[]> {
    const res: string[] = [];
    const userId = await this.getCurrentUserId();
    const accessProfiles = await this.getTextbookAccessProfiles(textbookId);
    for (const profile of accessProfiles) {
      const profileData = await this.readTextbookMetaById(textbookId, profile);
      if (profileData && profileData.requestedBy[userId]) res.push(profile);
    }
    return res;
  }

  async getTextbookDirsAndFilesWithAccessExcluded(textbookId: string): Promise<string[]> {
    const allDirsAndFiles = await this.getAllTextbookDirsAndFiles(textbookId);
    const accessDirs = this.getAccessDirs(allDirsAndFiles);
    const toDrop = allDirsAndFiles
      .filter((dir) => !accessDirs.includes(dir))
      .map((dir) => this.fs.join(TEXTBOOKS_DIR, textbookId, dir));
    return toDrop;
  }

  async getTextbookAccessProfiles(textbookId: string): Promise<string[]> {
    const dirs = await this.getAllTextbookDirsAndFiles(textbookId);
    return this.getAccessDirs(dirs);
  }

  async getTextbookAccessProfilesWithSameLevel(
    textbookId: string,
    accessLevel: AccessLevel
  ): Promise<string[]> {
    const accessProfiles = await this.getTextbookAccessProfiles(textbookId);
    const res: string[] = [];
    for (const profile of accessProfiles) {
      const profileData = await this.readTextbookMetaById(textbookId, profile);
      if (profileData && profileData.textbookAccessLevel === accessLevel) res.push(profile);
    }
    return res;
  }

  async deleteTextbookMeta(id: string, accessId: string): Promise<void> {
    try {
      return await this.fs.rmDir(this.fs.join(TEXTBOOKS_DIR, id, accessId));
    } catch {
      return undefined;
    }
  }

  truncateTextbook(id: string): Promise<void> {
    return this.fs.rmDir(this.fs.join(TEXTBOOKS_DIR, id));
  }

  //@TEXTBOOK PAGES
  writeTextbookPages(id: string, pages: TextbookPage[]): Promise<void> {
    return this.fs.writeJSONToDataDir(this.fs.join(TEXTBOOKS_DIR, id, TEXTBOOK_PAGES_FILE), pages);
  }

  readTextbookPages(id: string): Promise<TextbookPage[]> {
    return this.fs.readJSONDataFromDataDir(
      this.fs.join(TEXTBOOKS_DIR, id, TEXTBOOK_PAGES_FILE)
    ) as Promise<TextbookPage[]>;
  }

  async writeAllTextbookActions(id: string, payload: TextbookActionMap): Promise<void> {
    return await this.fs.writeJSONToDataDir(
      this.fs.join(TEXTBOOKS_DIR, id, TEXTBOOK_ACTIONS_DIR, TEXTBOOK_ACTIONS_FILE),
      payload
    );
  }

  readTextbookAction(id: string, actionId: string): Observable<TextbookAction | undefined> {
    return from(
      this.fs.readJSONDataFromDataDir<TextbookActionMap>(
        this.fs.join(TEXTBOOKS_DIR, id, TEXTBOOK_ACTIONS_DIR, TEXTBOOK_ACTIONS_FILE)
      )
    ).pipe(map((actionsMap) => actionsMap?.[actionId]));
  }

  //@TEXTBOOK RESOURCES
  getTextbookResourcesFolder(id: string): string {
    return this.fs.join(TEXTBOOKS_DIR, id, RESOURCES_DIR);
  }

  async writeStringResource(textbookId: string, resourceId: string, blob: Blob): Promise<void> {
    await this.fs.writeToDataDir(
      this.fs.join(TEXTBOOKS_DIR, textbookId, RESOURCES_DIR, resourceId),
      await blobToString(blob),
      Encoding.ASCII
    );
  }

  async readResource(textbookId: string, resourceId: string): Promise<Blob> {
    const data = await this.fs.readDataAsBlob(
      this.fs.join(TEXTBOOKS_DIR, textbookId, RESOURCES_DIR, resourceId)
    );
    if (!data) return new Blob([]);
    return data;
  }

  async deleteTextbookResources(id: string): Promise<void> {
    try {
      return await this.fs.rmDir(this.fs.join(TEXTBOOKS_DIR, id, RESOURCES_DIR));
    } catch {
      return undefined;
    }
  }

  //@TEXTBOOK LEGEND
  getTextbookAccessLevelFolder(id: string, accessLevel: AccessLevel): string {
    return this.fs.join(TEXTBOOKS_DIR, id, accessLevel);
  }

  writeTextbookLegend(
    textbookId: string,
    accessType: AccessLevel,
    legend: TextbookLegend
  ): Promise<void> {
    return this.fs.writeJSONToDataDir(
      this.fs.join(TEXTBOOKS_DIR, textbookId, accessType, TEXTBOOK_LEGEND_FILE),
      legend
    );
  }

  async readTextbookLegend(textbookId: string, accessType: AccessLevel): Promise<TextbookLegend> {
    const data = await this.fs.readJSONDataFromDataDir<TextbookLegend>(
      this.fs.join(TEXTBOOKS_DIR, textbookId, accessType, TEXTBOOK_LEGEND_FILE)
    );
    if (!data) return {} as TextbookLegend;
    return data;
  }

  //@TEXTBOOK TEXTUAL PAGES
  writeTextbookTextualPages(textbookId: string, pages: TextbookTextualPage[]): Promise<void> {
    return this.fs.writeJSONToDataDir(
      this.fs.join(TEXTBOOKS_DIR, textbookId, TEXTBOOK_TEXTUAL_PAGES_FILE),
      pages
    );
  }

  readTextbookTextualPages(textbookId: string): Promise<TextbookTextualPage[]> {
    return this.fs.readJSONDataFromDataDir(
      this.fs.join(TEXTBOOKS_DIR, textbookId, TEXTBOOK_TEXTUAL_PAGES_FILE)
    ) as Promise<TextbookTextualPage[]>;
  }

  async deleteTextbookAccessProfileData(id: string, accessLevel: AccessLevel): Promise<void> {
    try {
      return await this.fs.rmDir(this.fs.join(TEXTBOOKS_DIR, id, accessLevel));
    } catch {
      return undefined;
    }
  }

  //@USER NOTES
  async writeUserNotes(textbookId: string, notes: PageNote[]): Promise<void> {
    return await this.fs.writeJSONToDataDir(
      this.fs.join(USERS_DIR, await this.getCurrentUserId(), textbookId, USER_NOTES_FILE),
      notes
    );
  }

  async readUserNotes(textbookId: string): Promise<PageNote[]> {
    const data = await this.fs.readJSONDataFromDataDir<PageNote[]>(
      this.fs.join(USERS_DIR, await this.getCurrentUserId(), textbookId, USER_NOTES_FILE)
    );
    if (!data) return [];
    return data;
  }

  //@USER MARKERS
  async writeUserMarkers(textbookId: string, markers: Marker[]): Promise<void> {
    return await this.fs.writeJSONToDataDir(
      this.fs.join(USERS_DIR, await this.getCurrentUserId(), textbookId, USER_MARKERS_FILE),
      markers
    );
  }

  async readUserMarkers(textbookId: string): Promise<Marker[]> {
    const data = await this.fs.readJSONDataFromDataDir<Marker[]>(
      this.fs.join(USERS_DIR, await this.getCurrentUserId(), textbookId, USER_MARKERS_FILE)
    );
    if (!data) return [];
    return data;
  }

  async deleteUserContent(id: string): Promise<void> {
    try {
      return await this.fs.rmDir(this.fs.join(USERS_DIR, await this.getCurrentUserId(), id));
    } catch {
      return undefined;
    }
  }

  //@USER TASKS
  async writeUserTasks(task: Task[]): Promise<void> {
    return await this.fs.writeJSONToDataDir(
      this.fs.join(USERS_DIR, await this.getCurrentUserId(), USER_TASKS_FILE),
      task
    );
  }

  async readUserTasks(): Promise<Task[]> {
    const data = await this.fs.readJSONDataFromDataDir<Task[]>(
      this.fs.join(USERS_DIR, await this.getCurrentUserId(), USER_TASKS_FILE)
    );
    if (!data) return [];
    return data;
  }

  //@TEXTBOOK ACTION TRIGGERS
  writeTextbookActionTriggers(
    textbookId: string,
    accessType: AccessLevel,
    triggers: ActionTrigger[]
  ): Promise<void> {
    return this.fs.writeJSONToDataDir(
      this.fs.join(TEXTBOOKS_DIR, textbookId, accessType, TEXTBOOK_ACTIONS_TRIGGERS_FILE),
      triggers
    );
  }

  async readTextbookActionTriggers(
    textbookId: string,
    accessType: AccessLevel
  ): Promise<ActionTrigger[]> {
    const data = await this.fs.readJSONDataFromDataDir<ActionTrigger[]>(
      this.fs.join(TEXTBOOKS_DIR, textbookId, accessType, TEXTBOOK_ACTIONS_TRIGGERS_FILE)
    );
    if (!data) return [];
    return data;
  }

  writeTextbookActionGroups(
    textbookId: string,
    accessType: AccessLevel,
    groups: TextbookActionGroup[]
  ): Promise<void> {
    return this.fs.writeJSONToDataDir(
      this.fs.join(TEXTBOOKS_DIR, textbookId, accessType, TEXTBOOK_ACTIONS_GROUPS_FILE),
      groups
    );
  }

  async readTextbookActionGroups(
    textbookId: string,
    accessType: AccessLevel
  ): Promise<TextbookActionGroup[]> {
    const data = await this.fs.readJSONDataFromDataDir<TextbookActionGroup[]>(
      this.fs.join(TEXTBOOKS_DIR, textbookId, accessType, TEXTBOOK_ACTIONS_GROUPS_FILE)
    );
    if (!data) return [];
    return data;
  }

  //@ACTION DRAWINGS
  async writeUserActionDrawing(
    textbookId: string,
    actionId: string,
    userDrawing: Drawing
  ): Promise<void> {
    return await this.fs.writeJSONToDataDir(
      this.fs.join(
        USERS_DIR,
        await this.getCurrentUserId(),
        textbookId,
        USER_ACTION_DRAWINGS_DIR,
        actionId
      ),
      userDrawing
    );
  }

  async deleteUserActionDrawing(textbookId: string, actionId: string): Promise<void> {
    try {
      const path = this.fs.join(
        USERS_DIR,
        await this.getCurrentUserId(),
        textbookId,
        USER_ACTION_DRAWINGS_DIR,
        actionId
      );
      return await this.fs.rmDir(path);
    } catch {
      return undefined;
    }
  }

  async readUserActionDrawing(textbookId: string, actionId: string): Promise<Drawing | null> {
    const data = await this.fs.readJSONDataFromDataDir<Drawing>(
      this.fs.join(
        USERS_DIR,
        await this.getCurrentUserId(),
        textbookId,
        USER_ACTION_DRAWINGS_DIR,
        actionId
      )
    );
    if (!data) return null;
    return data;
  }

  //@ACTION NOTES
  async writeUserActionNote(
    textbookId: string,
    actionId: string,
    userActionNote: ActionNote
  ): Promise<void> {
    return await this.fs.writeJSONToDataDir(
      this.fs.join(
        USERS_DIR,
        await this.getCurrentUserId(),
        textbookId,
        USER_ACTION_NOTES_DIR,
        actionId
      ),
      userActionNote
    );
  }

  async deleteUserActionNote(textbookId: string, actionId: string): Promise<void> {
    try {
      const path = this.fs.join(
        USERS_DIR,
        await this.getCurrentUserId(),
        textbookId,
        USER_ACTION_NOTES_DIR,
        actionId
      );
      return await this.fs.rmDir(path);
    } catch {
      return undefined;
    }
  }

  //@USER DATA
  async getUserDataFolder(): Promise<string> {
    return this.fs.join(USERS_DIR, await this.getCurrentUserId());
  }

  async getUserDataFolderForTextbook(id: string): Promise<string> {
    return `${await this.getUserDataFolder()}/${id}`;
  }

  async getAccessProfileFolderForTextbook(
    textbookId: string,
    textbookAccessId: number
  ): Promise<string> {
    return this.fs.join(TEXTBOOKS_DIR, textbookId, textbookAccessId.toString());
  }

  async removeCurrentUserTextbookData(textbookId: string): Promise<void> {
    return await this.fs.rmDir(this.fs.join(USERS_DIR, await this.getCurrentUserId(), textbookId));
  }

  async readUserActionNote(textbookId: string, actionId: string): Promise<ActionNote | null> {
    const data = await this.fs.readJSONDataFromDataDir<ActionNote>(
      this.fs.join(
        USERS_DIR,
        await this.getCurrentUserId(),
        textbookId,
        USER_ACTION_NOTES_DIR,
        actionId
      )
    );
    if (!data) return null;
    return data;
  }

  //@DataCleaner META
  async appendDataCleanerMeta(data: string[]): Promise<string[]> {
    const prev = await this.readDataCleanerMeta();
    const next = prev.concat(data);
    await this.fs.writeJSONToDataDir(this.fs.join(DATA_CLEANER_META), next);
    return next;
  }

  async readDataCleanerMeta(): Promise<string[]> {
    const data = await this.fs.readJSONDataFromDataDir<string[]>(this.fs.join(DATA_CLEANER_META));
    if (!data) return [];
    return data;
  }

  writeDataCleanerMeta(data: string[]): Promise<void> {
    return this.fs.writeJSONToDataDir(this.fs.join(DATA_CLEANER_META), data);
  }

  async rmDir(path: string): Promise<void> {
    try {
      return await this.fs.rmDir(path);
    } catch (e) {
      return undefined;
    }
  }

  async rmFile(path: string): Promise<void> {
    try {
      return await this.fs.rmFile(path);
    } catch (e) {
      return undefined;
    }
  }

  getTextbooksFolderData(): Promise<string[]> {
    return this.fs.readDataDir(this.fs.join(TEXTBOOKS_DIR));
  }

  //@ERROR LOG
  writeError(error: Error): Promise<void> {
    const fileName = dayjs().format('DD.MM.YYYY-HH.mm.ss.SSS');
    return this.fs.writeJSONToDataDir(this.fs.join(ERROR_LOGS_DIR, fileName), error);
  }

  readErrorFolder(): Promise<string[]> {
    return this.fs.readDataDir(this.fs.join(ERROR_LOGS_DIR));
  }

  async readErrorFile(fileName: string): Promise<Error> {
    const data = await this.fs.readDataFromDataDir(this.fs.join(ERROR_LOGS_DIR, fileName));
    return JSON.parse(data as string);
  }

  //@WAKE LOCK
  async initWakeLock(): Promise<void> {
    try {
      return await KeepAwake.keepAwake();
    } catch (e) {
      console.log('Wake lock is not available', e);
    }
    return Promise.resolve();
  }

  async stopWakeLock(): Promise<void> {
    try {
      return await KeepAwake.allowSleep();
    } catch (e) {
      console.log('Wake lock is not available', e);
    }
    return Promise.resolve();
  }

  private async getAllTextbookDirsAndFiles(textbookId: string): Promise<string[]> {
    const allDirs = await this.fs.readDataDir(this.fs.join(TEXTBOOKS_DIR, textbookId));
    return allDirs;
  }

  private getAccessDirs(textbookLevelDirs: string[]): string[] {
    return textbookLevelDirs.filter((dir) => this.isAccessDir(dir));
  }

  private isAccessDir(textbookLevelDirName: string): boolean {
    return !isNaN(parseInt(textbookLevelDirName));
  }
}
