import { createAction, props } from '@ngrx/store';

export const loginWithCredentials = createAction(
  '[Auth] Login With Credentials',
  props<{ login: string; password: string }>()
);

export const loginWithToken = createAction(
  '[Auth] Login With Token',
  props<{ token: string; accessId?: number }>()
);

export const logout = createAction('[Auth] Logout');

export const logoutSuccess = createAction('[Auth] Success');

export const setFormValid = createAction(
  '[Auth] Set Form Valid',
  props<{ isFormValid: boolean }>()
);

export const recreateUser = createAction('[Auth] Recreate User', props<{ accessId?: number }>());

export const recreateUserSuccess = createAction('[Auth] Recreate User Success');

export const recreateUserToken = createAction('[Auth] Recreate User Token');
