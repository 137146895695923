import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
  ChildActivationEnd,
  ChildActivationStart,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RouterEvent,
  Scroll,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { TextbookChildRoute } from '@app/textbook/constants';

const DISABLE_OVERSCROLL_CLASS_NAME = 'blockScroll';

@Injectable()
export class NavigationService implements OnDestroy {
  readonly showLoader$ = new BehaviorSubject<boolean>(false);
  private readonly subscription = new Subscription();
  private readonly htmlEl = document.getElementsByTagName('html')?.[0];
  private readonly renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);

  constructor(private readonly router: Router, private rendererFactory: RendererFactory2) {
    this.subscription.add(
      this.router.events
        .pipe(
          filter(
            (event) =>
              event instanceof NavigationStart ||
              event instanceof NavigationEnd ||
              event instanceof NavigationCancel ||
              event instanceof NavigationError
          )
        )
        .subscribe((event) => {
          return this.checkRouteChange(event);
        })
    );
    this.subscription.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event) => {
          if ((event as NavigationEnd).urlAfterRedirects.includes(TextbookChildRoute.Action)) {
            this.renderer.addClass(this.htmlEl, DISABLE_OVERSCROLL_CLASS_NAME);
          } else {
            this.renderer.removeClass(this.htmlEl, DISABLE_OVERSCROLL_CLASS_NAME);
          }
        })
    );
  }

  checkRouteChange(
    routerEvent:
      | RouterEvent
      | RouteConfigLoadStart
      | RouteConfigLoadEnd
      | ChildActivationStart
      | ChildActivationEnd
      | Scroll
  ): void {
    this.showLoader$.next(routerEvent instanceof NavigationStart);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
