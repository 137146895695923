import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { effects } from './effects';
import { reducers } from './reducers';

export const shelfFeatureKey = 'shelf';

const storeModule = StoreModule.forFeature(shelfFeatureKey, reducers);
const effectsModule = EffectsModule.forFeature(effects);

@NgModule({
  imports: [storeModule, effectsModule],
})
export class ShelfStoreModule {}
