import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { SystemMaintenanceFacade } from '@app/core/store/facade/system-maintenance.facade';
import { map, Observable } from 'rxjs';

@Injectable()
export class AppLockGuard implements CanActivate {
  constructor(private readonly systemMaintenanceFacade: SystemMaintenanceFacade) {}

  canActivate(): Observable<boolean> {
    return this.systemMaintenanceFacade.appLocked$.pipe(map((locked) => !locked));
  }
}
