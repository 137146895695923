import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appMulticlick]',
})
export class MulticlickDirective {
  @Input() timeout = 1000;
  @Input() clicks = 2;
  @Output() multiclickDone: EventEmitter<boolean> = new EventEmitter<boolean>();

  private timer!: number;
  private clickCounter = 0;

  @HostListener('click')
  onClick(): void {
    clearTimeout(this.timer);
    this.clickCounter++;
    this.timer = setTimeout(() => {
      if (this.clicks <= this.clickCounter) {
        this.multiclickDone.emit(true);
      }
      this.clickCounter = 0;
    }, this.timeout);
  }
}
