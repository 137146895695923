import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { logger } from '@app/shared/utils';

import { Forbidden, HttpError, Unauthorized } from 'http-errors';
import { Store } from '@ngrx/store';
import { ErrorActions } from '@app/core/store/actions';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private readonly nonReportingErrors = [Unauthorized, Forbidden];

  private avoidReports = false;

  constructor(private injector: Injector) {}

  private stabilizeError(error: Error): Error {
    if ('promise' in error) {
      return (error as unknown as { rejection: Error }).rejection;
    }
    return error;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  handleError(error: any): void {
    const httpErrorStatus = ((error.originalError || error) as HttpError | undefined)?.status;
    const stabilizedError = this.stabilizeError(error.originalError || error);

    logger.error(stabilizedError);
    const isReportingError = this.nonReportingErrors.every(
      (errorClass) => !(stabilizedError instanceof errorClass)
    );

    if (!isReportingError) {
      this.avoidReports = true;
    }

    const hash = this.hash(
      // eslint-disable-next-line no-prototype-builtins
      stabilizedError.hasOwnProperty('stack')
        ? (stabilizedError.stack as string)
        : stabilizedError.toString()
    );

    if (isReportingError && !this.avoidReports) {
      // @TODO: Wait for sentry dsn
      // Sentry.configureScope((scope) => scope.setTags({ errorCode:hash }));
      // Sentry.captureException(error);
      //logger.log('ERROR REPORTED');
    }
    this.injector
      .get(Store)
      .dispatch(ErrorActions.errorOccurred({ error: stabilizedError, hash, httpErrorStatus }));
  }

  private hash(stringMessage: string): string {
    return [...stringMessage]
      .map((char) => char.charCodeAt(0).toFixed(0))
      .map(parseInt)
      .filter((el) => !Number.isNaN(el))
      .reduce((a, b) => a + b, 0)
      .toString(32)
      .toUpperCase();
  }
}

export const globalErrorHandler = {
  provide: ErrorHandler,
  useClass: GlobalErrorHandler,
};
