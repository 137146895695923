<app-modal-layout maxWidth="24rem" marginTop="2.5rem" (backdropClick)="onReject()">
  <div class="content">
    <button class="close-btn" (click)="onReject()"><app-icon name="close"></app-icon></button>

    <p class="header">Uwaga!</p>

    <p>
      <i> {{ textbook.access.name }} </i>
    </p>

    <p>
      W pobranym multipodręczniku, który działa w trybie offline, część funkcji jest niedostępna
      (aby z nich<br />
      korzystać, musisz mieć połączenie z internetem).
    </p>

    <p class="question">Czy chcesz pobrać multipodręcznik?</p>

    <div>
      <button class="yes" (click)="onConfirm()">Tak</button>
      <button (click)="onReject()">Nie</button>
    </div>
  </div>
</app-modal-layout>
