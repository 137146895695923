import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as dayjs from 'dayjs';
import * as uuid from 'uuid';

import { PATH_DELIMITER } from '../constants/filesystem-path';
import { DROP_TAG } from '../constants/filesystem-tag';
import { DownloadRegistryUnit } from '../store/reducers';
import { RuntimeService } from './runtime.service';
import { FileExtension } from '@app/shared/constants/file-extensions.enum';

@Injectable()
export class DataCleanerService {
  private static instances = 0;

  constructor(private readonly runtimeService: RuntimeService) {
    DataCleanerService.instances++;
  }

  async serviceWorkerStart(): Promise<void> {
    const data = await this.runtimeService.readDataCleanerMeta();
    console.log('DATA CLEANER META:', data);
    while (data.length > 0) {
      const path = data.shift() as string;
      const isJsonFile = path.endsWith(FileExtension.Json);
      await (isJsonFile ? this.runtimeService.rmFile(path) : this.runtimeService.rmDir(path));
      console.log('DATA CLEANER -> DROP', path);
      await this.runtimeService.writeDataCleanerMeta(data);
    }
  }

  private async checkTextbookExpired(
    id: string,
    access: string
  ): Promise<{ expired: boolean; meta: DownloadRegistryUnit } | null> {
    const meta = await this.runtimeService.readTextbookMetaById(id, access);
    if (!meta) return null;
    const res = (expired: boolean) => ({
      meta,
      expired,
    });
    if (!meta) return res(false);
    const { expireDate } = meta;
    const dropDate = dayjs(expireDate).add(environment.textbookExpiredAfterDays, 'day');
    if (dropDate.isBefore(dayjs())) {
      return res(true);
    }
    return res(false);
  }

  async checkSystemForGarbage(): Promise<void> {
    if (DataCleanerService.instances === 1) return;
    console.log('DATA CLEANER -> GARBAGE COLLECTOR');
    const textbooks = (await this.runtimeService.getTextbooksFolderData()).filter(
      ($) => !$.includes(DROP_TAG)
    );
    for (const id of textbooks) {
      for (const access of await this.runtimeService.getTextbookAccessProfiles(id)) {
        const payload = await this.checkTextbookExpired(id, access);
        if (payload?.expired) {
          console.log('EXPIRED TEXTBOOK', payload.meta);
          delete payload.meta.requestedBy[await this.runtimeService.getCurrentUserId()];
          await this.runtimeService.writeTextbookMetaAccessScope(
            payload.meta.textbookId,
            payload.meta.textbookAccessId.toString(),
            payload.meta
          );
          await this.addDeleteTextbookTask(payload.meta, false);
        }
      }
    }
  }

  private wrapWithDropTag(str: string): string {
    return `${DROP_TAG}${str}${DROP_TAG}`;
  }

  private getTimeTag(): string {
    return uuid.v4();
  }

  private async markFolderForDrop(path: string): Promise<string> {
    const name = path.split(PATH_DELIMITER).pop() as string;
    const isJsonFile = name.endsWith(FileExtension.Json);
    const newName = `${this.wrapWithDropTag(this.getTimeTag())}${
      isJsonFile ? FileExtension.Json : ''
    }`;
    const newPath = path.replace(name, newName);
    return await this.runtimeService.rename(path, newPath);
  }

  async addDeleteTextbookTask(unit: DownloadRegistryUnit, exhausted = false): Promise<void> {
    let toDrop = await this.getFoldersForDrop(unit, !!exhausted);
    toDrop = await Promise.all(toDrop.map(($) => this.markFolderForDrop($)));
    await this.runtimeService.appendDataCleanerMeta(toDrop);
    await this.serviceWorkerStart();
  }

  private async getFoldersForDrop(
    unit: DownloadRegistryUnit,
    exhausted: boolean
  ): Promise<string[]> {
    const userAccessLevelDirs = (
      await this.runtimeService.getTextbookAccessProfilesRequestedByCurrentUser(unit.textbookId)
    ).length;
    const sameAccessLevelDirs = await this.runtimeService.getTextbookAccessProfilesWithSameLevel(
      unit.textbookId,
      unit.textbookAccessLevel
    );
    const accessDirs = await this.runtimeService.getTextbookAccessProfiles(unit.textbookId);
    const dropResources =
      sameAccessLevelDirs.length === 1 && userAccessLevelDirs === 0 && accessDirs.length === 1;
    if (dropResources) {
      return this.getTextbookMainDirsToDrop(unit.textbookId);
    } else if (exhausted) {
      return await this.runtimeService.getTextbookDirsAndFilesWithAccessExcluded(unit.textbookId);
    } else {
      return this.getUserAndAccessDirsToDrop(unit, userAccessLevelDirs, sameAccessLevelDirs);
    }
  }

  private async getTextbookMainDirsToDrop(textbookId: string): Promise<string[]> {
    return [
      await this.runtimeService.getUserDataFolderForTextbook(textbookId),
      this.runtimeService.getTextbookFolder(textbookId),
    ];
  }

  private async getUserAndAccessDirsToDrop(
    unit: DownloadRegistryUnit,
    userAccessLevelDirs: number,
    sameAccessLevelDirs: string[]
  ): Promise<string[]> {
    const toDrop = [];
    toDrop.push(
      await this.runtimeService.getAccessProfileFolderForTextbook(
        unit.textbookId,
        unit.textbookAccessId
      )
    );
    if (userAccessLevelDirs === 0) {
      toDrop.push(await this.runtimeService.getUserDataFolderForTextbook(unit.textbookId));
    }
    if (sameAccessLevelDirs.length === 1) {
      toDrop.push(
        this.runtimeService.getTextbookAccessLevelFolder(unit.textbookId, unit.textbookAccessLevel)
      );
    }
    return toDrop;
  }
}
