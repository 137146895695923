import { Injectable } from '@angular/core';
import { Device } from '@capacitor/device';
import { Directory, Encoding, Filesystem } from '@capacitor/filesystem';
import { environment } from '@environments/environment';
import { PATH_DELIMITER, ROOT_DIR, TEXTBOOKS_DIR } from '../constants/filesystem-path';
import { stringToBlob } from '@app/shared/utils/stringtoBlob';

@Injectable()
export class FilesystemService {
  private getDirFromPath(path: string): string {
    const dirs = path.split(PATH_DELIMITER);
    dirs.pop();
    return dirs.join(PATH_DELIMITER);
  }

  async readDataDir(path?: string): Promise<string[]> {
    try {
      const res = await Filesystem.readdir({
        directory: Directory.Data,
        path: path ? path : this.join(TEXTBOOKS_DIR),
      });
      return res.files;
    } catch (e) {
      console.log('ERR', e);
    }
    return [];
  }

  async mkDir(path: string): Promise<void> {
    try {
      await Filesystem.mkdir({
        path,
        recursive: true,
        directory: Directory.Data,
      });
    } catch (e) {
      !environment.production && console.error(e);
    }
  }

  async writeToDataDir(
    path: string,
    data: string,
    encoding: Encoding = Encoding.UTF8
  ): Promise<void> {
    await this.mkDir(this.getDirFromPath(path));
    await Filesystem.writeFile({
      encoding,
      path,
      data,
      directory: Directory.Data,
    });
  }

  async readDataFromDataDir(
    path: string,
    encoding: Encoding = Encoding.UTF8
  ): Promise<string | null> {
    try {
      const readResult = await Filesystem.readFile({ path, encoding, directory: Directory.Data });
      return readResult.data;
    } catch (e) {
      !environment.production && console.error(e);
    }
    return null;
  }

  async rmFilesFromDir(dirPath: string, filter: (a: string) => boolean): Promise<void> {
    const dirs = await Filesystem.readdir({ path: dirPath, directory: Directory.Data });
    return await Promise.all(
      dirs.files.filter(filter).map((filename) =>
        Filesystem.deleteFile({
          path: `${dirPath}${PATH_DELIMITER}${filename}`,
          directory: Directory.Data,
        })
      )
    ).then(() => undefined);
  }

  rmDir(path: string): Promise<void> {
    return Filesystem.rmdir({
      path,
      directory: Directory.Data,
      recursive: true,
    });
  }

  rmFile(path: string): Promise<void> {
    return Filesystem.deleteFile({
      path,
      directory: Directory.Data,
    });
  }

  writeJSONToDataDir(path: string, data: unknown): Promise<void> {
    return this.writeToDataDir(path, this.toWritableFormat(data));
  }

  async readJSONDataFromDataDir<T>(path: string): Promise<T | null> {
    const text = await this.readDataFromDataDir(path);
    if (text) {
      return JSON.parse(text);
    }
    return null;
  }

  async readDataAsBlob(path: string, type?: string): Promise<Blob | null> {
    const data = await this.readDataFromDataDir(path);
    if (!data) return null;
    return stringToBlob(data, type);
  }

  join(...args: string[]): string {
    return [ROOT_DIR].concat(args).join(PATH_DELIMITER);
  }

  toWritableFormat(data: unknown): string {
    return JSON.stringify(data);
  }

  async getFreeMemory(): Promise<number> {
    const info = await Device.getInfo();
    return info.realDiskFree || Number(environment.memory) || Infinity;
  }

  rename(path: string, newPath: string): Promise<void> {
    return Filesystem.rename({
      directory: Directory.Data,
      from: path,
      to: newPath,
    });
  }
}
