import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

const allowedNames = [
  'search',
  'collapse',
  'close',
  'first page',
  'prev page',
  'next page',
  'last page',
  'backspace',
  'gwo logo',
  'logout',
  'check for updates',
  'info',
  'download',
  'pause',
  'continue',
  'delete',
  'help',
  'zoom plus',
  'zoom minus',
  'full screen',
  'normal screen',
  'menu',
  'borders on',
  'borders off',
  'highlighter',
  'trash',
  'pin',
  'pin-grey',
  'pin note',
  'drag',
  'show-password',
  'hide-password',
  'back',
  'arrow',
  'warning',

  'teacher_comment_on',
  'teacher_comment_off',
  'sandbox-drawer',
  'arrow-down',
  'disconnected',
] as const;

/**
 * Reusable icon component. Type of icon is passing as `name` @Input.
 * Custom styles can be applied as normal styles from parent component example:
 * ```css
 * app-icon {
 *   width: 1.8rem;
 *   height: 1.8rem;
 *   --primary-color: #ec5800;
 * }
 * ```
 */
@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() name!: typeof allowedNames[number];
  @Input() fill?: string | null;
  @Input() stroke?: string | null;
}
