import { ActionReducerMap } from '@ngrx/store';

import { errorFeatureKey, errorReducer, ErrorState } from './error.reducer';
import { userFeatureKey, userReducer, UserState } from '@app/core/store/reducers/user.reducer';
import { authFeatureKey, authReducer, AuthState } from '@app/core/store/reducers/auth.reducer';
import {
  systemMaintenanceFeatureKey,
  systemMaintenanceReducer,
  SystemMaintenanceState,
} from '@app/core/store/reducers/system-maintenance.reducer';

export interface AppState {
  [errorFeatureKey]: ErrorState;
  [userFeatureKey]: UserState;
  [authFeatureKey]: AuthState;
  [systemMaintenanceFeatureKey]: SystemMaintenanceState;
}

export const reducers: ActionReducerMap<AppState> = {
  [errorFeatureKey]: errorReducer,
  [userFeatureKey]: userReducer,
  [authFeatureKey]: authReducer,
  [systemMaintenanceFeatureKey]: systemMaintenanceReducer,
};
