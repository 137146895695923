import { createAction, props } from '@ngrx/store';
import { AppMessage } from '@gwo/textbook-api-client';

export const initMobileAppBackgroundEffects = createAction(
  '[System Maintenance] Init Mobile App Background Effects'
);

export const mobileAppBackgroundEffectsInitializationSkipped = createAction(
  '[System Maintenance] Mobile App Background Effects Initialization Skipped'
);

export const setWarningModalVisibility = createAction(
  '[System Maintenance] Set Warning Modal Visibility',
  props<{ visible: boolean }>()
);

export const warningMessageConfirmed = createAction(
  '[System Maintenance] Warning Message Confirmed'
);

export const setAppMessage = createAction(
  '[System Maintenance] Set App Message',
  props<{ message: AppMessage | null }>()
);

export const updateLockStatus = createAction(
  '[System Maintenance] Update Lock Status',
  props<{ locked: boolean }>()
);

export const fetchAppMessages = createAction('[System Maintenance] Fetch App Messages');
