import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import { catchError, filter, first, map } from 'rxjs/operators';
import {
  BaseManagerApi,
  TEXTBOOK_MANAGER_TOKEN,
} from '@app/core/providers/textbook-manager.provider';
import { Textbook, TextbookPage } from '@gwo/textbook-api-client';
import { TextbookPageNumberUtil } from '@app/textbook/utils/textbook-page-number-util';
import { CoreRoute } from '@app/core/constants';
import { TextbookTextualPage } from '@gwo/textbook-api-client/lib/interface/textbook-textual-page.model';
import { TextbookLegend } from '@gwo/textbook-api-client/lib/interface/textbook-legend.model';
import { ActionTrigger } from '@gwo/textbook-api-client/lib/actions';
import { ActionTriggersState } from '../store/reducers/viewer.reducer';
import { ErrorRoute, ErrorRouteQueryParam } from '@app/errors/constants/routes';
import { TextbookDemoForbiddenError } from '@app/core/modules/error-handler/errors/textbook-demo-forbidden.error';
import { PlayerTypeResolver } from '@app/textbook/resolvers/player-type.resolver';

export type TextbookCoreData = {
  textbook: Textbook;
  pages: TextbookPage[];
  actionTriggers: ActionTriggersState;
  textualPages: TextbookTextualPage[] | null;
  legend: TextbookLegend | null;
};

@Injectable()
export class TextbookDataResolver implements Resolve<TextbookCoreData> {
  constructor(
    @Inject(TEXTBOOK_MANAGER_TOKEN) private textbookManager: BaseManagerApi,
    private readonly router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<TextbookCoreData> {
    const {
      url: [, { path: textbookId }],
      queryParams: { accessId },
    } = route;
    const playerType = PlayerTypeResolver.getPlayerType(route);
    const textbook$ = this.textbookManager.getTextbook$(textbookId).pipe(
      first(),
      catchError((error) => {
        if (CoreRoute.Demos === playerType && error instanceof TextbookDemoForbiddenError) {
          this.router.navigate([CoreRoute.Error, ErrorRoute.INACTIVE_TEXTBOOK_DEMO, textbookId], {
            queryParams: { [ErrorRouteQueryParam.RESOLVER_REDIRECTION]: true },
          });
          return of(null);
        }
        throw error;
      }),
      filter(Boolean),
      map(TextbookDataResolver.parseTextbook)
    );

    return textbook$.pipe(
      switchMap((textbook) =>
        forkJoin([
          this.textbookManager.getTextbookPages$(textbookId, textbook.version).pipe(first()),
          this.textbookManager
            .getTextbookActionTriggers$(textbookId, textbook.version, accessId)
            .pipe(first()),
          this.getTextualPages(playerType as CoreRoute, textbookId, textbook.version),
          this.getLegend(playerType as CoreRoute, accessId, textbookId, textbook.version),
        ]).pipe(
          map(([pages, actionTriggers, textualPages, legend]) => ({
            textbook,
            pages,
            actionTriggers: TextbookDataResolver.parseActionTriggers(actionTriggers),
            textualPages,
            legend,
          }))
        )
      )
    );
  }

  private getTextualPages(
    playerType: CoreRoute,
    textbookId: string,
    textbookVersion: number
  ): Observable<TextbookTextualPage[] | null> {
    return playerType === CoreRoute.Infographics
      ? of(null)
      : this.textbookManager.getTextbookTextualPages$(textbookId, textbookVersion).pipe(first());
  }

  private getLegend(
    playerType: CoreRoute,
    accessId: number,
    textbookId: string,
    textbookVersion: number
  ): Observable<TextbookLegend | null> {
    return playerType === CoreRoute.Infographics
      ? of(null)
      : this.textbookManager
          .getTextbookLegend$(textbookId, textbookVersion, accessId)
          .pipe(first());
  }

  private static parseActionTriggers(actionTriggers: ActionTrigger[]): ActionTriggersState {
    const actionTriggersMap = actionTriggers.reduce(
      (result: { [key: string]: ActionTrigger[] }, actionTrigger) => {
        return {
          ...result,
          [actionTrigger.pageId]: [...(result[actionTrigger.pageId] || []), actionTrigger],
        };
      },
      {}
    );

    Object.keys(actionTriggersMap)
      .filter((pageId) => TextbookDataResolver.isOrderOnPageAvailable(actionTriggersMap[pageId]))
      .forEach((pageId) => {
        const actionTriggersForPage = actionTriggersMap[pageId];
        actionTriggersMap[pageId] =
          TextbookDataResolver.sortByOrderOnPageAscending(actionTriggersForPage);
      });
    return actionTriggersMap;
  }

  // przesun firstPage jesli to nie infografika
  private static parseTextbook(textbook: Textbook): Textbook {
    return {
      ...textbook,
      firstPageNumber: TextbookPageNumberUtil.parseFirstPageNumber(textbook),
    };
  }

  private static isOrderOnPageAvailable(actionTriggers: ActionTrigger[]): boolean {
    return !actionTriggers.some(
      (actionTrigger) =>
        actionTrigger.orderOnPage === null || actionTrigger.orderOnPage === undefined
    );
  }

  private static sortByOrderOnPageAscending(actionTriggers: ActionTrigger[]): ActionTrigger[] {
    return actionTriggers.sort((first, second) =>
      (first.orderOnPage as number) > (second.orderOnPage as number) ? 1 : -1
    );
  }
}
