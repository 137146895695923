import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { PlayerType } from '@app/core/constants';

@Injectable({
  providedIn: 'root',
})
export class PlayerTypeResolver implements Resolve<PlayerType> {
  resolve(route: ActivatedRouteSnapshot): Observable<PlayerType> {
    return of(PlayerTypeResolver.getPlayerType(route));
  }

  public static getPlayerType(route: ActivatedRouteSnapshot): PlayerType {
    const [{ path: playerType }] = route.url;
    return playerType as PlayerType;
  }
}
