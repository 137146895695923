import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class NoteBoxService {
  public isPinClicked = new BehaviorSubject(false);
  public readonly virtualKeyboardActiveSubject = new BehaviorSubject(false);
  private readonly activeElementChangedSubject = new Subject<void>();
  private lastActiveElement: Element | null = null;

  readonly activeElementChanged$ = this.activeElementChangedSubject.asObservable();

  constructor() {
    window.addEventListener('focus', this.onElementFocusChange, true);
    window.addEventListener('blur', this.onElementFocusChange, true);
  }

  sendPinValue(isPin: boolean): void {
    this.isPinClicked.next(isPin);
  }

  getFlagFromPin(): Observable<boolean> {
    return this.isPinClicked.asObservable();
  }

  isVirtualKeyboardActive(): Observable<boolean> {
    return this.virtualKeyboardActiveSubject.asObservable();
  }

  private readonly onElementFocusChange = () => {
    if (document.activeElement === this.lastActiveElement) {
      return;
    }
    this.lastActiveElement = document.activeElement;
    this.activeElementChangedSubject.next();
  };
}
