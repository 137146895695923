import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NetworkService } from '@app/shared/services/network.service';
import * as createHttpError from 'http-errors';
import { lastValueFrom } from 'rxjs';
import { HandledIndexDBErrors } from '../constants/handled-indexdb-erros';
import { IndexDBError } from '../errors/indexDB.error';
import { OfflineError } from '../errors/offline.error';
import { LocalError } from '../errors/local.error';
import { UnknownTextbookError } from '../errors/unknown-request.error';
import { MemoryExhaustedError } from '@app/core/modules/error-handler/errors/memory-exhausted.error';
import { TextbookDemoForbiddenError } from '@app/core/modules/error-handler/errors/textbook-demo-forbidden.error';
import { TextbookClientEndpointEnum } from '@gwo/textbook-api-client';
import { URL_PARTS_SEPARATOR } from '@app/textbook/utils/url-manipulation-util';

@Injectable()
export class ErrorHandlerService {
  constructor(private readonly networkService: NetworkService) {
    (Error as unknown as { captureStackTrace: () => void }).captureStackTrace ??= () => {
      undefined;
    };
  }

  private parseCustomError(error: unknown): Error {
    if (
      error instanceof Error &&
      Object.values(HandledIndexDBErrors).includes(error.toString() as HandledIndexDBErrors)
    ) {
      return new IndexDBError(error.message);
    }
    return error as Error;
  }

  private isUnknownTextbookResponse(response: HttpErrorResponse): boolean {
    return (
      !!response.url?.includes('textbooks') && (response.status === 400 || response.status >= 404)
    );
  }

  private isTextbookDemoForbiddenResponse(response: HttpErrorResponse): boolean {
    const getTextbookDemoUrlPart = TextbookClientEndpointEnum.GET_TEXTBOOK_DEMO.url
      .split(URL_PARTS_SEPARATOR)
      .filter((part) => !!part)[0];
    return !!response.url?.includes(getTextbookDemoUrlPart) && response.status === 403;
  }

  private isMemoryExhaustedResponse(response: HttpErrorResponse): boolean {
    const errors = response.error?.errors;
    // TODO Zmiana sposobu wykrywania błędu związanego z brakiem pamięci - parsowanie html zwracanego w response
    return (
      !!errors &&
      errors.some(
        (err: { status: number; title: string }) =>
          err.status == MemoryExhaustedError.MEMORY_EXHAUSTED_ERROR_STATUS &&
          err.title == MemoryExhaustedError.MEMORY_EXHAUSTED_ERROR_TITLE
      )
    );
  }

  private async parseHttpResponse(
    response: HttpErrorResponse
  ): Promise<Error | createHttpError.HttpError> {
    if (!(await lastValueFrom(this.networkService.isOnlineMode))) {
      return new OfflineError(response.message);
    } else if (response.status === 0) {
      return new LocalError(response.message);
    } else if (this.isMemoryExhaustedResponse(response)) {
      return new MemoryExhaustedError();
    } else if (this.isTextbookDemoForbiddenResponse(response)) {
      return new TextbookDemoForbiddenError();
    } else if (this.isUnknownTextbookResponse(response)) {
      return new UnknownTextbookError();
    }
    return createHttpError(response.status);
  }

  async generateError(response: HttpErrorResponse): Promise<Error> {
    if (response instanceof HttpErrorResponse) {
      return await this.parseHttpResponse(response);
    }
    return this.parseCustomError(response);
  }
}
