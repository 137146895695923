import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-layout',
  templateUrl: './modal-layout.component.html',
  styleUrls: ['./modal-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalLayoutComponent {
  @Input() backdropOpacity = 0.8;
  @Input() maxWidth = '35rem';
  @Input() marginTop = '0.2rem';

  @Output() backdropClick = new EventEmitter<void>();

  onBackdropClick(): void {
    this.backdropClick.emit();
  }
}
