import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TextbookMorph } from '@app/shelf/models/textbook.model';

@Component({
  selector: 'app-confirm-delete-modal',
  templateUrl: './confirm-delete-modal.component.html',
  styleUrls: ['./confirm-delete-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDeleteModalComponent {
  @Input() textbook!: TextbookMorph;

  @Output() confirm = new EventEmitter<TextbookMorph>();
  @Output() reject = new EventEmitter<void>();

  onConfirm = (): void => this.confirm.emit(this.textbook);
  onReject = (): void => this.reject.emit();
}
