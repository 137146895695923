import { Capacitor } from '@capacitor/core';

export const environment = {
  production: true,
  textbookApiUrl: 'https://multipodreczniki.apps.gwo.pl/api',
  launcherApiUrl: 'https://launcher-multipodreczniki.apps.gwo.pl/api',
  rememberPasswordPageUrl: 'https://moje.gwo.pl/uzytkownik/przypomnienie-hasla',
  registryGwoUrl: 'https://moje.gwo.pl/uzytkownik/rejestracja',
  loginGwoUrl: 'https://moje.gwo.pl/',
  deleteAccountHelpPageUrl: 'https://gwo.pl/jak-usunac-konto/',
  privacyPolicyPageUrl: 'https://gwo.pl/polityka-prywatnosci-aplikacji-multiteka-gwo',
  iosStoreAppUrl: 'https://apps.apple.com/pl/app/multiteka-gwo/id1200803752?l=pl',
  androidStoreAppUrl: 'market://launch?id=pl.gwo.mhb',
  androidStoreAppDetailsUrl: 'market://details?id=pl.gwo.mhb',
  appMode: Capacitor.isNativePlatform(),
  logging: false,
  sentryDSN: 'https://de6fb993eb9145dba088165c82f56f5c@sentry2.gwo.pl/27',
  name: 'prod',
  reduxDevtools: false,
  userList: false,
  numberOfPagesInCache: 2,
  textbookAccessProlongedForPeriod: { quantity: 1, unit: 'w' },
  hourForDeleteExpiredTextbook: 12,
  memory: null,
  taskRunnerInterval: 2_000,
  textbookExpiredAfterDays: 7,
  editorPreviewMode: false,
};
