import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.scss'],
})
export class OfflineErrorComponent {
  constructor(private readonly router: Router) {}

  titleText = 'Uwaga!';
  messageText = 'Twoje urządzenie nie ma połączenia z internetem.';
  isMobile = environment.appMode;

  tryOneMoreTimeText = 'Spróbuj ponownie';
  backTOShelfText = 'Wróć do półki';

  backClick(): void {
    this.router.navigate(['/']);
  }
}
