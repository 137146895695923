import { Injectable } from '@angular/core';
import {
  selectAccessLevel,
  selectAllAccesses,
  selectIsPremiumAccess,
  selectUser,
} from '@app/core/store/selectors/user.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/store/reducers';

@Injectable()
export class UserFacade {
  selectUser$ = this.store.select(selectUser);
  selectAllAccesses$ = this.store.select(selectAllAccesses);
  selectAccessLevel$ = this.store.select(selectAccessLevel);
  selectIsPremiumAccess$ = this.store.select(selectIsPremiumAccess);

  constructor(private store: Store<AppState>) {}
}
