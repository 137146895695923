import { TextbookMorph, TextbookRecord } from '@app/shelf/models/textbook.model';
import { createAction, props } from '@ngrx/store';
import { DownloadRegistry, DownloadRegistryUnit } from '../reducers';
import { User } from '@gwo/textbook-api-client/lib/interface/user.model';

export const startDownload = createAction(
  '[Download-Registry] Start Download',
  props<{ textbook: TextbookMorph }>()
);

export const stopDownload = createAction(
  '[Download-Registry] Stop Download',
  props<{ index: string }>()
);

export const downloadStopped = createAction(
  '[Download-Registry] DownloadStopped',
  props<{ index: string }>()
);

export const updateUserData = createAction(
  '[Download-Registry] Update User Data',
  props<{ textbook: TextbookMorph }>()
);

export const updateTextbookData = createAction(
  '[Download-Registry] Update Textbook Data',
  props<{ textbook: TextbookMorph }>()
);

export const deleteTextbook = createAction(
  '[Download-Registry] Delete Textbook',
  props<{ textbook: TextbookMorph }>()
);

export const setDownloadRegistry = createAction(
  '[Download-Registry] Set Download Registry',
  props<{ registry: DownloadRegistry }>()
);

export const setDownloadRegistryUnit = createAction(
  '[Download-Registry] Set Download Unit',
  props<{ unit: DownloadRegistryUnit }>()
);

export const initialize = createAction(
  '[Textbooks] Initialize',
  props<{ textbooks: TextbookRecord; userExternalId?: User['externalId'] }>()
);
