import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TextbookClient } from '@gwo/textbook-api-client';

import { environment } from '@environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { interceptors } from './core/interceptors';
import { CoreModule } from './core/core.module';
import { appInitializer } from './app-initializer';
import { hammerConfig } from './hammer-config';
import { NoteBoxService } from './textbook/components/note-box/note-box-service';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [AppComponent],
  providers: [
    appInitializer,
    hammerConfig,
    NoteBoxService,
    ...interceptors,
    {
      provide: TextbookClient,
      useFactory: (http: HttpClient) =>
        new TextbookClient(http, environment.textbookApiUrl, environment.editorPreviewMode),
      deps: [HttpClient],
    },
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    HammerModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
