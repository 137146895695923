import { InjectionToken, ValueProvider } from '@angular/core';
import * as MobileDetect from 'mobile-detect';

const MobileDetectToken = new InjectionToken<MobileDetect>('TxtbMobileDetectProvider');

const MobileDetectProvider: ValueProvider = {
  provide: MobileDetectToken,
  useValue: new MobileDetect(window.navigator.userAgent),
};

export { MobileDetectToken, MobileDetectProvider };
