import { DataCleanerService } from './data-cleaner.service';
import { DownloadService } from './download.service';
import { FetchService } from './fetch.service';
import { FilesystemService } from './filesystem.service';
import { ShelfService } from './shelf.service';
import { RuntimeService } from './runtime.service';
import { DownloadAccessService } from '@app/shelf/services/download-access.service';
import { TaskRunnerService } from '@app/shelf/services/task-runner.service';

const services = [
  FetchService,
  FilesystemService,
  DownloadService,
  RuntimeService,
  DataCleanerService,
  DownloadAccessService,
  TaskRunnerService,
];

export {
  services,
  FetchService,
  FilesystemService,
  DownloadService,
  ShelfService,
  RuntimeService,
  DataCleanerService,
  DownloadAccessService,
  TaskRunnerService,
};
