import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TextbookMorph } from '@app/shelf/models/textbook.model';
import { TextbookStatus } from '@app/shared/pipes/translate-textbook-status.pipe';

@Component({
  selector: 'app-textbook-details-modal',
  templateUrl: './textbook-details-modal.component.html',
  styleUrls: ['./textbook-details-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextbookDetailsModalComponent {
  @Input() textbook!: TextbookMorph;
  @Output() closeClick = new EventEmitter<void>();
  readonly textbookStatusOpened = TextbookStatus.opened;
  readonly CONVERSION_RATIO = 1.4;

  onCloseClick = (): void => this.closeClick.emit();
}
