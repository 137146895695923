import { createReducer, on } from '@ngrx/store';

import { User } from '@gwo/textbook-api-client/lib/interface/user.model';
import { AuthActions, UserActions } from '@app/core/store/actions';
import { UserRole } from '@gwo/textbook-api-client/lib/interface/user-role';

export const userFeatureKey = 'user';

export interface UserState {
  user: User;
}

export const initialState: UserState = {
  user: {
    projectionId: '',
    externalId: 0,
    firstName: '',
    lastName: '',
    email: '',
    role: UserRole.User,
    avatarResourceId: null,
    textbookAccesses: [],
  },
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.setUserData, (state, { user }) => ({
    ...state,
    user,
  })),
  on(AuthActions.logout, (state) => ({
    ...state,
    ...initialState,
  }))
);
