import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-offline-warning-modal',
  templateUrl: './offline-warning-modal.component.html',
  styleUrls: ['./offline-warning-modal.component.scss'],
})
export class OfflineWarningModalComponent {
  @Output() closeClick = new EventEmitter<void>();

  onClose = (): void => this.closeClick.emit();
}
