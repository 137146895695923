import { Component } from '@angular/core';
import { AuthFacade } from '@app/core/store/facade/auth.facade';

@Component({
  selector: 'app-no-auth',
  templateUrl: './no-auth.component.html',
  styleUrls: ['./no-auth.component.scss'],
})
export class NoAuthComponent {
  constructor(private readonly authFacade: AuthFacade) {}

  logout(): void {
    this.authFacade.logout();
  }
}
