import { CoreRoute } from '@app/core/constants';
import { URL_PARTS_SEPARATOR } from '@app/textbook/utils/url-manipulation-util';

export enum ErrorRoute {
  OFFLINE = 'offline',
  NO_AUTH = 'no-auth',
  LOG = 'log',
  INACTIVE_TEXTBOOK_DEMO = 'demo-inactive',
}

export enum ErrorRouteQueryParam {
  RESOLVER_REDIRECTION = 'resolver-redirection',
}

export function isInactiveDemoRoute(locationPathName: string): boolean {
  return locationPathName.startsWith(
    `${URL_PARTS_SEPARATOR}${CoreRoute.Error}${URL_PARTS_SEPARATOR}${ErrorRoute.INACTIVE_TEXTBOOK_DEMO}${URL_PARTS_SEPARATOR}`
  );
}
