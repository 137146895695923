import { AppWarningMessage, MessageTypeEnum } from '@gwo/textbook-api-client';
import { isAppWarningMeta } from '@app/core/models/type-guards/is-app-warning-meta';

export function isAppWarningMessage(message: any): message is AppWarningMessage {
  const warningMessage = message as AppWarningMessage | undefined;
  return (
    MessageTypeEnum.WARNING === warningMessage?.type &&
    !!warningMessage?.id &&
    isAppWarningMeta(warningMessage?.meta)
  );
}
