import { Pipe, PipeTransform } from '@angular/core';

enum TextbookLevel {
  'student_basic' = 'uczniowski',
  'student_premium' = 'uczniowski',
  'teacher' = 'nauczycielski',
}

@Pipe({
  name: 'translateTextbookLevel',
})
export class TranslateTextbookLevelPipe implements PipeTransform {
  transform(access: string | undefined): TextbookLevel {
    return TextbookLevel[access as keyof typeof TextbookLevel];
  }
}
