import { Injectable } from '@angular/core';
import { DownloadService, RuntimeService } from '@app/shelf/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, startWith, switchMap, tap } from 'rxjs';
import { DownloadRegistryActions } from '../actions';
import { DownloadRegistryFacade, TextbooksFacade } from '../facade';
import { AuthActions, ErrorActions } from '@app/core/store/actions';
import { Action } from '@ngrx/store';
import { distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { TextbookRecord } from '@app/shelf/models/textbook.model';

@Injectable()
export class DownloadRegistryEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly downloadService: DownloadService,
    private readonly textbooksFacade: TextbooksFacade,
    private readonly downloadRegistryFacade: DownloadRegistryFacade,
    private readonly runtimeService: RuntimeService
  ) {}

  startDownload$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DownloadRegistryActions.startDownload),
        map(({ textbook }) => {
          this.downloadService.startDownload(textbook);
        })
      ),
    { dispatch: false }
  );

  stopDownload$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DownloadRegistryActions.stopDownload),
        tap(({ index }) => this.downloadService.stopDownload(index))
      ),
    { dispatch: false }
  );

  deleteTextbook$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DownloadRegistryActions.deleteTextbook),
        tap(({ textbook }) => this.downloadService.deleteDownloadedTextbook(textbook))
      ),
    { dispatch: false }
  );

  // TODO Aktualizacja treści użytkownika - do przywrócenia po opracowaniu mechanizmu aktualizacji
  // updateUserData$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(DownloadRegistryActions.updateUserData),
  //       tap(({ textbook }) => this.downloadService.startUserDataUpdate(textbook))
  //     ),
  //
  //   { dispatch: false }
  // );

  updateTextbookData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DownloadRegistryActions.updateTextbookData),
        map(({ textbook }) => this.downloadService.updateTextbookData(textbook))
      ),
    { dispatch: false }
  );

  init$ = createEffect(
    () =>
      this.actions$.pipe(
        startWith({ type: DownloadRegistryActions.initialize.type } as Action),
        ofType(DownloadRegistryActions.initialize),
        distinctUntilChanged(
          (prev, current) =>
            !!prev?.userExternalId && current.userExternalId === prev.userExternalId
        ),
        map((initAction) => initAction?.textbooks),
        filter((textbooks) => !!textbooks && !!Object.keys(textbooks).length),
        switchMap((textbooks: TextbookRecord) =>
          this.runtimeService.initDownloadRegistry(textbooks)
        ),
        map((registry) => this.downloadRegistryFacade.setDownloadRegistry(registry))
      ),
    { dispatch: false }
  );

  refreshRegistryForTextbook$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DownloadRegistryActions.downloadStopped),
        withLatestFrom(this.downloadRegistryFacade.selectDownloadRegistry$()),
        switchMap(([action, registry]) =>
          this.runtimeService.refreshTextbookDownloadRegistry(action.index, registry)
        ),
        map((registry) => this.downloadRegistryFacade.setDownloadRegistry(registry))
      ),
    { dispatch: false }
  );

  stopDownloadByLogoutOrError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logoutSuccess, ErrorActions.errorOccurred),
        switchMap(() => this.downloadRegistryFacade.getDownloadingTextbook()),
        filter(Boolean),
        map((unit) => this.downloadService.stopDownload(unit.textbookIndex))
      ),
    { dispatch: false }
  );
}
