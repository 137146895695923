export class MemoryExhaustedError extends Error {
  static readonly MEMORY_EXHAUSTED_ERROR_TITLE = 'Memory Exhausted';
  static readonly MEMORY_EXHAUSTED_ERROR_STATUS = 500;

  status!: number;

  constructor(msg = MemoryExhaustedError.MEMORY_EXHAUSTED_ERROR_TITLE) {
    super(msg);
    this.status = MemoryExhaustedError.MEMORY_EXHAUSTED_ERROR_STATUS;
  }
}
