<app-modal-layout marginTop="2.5rem" (backdropClick)="close()">
  <div class="content">
    <button class="close-btn" (click)="close()"><app-icon name="close"></app-icon></button>

    <p class="header">Uwaga!</p>

    <p class="question">
      Nie posiadasz wystarczającej ilości wolnego miejsca,<br />
      by pobrać ten multipodręcznik do pamięci swojego urządzenia.
    </p>

    <div>
      <button (click)="close()">OK</button>
    </div>
  </div>
</app-modal-layout>
