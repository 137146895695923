import { createFeatureSelector, createSelector } from '@ngrx/store';

import { errorFeatureKey, ErrorState } from '@app/core/store/reducers/error.reducer';
import { AppState } from '../reducers';

export const selectErrorState = createFeatureSelector<ErrorState>(errorFeatureKey);

export const selectError = createSelector<AppState, ErrorState, ErrorState['error']>(
  selectErrorState,
  ({ error }) => error
);

export const selectHash = createSelector<AppState, ErrorState, ErrorState['hash']>(
  selectErrorState,
  ({ hash }) => hash
);
