export enum FileExtension {
  Jpg = '.jpg',
  Pdf = '.pdf',
  Txt = '.txt',
  Html = '.html',
  Mp3 = '.mp3',
  Png = '.png',
  Svg = '.svg',
  Json = '.json',
  Css = '.css',
  Js = '.js',
  Avi = '.avi',
  Wav = '.wav',
}
