<div *ngIf="type$ | async as type">
  <app-full-screen-modal *ngIf="type !== 'unknown'" [ngSwitch]="type">
    <div class="header">
      <ng-container *ngSwitchCase="'session'">Uwaga!</ng-container>

      <ng-container *ngSwitchCase="'no-internet'">Błąd połączenia</ng-container>

      <ng-container *ngSwitchCase="'filesystem'">Błąd Danych</ng-container>

      <ng-container *ngSwitchDefault>Nieznany błąd</ng-container>
    </div>
    <div class="content">
      <ng-container *ngSwitchCase="'session'">
        <p>Sesja wygasła. Zaloguj się ponownie.</p>
        <div class="buttons">
          <button class="ok-btn" (click)="onReLoginClick()">OK</button>
          <button class="cache-btn" (click)="onCacheClearClick()">Wyczyść cache aplikacji</button>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'no-internet'">
        <p>Twoje połączenie internetowe zostało zerwane.</p>
        <p>Odśwież stronę gdy uzyskasz je ponownie.</p>
        <div class="buttons">
          <button (click)="onRefresh()">Odśwież</button>
          <button (click)="onCacheClearClick()">Wyczyść cache aplikacji</button>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'filesystem'">
        <p>Wystąpił błąd przy operacji na danych.</p>
        <p>Problem może rozwiązać odświeżenie strony.</p>
        <div class="buttons">
          <button [disabled]="isDeleting$ubject | async" (click)="onReBootClick()">Odśwież</button>
          <button [disabled]="isDeleting$ubject | async" (click)="onDropDataClick()">
            {{ (isDeleting$ubject | async) ? 'trwa usuwanie...' : 'Wyczyść dane aplikacji' }}
          </button>
        </div>
        <button (click)="onCacheClearClick()">Wyczyść cache aplikacji</button>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <p>Wystąpił nieznany błąd.</p>
        <p *ngIf="hash$ | async as hash">Kod błędu: {{ hash }}</p>
        <p>Spróbuj jednej z poniższych opcji aby naprawić problem.</p>
        <div class="buttons">
          <button (click)="onRefresh()">Odśwież</button>
          <button (click)="onReLoginClick()">Zaloguj się ponownie</button>
          <button (click)="onCacheClearClick()">Wyczyść cache aplikacji</button>
        </div>
      </ng-container>
    </div>
  </app-full-screen-modal>
</div>
