<app-modal-layout (backdropClick)="onClose()">
  <div class="modal">
    <header>
      <h5>Pomoc</h5>
      <button (click)="onClose()" class="button-close">
        <app-icon name="close"></app-icon>
      </button>
    </header>

    <main *ngIf="!isAuthToken.value" class="help_modal">
      <p>
        <span class="italic"><strong>MULTI</strong>teka GWO</span>
        to aplikacja, dzięki której możesz korzystać ze swoich multipodręczników na urządzeniach
        mobilnych.
      </p>

      <p>
        Gdy się w niej zalogujesz, zobaczysz wszystkie multipodręczniki, które zostały odblokowane
        na Twoim koncie w portalu gwo.pl. Jeżeli więc nie widzisz w aplikacji jakiegoś
        multipodręcznika, upewnij się, czy masz do niego dostęp w Moim GWO.
      </p>

      <div>
        <div class="strong_title">
          <strong> Aktywacja kodu dostępu do multipodręcznika</strong>
        </div>
        <p class="help_description">
          Wejdź na wpiszkod.gwo.pl, zaloguj się za pomocą loginu i hasła z portalu gwo.pl, a
          następnie wpisz swój kod w odpowiednim polu i kliknij Dalej.
        </p>
      </div>

      <div>
        <div class="strong_title">
          <strong>Pomoc techniczna</strong>
        </div>
        tel. 58 764 61 61<br />
        e-mail: kontakt@gwo.pl
      </div>
    </main>

    <main *ngIf="isAuthToken.value" class="help_modal">
      <div class="help_wrapper">
        <ul>
          <p>Funkcje dostępne z poziomu półki:</p>
          <li>1) <strong> Sprawdzanie informacji o posiadanym multipodręczniku.</strong></li>
          <li>
            2) <strong> Pobieranie multipodręcznika do pamięci urządzenia, </strong> by korzystać z
            książki również w trybie offline (bez internetu). W pobranym multipodręczniku
            niedostępne są ćwiczenia interaktywne, animacje oraz filmy, a także następujące funkcje:
            zakreślacz, zakładki i możliwość zapisywania notatek graficznych.
          </li>
          <li>
            3) <strong> Aktualizowanie pobranego multipodręcznika </strong> do jego nowszej wersji.
          </li>
          <li>
            4) <strong> Usuwanie multipodręcznika z pamięci urządzenia, </strong> by korzystać z
            książki jedynie w trybie online (przy włączonym internecie).
          </li>
          <hr />
          <li>
            <strong> Usuwanie konta w serwisie GWO – </strong>
            <a id="delete-account-help" href="{{ deleteAccountHelpPageUrl }}">zobacz instrukcję</a>.
          </li>
          <li>
            <strong> Polityka prywatności MULTIteki GWO – </strong>
            <a id="privacy-policy-help" href="{{ privacyPolicyPageUrl }}">zapoznaj się</a>.
          </li>
        </ul>
        <div class="help_dashboard">
          <img [src]="pathImage" alt="" />
        </div>
      </div>
    </main>
  </div>
</app-modal-layout>
