import { DownloadRegistryUnit } from '@app/shelf/store/reducers';
import { TextbookMorph } from '@app/shelf/models/textbook.model';

export class TextbookStatusUtil {
  static isFullUpdateAvailable(
    textbookRegistryUnit: DownloadRegistryUnit,
    textbook: TextbookMorph
  ): boolean {
    return textbookRegistryUnit?.version < textbook.version;
  }
}
