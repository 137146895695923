import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ShelfState } from '../reducers';
import { shelfFeatureKey } from '../shelf-store.module';

const selectShelfState = createFeatureSelector<ShelfState>(shelfFeatureKey);

export const selectDownloadRegistry = createSelector(
  selectShelfState,
  ({ downloadRegistry }) => downloadRegistry
);
