import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { logger } from '@app/shared/utils';

// @TODO: Wait for sentry dsn
// Sentry.init({
//   dsn: environment.sentryDSN,
//   environment: environment.name,
// });

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    // @TODO: Wait for sentry dsn
    // const errorCode = 'BOOTSTRAP_ERROR';
    // Sentry.withScope((scope) => scope.setTags({ errorCode }));
    // Sentry.captureException(err);
    logger.error(err);
  });
