import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MergedRoute, MergedRouteReducerState } from '@app/router/merged-route';

import { AppState } from '@app/core/store/reducers';
import { routerStateConfig } from '@app/router/ngrx-router.module';

export const getRouterReducerState = createFeatureSelector<MergedRouteReducerState>(
  routerStateConfig.stateKey
);
export const getMergedRoute = createSelector<AppState, MergedRouteReducerState, MergedRoute>(
  getRouterReducerState,
  (routerReducerState) => routerReducerState.state
);
export const selectTextbookId = createSelector<AppState, MergedRoute, string>(
  getMergedRoute,
  (routeState) => routeState.params.textbookId
);
export const selectTextbookAccessId = createSelector<AppState, MergedRoute, number>(
  getMergedRoute,
  (routeState) => +routeState.queryParams.accessId
);
