import * as MobileDetect from 'mobile-detect';

export class MobileDetectUtil {
  public static isMobile(mobileDetect: MobileDetect): boolean {
    return !!mobileDetect.mobile() || MobileDetectUtil.isIOSRequestingDesktopVersion();
  }

  public static isIOSRequestingDesktopVersion(): boolean {
    const platform = window.navigator.platform;
    const userAgent = window.navigator.userAgent;
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        platform
      ) ||
      (userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
}
