import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { components } from './components';
import { SharedModule } from '@app/shared/shared.module';
import { ErrorsRoutingModule } from './errors-routing.module';
import { DemoInactiveErrorComponent } from './components/demo-inactive-error/demo-inactive-error.component';

@NgModule({
  declarations: [...components, DemoInactiveErrorComponent],
  imports: [CommonModule, SharedModule, ErrorsRoutingModule],
})
export class ErrorsModule {}
