import { Capacitor } from '@capacitor/core';
import { environment as prodEnvironment } from './environment.prod';

export const environment = {
  production: false,
  textbookApiUrl: 'https://multipodreczniki.apps.gwodev.pl/api',
  launcherApiUrl: 'https://launcher-multipodreczniki.apps.gwodev.pl/api',
  rememberPasswordPageUrl: 'https://moje.gwodev.pl/uzytkownik/przypomnienie-hasla',
  registryGwoUrl: 'https://moje.gwodev.pl/uzytkownik/rejestracja',
  loginGwoUrl: 'https://moje.gwodev.pl/',
  deleteAccountHelpPageUrl: prodEnvironment.deleteAccountHelpPageUrl,
  privacyPolicyPageUrl: prodEnvironment.privacyPolicyPageUrl,
  iosStoreAppUrl: prodEnvironment.iosStoreAppUrl,
  androidStoreAppUrl: prodEnvironment.androidStoreAppUrl,
  androidStoreAppDetailsUrl: prodEnvironment.androidStoreAppDetailsUrl,
  appMode: Capacitor.isNativePlatform() || !!Number(localStorage.getItem('isMobile')),
  logging: true,
  sentryDSN: '??',
  name: 'development',
  reduxDevtools: true,
  userList: true,
  numberOfPagesInCache: 2,
  textbookAccessProlongedForPeriod: { quantity: 1, unit: 'w' },
  hourForDeleteExpiredTextbook: 12,
  memory: 200_000,
  taskRunnerInterval: 5_000,
  textbookExpiredAfterDays: 7,
  editorPreviewMode: false,
};
