import { createReducer, on } from '@ngrx/store';
import { AuthActions } from '@app/core/store/actions';

export const authFeatureKey = 'auth';

export interface AuthState {
  isFormValid: boolean;
}

export const initialState: AuthState = {
  isFormValid: true,
};

export const authReducer = createReducer(
  initialState,
  on(AuthActions.setFormValid, (state, { isFormValid }) => ({
    ...state,
    isFormValid,
  }))
);
