import { TextbookAccess } from '@gwo/textbook-api-client/lib/interface/textbook-access.model';
import { isEmpty as _isEmpty, xor as _xor } from 'lodash';

export class ProjectionUtil {
  public static isProjectionChangePresent(
    prevAccesses: TextbookAccess[],
    currAccessess: TextbookAccess[]
  ): boolean {
    return (
      ProjectionUtil.isMissingAccessPresent(prevAccesses, currAccessess) ||
      ProjectionUtil.isChangedAccessPresent(prevAccesses, currAccessess)
    );
  }

  private static isMissingAccessPresent(
    prevAccesses: TextbookAccess[],
    currAccessess: TextbookAccess[]
  ): boolean {
    const prevAccessIds = prevAccesses.map((access) => access.accessId);
    const currAccessIds = currAccessess.map((access) => access.accessId);
    return !_isEmpty(_xor(prevAccessIds, currAccessIds));
  }

  private static isChangedAccessPresent(
    prevAccesses: TextbookAccess[],
    currAccessess: TextbookAccess[]
  ): boolean {
    return prevAccesses.some((prevAccess) => {
      const toCompare = currAccessess.find((access) => access.accessId === prevAccess.accessId);
      return !this.isEqual(prevAccess, toCompare);
    });
  }

  private static isEqual(access: TextbookAccess, second: TextbookAccess | undefined): boolean {
    return !!second && JSON.stringify(access) === JSON.stringify(second);
  }
}
