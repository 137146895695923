import { Injectable } from '@angular/core';
import {
  DataCleanerService,
  FetchService,
  RuntimeService,
  ShelfService,
} from '@app/shelf/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { first, map, switchMap, tap } from 'rxjs';
import { ShelfActions } from '../actions';
import { DownloadRegistryFacade, SelectedTextbookFacade, TextbooksFacade } from '../facade';
import { withLatestFrom } from 'rxjs/operators';
import { UserFacade } from '@app/core/store/facade/user.facade';

@Injectable()
export class ShelfEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly fetchService: FetchService,
    private readonly downloadRegistryFacade: DownloadRegistryFacade,
    private readonly textbookFacade: TextbooksFacade,
    private readonly dataCleanerService: DataCleanerService,
    private readonly runtimeService: RuntimeService,
    private readonly selectedTextbookFacade: SelectedTextbookFacade,
    private readonly userFacade: UserFacade,
    private readonly shelfService: ShelfService
  ) {}

  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShelfActions.init),
        switchMap(() => this.fetchService.getTextbooks$()),
        tap((textbooks) => this.textbookFacade.setTextbooks(textbooks)),
        withLatestFrom(this.userFacade.selectUser$),
        map(([textbooks, user]) =>
          this.downloadRegistryFacade.initialize(textbooks, user.externalId)
        ),
        map(() => this.dataCleanerService.checkSystemForGarbage()),
        map(() => this.selectedTextbookFacade.cleanSelectedTextbook()),
        switchMap(() => this.runtimeService.initWakeLock()),
        tap(() => this.shelfService.shelfInitializationDone())
      ),
    { dispatch: false }
  );

  firstInit$ = createEffect(
    () =>
      this.actions$.pipe(
        first(),
        map(() => this.dataCleanerService.serviceWorkerStart())
      ),
    { dispatch: false }
  );

  refreshTextbooks$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShelfActions.refreshTextbooks),
        switchMap(() => this.fetchService.getTextbooks$()),
        map((textbooks) => this.textbookFacade.setTextbooks(textbooks))
      ),
    { dispatch: false }
  );
}
