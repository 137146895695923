import {
  AppMessage,
  AppWarningMessage,
  MessagePeriodicityEnum,
  MessageTypeEnum,
} from '@gwo/textbook-api-client';
import { isAppWarningMessage } from '@app/core/models/type-guards/is-app-warning-message';

const MINUTES_SECONDS_MAX_VALUE = 59;
const TIME_MIN_VALUE = 0;

const HOURS_MINUTES_SEPARATOR = ':';
const TIME_24H_REGEX = /^([01]?[0-9]|2[0-3]):([0-5]?[0-9])$/;

const HOUR_IN_MS = 1000 * 60 * 60;
const DAY_IN_MS = HOUR_IN_MS * 24;
const WEEK_IN_MS = DAY_IN_MS * 7;
const MONTH_IN_MS = DAY_IN_MS * 30;
const PERIODICITY_MS_MAP: { [key in MessagePeriodicityEnum]: number } = {
  [MessagePeriodicityEnum.HOURLY]: HOUR_IN_MS,
  [MessagePeriodicityEnum.DAILY]: DAY_IN_MS,
  [MessagePeriodicityEnum.WEEKLY]: WEEK_IN_MS,
  [MessagePeriodicityEnum.MONTHLY]: MONTH_IN_MS,
};

export class AppMessagesUtil {
  public static isAppLocked(message: AppMessage | null): boolean {
    if (!message) {
      return false;
    }
    if (MessageTypeEnum.LOCK === message.type) {
      return true;
    }
    if (isAppWarningMessage(message)) {
      return AppMessagesUtil.isLocked(message);
    }
    return false;
  }

  public static getHtmlMessage(message: AppMessage | null): string {
    if (MessageTypeEnum.LOCK === message?.type) {
      return message.meta.messageHtml;
    }
    if (isAppWarningMessage(message)) {
      const lockInfo = message.meta.lockAppInfo;
      return !!lockInfo && AppMessagesUtil.isLocked(message)
        ? lockInfo.message.messageHtml
        : message.meta.messageHtml;
    }
    return '';
  }

  public static isWarningPeriodMatching(
    periodicity: MessagePeriodicityEnum,
    lastWarningDisplayTime: number
  ): boolean {
    const timeUntilLastWarningDisplay = Date.now() - (lastWarningDisplayTime || Date.now());
    return (
      !timeUntilLastWarningDisplay || PERIODICITY_MS_MAP[periodicity] < timeUntilLastWarningDisplay
    );
  }

  public static isWarningTimeMatching(warningMessage: AppWarningMessage): boolean {
    const startDate = AppMessagesUtil.getCurrentDateAt(
      warningMessage.meta.startTime24H,
      TIME_MIN_VALUE
    ).getTime();
    const endDate = AppMessagesUtil.getCurrentDateAt(
      warningMessage.meta.endTime24H,
      MINUTES_SECONDS_MAX_VALUE
    ).getTime();
    const currentDatetime = Date.now();
    return startDate <= currentDatetime && endDate >= currentDatetime;
  }

  private static isLocked(warningMessage: AppWarningMessage): boolean {
    const lockInfo = warningMessage.meta.lockAppInfo;
    return !!lockInfo?.lockDate && new Date(lockInfo.lockDate).getTime() <= Date.now();
  }

  private static getCurrentDateAt(time24H: string, seconds = 0): Date {
    if (!TIME_24H_REGEX.test(time24H)) {
      throw new Error('Invalid time value for 24H time format');
    }
    const splittedTime = time24H.split(HOURS_MINUTES_SEPARATOR);
    const hours = +splittedTime[0];
    const minutes = +splittedTime[1];
    const currentDate = new Date();
    currentDate.setHours(hours, minutes, seconds);
    return currentDate;
  }
}
