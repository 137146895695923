<div
  class="wrapper"
  [style.--opacity]="
    !isConnected && (textbookRegistry$ | async)?.status !== downloadUnitStatus.downloaded ? 0.1 : 1
  "
>
  <div *ngIf="canBeUpdated$ | async" id="update-alert">
    <div id="update-alert-circle">
      <p>!</p>
    </div>
  </div>
  <img
    [src]="src"
    [alt]="textbook.access.name"
    [class.disabled]="
      textbook.access.accessStatus === accessStatusFinished ||
      isExpired ||
      (textbookRegistry$ | async)?.status === downloadUnitStatus.updating
    "
  />
  <button
    [id]="'cover-open-textbook-btn-' + textbook?.access?.accessId"
    class="btn-open"
    type="button"
    (click)="onOpenTextbook()"
    [class.disabled]="
      !isConnected && (textbookRegistry$ | async)?.status !== downloadUnitStatus.downloaded
    "
    [disabled]="
      (textbookRegistry$ | async)?.status === downloadUnitStatus.updating ||
      (textbookRegistry$ | async)?.status === downloadUnitStatus.process
    "
  ></button>

  <div
    *ngIf="
      (textbookRegistry$ | async)?.status === downloadUnitStatus.process ||
      (textbookRegistry$ | async)?.status === downloadUnitStatus.paused ||
      (textbookRegistry$ | async)?.status === downloadUnitStatus.markedForDrop ||
      (textbookRegistry$ | async)?.status === downloadUnitStatus.updating
    "
    class="download-info"
  >
    <app-progress
      *ngIf="
        (textbookRegistry$ | async)?.status === downloadUnitStatus.process ||
        (textbookRegistry$ | async)?.status === downloadUnitStatus.paused
      "
      [value]="(textbookRegistry$ | async)!.downloadPercent || 0"
    ></app-progress>

    <div
      *ngIf="
        (textbookRegistry$ | async)?.status === downloadUnitStatus.markedForDrop ||
        (textbookRegistry$ | async)?.status === downloadUnitStatus.updating
      "
      class="delete-info"
    >
      <app-spinner></app-spinner>
      <p>
        {{
          (textbookRegistry$ | async)?.status === downloadUnitStatus.updating
            ? 'Pobieranie'
            : 'Usuwanie'
        }}
      </p>
    </div>
  </div>

  <button
    [id]="'cover-textbook-info-btn-' + textbook?.access?.accessId"
    class="btn-info"
    type="button"
    (click)="showInfo()"
    [disabled]="!isConnected && (isDownloaded$ | async) === false"
  >
    <app-icon name="info"></app-icon>
  </button>

  <button
    *ngIf="isConnected && (textbookRegistry$ | async) === undefined"
    [id]="'cover-download-textbook-btn-' + textbook?.access?.accessId"
    class="btn-download1"
    type="button"
    (click)="tryDownloadTextbook()"
    [disabled]="(canBeDownloaded$ | async) === false || isExpired"
  >
    <app-icon [class.disabled]="isExhaustedDownloadLimit" name="download"></app-icon>
  </button>

  <ng-container *ngIf="textbookRegistry$ | async as registryUnit">
    <button
      *ngIf="
        (canBeUpdated$ | async) &&
        (registryUnit.status === downloadUnitStatus.downloaded ||
          registryUnit.status === downloadUnitStatus.paused)
      "
      [id]="'cover-update-textbook-btn-' + textbook?.access?.accessId"
      class="btn-download1"
      type="button"
      [disabled]="!isConnected || isExpired || (canBeDownloaded$ | async) === false"
      (click)="onUpdate()"
    >
      <app-icon name="check for updates"></app-icon>
    </button>
  </ng-container>

  <button
    *ngIf="isConnected && (textbookRegistry$ | async)?.status === downloadUnitStatus.process"
    [id]="'cover-pause-download-btn-' + textbook?.access?.accessId"
    class="btn-download1"
    type="button"
    (click)="onStopDownload()"
  >
    <app-icon name="pause"></app-icon>
  </button>

  <button
    *ngIf="
      isConnected &&
      (textbookRegistry$ | async)?.status === downloadUnitStatus.paused &&
      (canBeUpdated$ | async) !== true
    "
    [id]="'cover-continue-download-btn-' + textbook?.access?.accessId"
    class="btn-download1"
    type="button"
    (click)="continueDownload()"
    [disabled]="(canBeDownloaded$ | async) === false || isExpired"
  >
    <app-icon name="continue"></app-icon>
  </button>

  <button
    *ngIf="
      (textbookRegistry$ | async)?.status === downloadUnitStatus.downloaded ||
      (textbookRegistry$ | async)?.status === downloadUnitStatus.paused ||
      (textbookRegistry$ | async)?.status === downloadUnitStatus.process
    "
    [id]="'cover-delete-textbook-btn-' + textbook?.access?.accessId"
    class="btn-download2"
    type="button"
    (click)="delete()"
  >
    <app-icon name="delete"></app-icon>
  </button>
</div>
<span id="access-color-bar" [class]="textbook.access.accessLevel"></span>
<div id="shelf"></div>
