import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { ErrorModalWrapperComponent } from './error-modal-wrapper/error-modal-wrapper.component';
import { globalErrorHandler } from './global-error-handler';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { ErrorHandlerService } from './services/errorHandler.service';

@NgModule({
  providers: [
    globalErrorHandler,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    ErrorHandlerService,
  ],
  imports: [SharedModule],
  declarations: [ErrorModalWrapperComponent],
  exports: [ErrorModalWrapperComponent],
})
export class ErrorHandlerModule {}
