import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/store/reducers';
import {
  selectApplicationStoreButtonVisibility,
  selectAppLocked,
  selectHtmlMessage,
  selectMessageId,
  selectWarningModalVisibility,
} from '@app/core/store/selectors/system-maintenance.selectors';
import { warningMessageConfirmed } from '@app/core/store/actions/system-maintenance.actions';

@Injectable()
export class SystemMaintenanceFacade {
  readonly messageId$ = this.store.select(selectMessageId);
  readonly appLocked$ = this.store.select(selectAppLocked);
  readonly htmlMessage$ = this.store.select(selectHtmlMessage);
  readonly applicationStoreButtonVisible$ = this.store.select(
    selectApplicationStoreButtonVisibility
  );
  readonly warningModalVisible$ = this.store.select(selectWarningModalVisibility);

  constructor(private store: Store<AppState>) {}

  warningMessageConfirmed(): void {
    this.store.dispatch(warningMessageConfirmed());
  }
}
