import { Injectable } from '@angular/core';
import { UserFacade } from '@app/core/store/facade/user.facade';
import { TextbookAccess } from '@gwo/textbook-api-client/lib/interface/textbook-access.model';
import { from, Observable, of, reduce, switchMap } from 'rxjs';
import { DownloadRegistryFacade } from '@app/shelf/store/facade';
import { filter, map } from 'rxjs/operators';
import { DownloadRegistry, DownloadRegistryUnit } from '@app/shelf/store/reducers';

@Injectable()
export class UserContentService {
  constructor(
    private readonly userFacade: UserFacade,
    private readonly downloadRegistryFacade: DownloadRegistryFacade
  ) {}

  private readonly currentAllAccesses = this.userFacade.selectAllAccesses$;

  markRegistryUnitsReadyToUpdate$(accessesFromServer: TextbookAccess[]): Observable<void> {
    return this.currentAllAccesses.pipe(
      switchMap((allAccesses) => UserContentService.findDownloadedAccesses(allAccesses)),
      switchMap((downloadedAccesses) =>
        this.findAccessesWithModifiedUserContent(downloadedAccesses, accessesFromServer)
      ),
      filter((accesses) => accesses.length > 0),
      switchMap((accessesWithModifiedUserContent) =>
        this.updateUnits(accessesWithModifiedUserContent)
      )
    );
  }

  private static findDownloadedAccesses(
    textbookAccesses: TextbookAccess[]
  ): Observable<TextbookAccess[]> {
    return of(textbookAccesses).pipe(
      map((textbookAccesses) => textbookAccesses.filter(({ downloads }) => downloads))
    );
  }

  private findAccessesWithModifiedUserContent(
    accessesFromStore: TextbookAccess[],
    accessesFromServer: TextbookAccess[]
  ): Observable<TextbookAccess[]> {
    return from(accessesFromStore).pipe(
      reduce((result: TextbookAccess[], textbookAccess) => {
        accessesFromServer.forEach((accessFromServer) => {
          const isTheSameAccess = textbookAccess.accessId === accessFromServer.accessId;
          const isNotModifiedUserContent =
            textbookAccess.lastModifiedUserContent === accessFromServer.lastModifiedUserContent;
          return (result =
            isTheSameAccess && !isNotModifiedUserContent
              ? [...result, textbookAccess]
              : [...result]);
        });
        return result;
      }, [])
    );
  }

  private updateUnits(textbookAccesses: TextbookAccess[]): Observable<void> {
    return this.downloadRegistryFacade.getDownloadRegistry().pipe(
      map((registry) =>
        this.findUnitsToUpdate(registry, textbookAccesses).forEach((unitToUpdate) => {
          this.downloadRegistryFacade.setDownloadRegistry({
            ...registry,
            [unitToUpdate.textbookIndex]: UserContentService.updateUnit(unitToUpdate),
          });
        })
      )
    );
  }

  private findUnitsToUpdate(
    registry: DownloadRegistry,
    textbookAccesses: TextbookAccess[]
  ): DownloadRegistryUnit[] {
    return Object.values(registry).filter(({ textbookAccessId }) =>
      textbookAccesses.map(({ accessId }) => accessId === textbookAccessId)
    );
  }

  private static updateUnit(unit: DownloadRegistryUnit): DownloadRegistryUnit {
    return {
      ...unit,
      isUserContentModified: true,
    };
  }
}
