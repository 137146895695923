import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { CoreRoute } from '@app/core/constants';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/store/reducers';
import { selectError, selectHash } from '@app/core/store/selectors';
import { filterNullish } from '@app/shared/utils';
import { AuthFacade } from '@app/core/store/facade/auth.facade';

@Component({
  selector: 'app-error-modal-wrapper',
  templateUrl: './error-modal-wrapper.component.html',
  styleUrls: ['./error-modal-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorModalWrapperComponent {
  error$ = this.store.select(selectError);
  hash$ = this.store.select(selectHash);

  type$ = this.error$.pipe(
    filterNullish(),
    map(() => {
      return 'unknown';
    })
  );

  isDeleting$ubject = new BehaviorSubject(false);

  constructor(
    private router: Router,
    private authFacade: AuthFacade,
    private store: Store<AppState>
  ) {}

  async onReLoginClick(): Promise<void> {
    this.authFacade.logout();
    await this.router.navigate([CoreRoute.Auth]);
    this.onRefresh();
  }

  async onReBootClick(): Promise<void> {
    await this.router.navigate(['/']);
    this.onRefresh();
  }

  async onDropDataClick(): Promise<void> {
    this.isDeleting$ubject.next(true);
  }

  async onCacheClearClick(): Promise<void> {
    const isMobile = localStorage.getItem('isMobile');
    localStorage.clear();
    localStorage.setItem('isMobile', isMobile || '0');
    await this.onReBootClick();
  }

  onRefresh(): void {
    window.location.reload();
  }
}
