import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth.interceptor';
import { OfflineInterceptor } from './offline.interceptor';

const coreInterceptors = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: OfflineInterceptor,
    multi: true,
  },
];

const interceptors = [...coreInterceptors];

export { interceptors, AuthInterceptor, OfflineInterceptor };
