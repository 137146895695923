import { Injectable } from '@angular/core';
import { AppState } from '@app/core/store/reducers';
import { Store } from '@ngrx/store';
import { ShelfActions } from '../actions';

@Injectable()
export class ShelfFacade {
  constructor(private readonly store: Store<AppState>) {}

  init(): void {
    return this.store.dispatch(ShelfActions.init());
  }

  exit(): void {
    return this.store.dispatch(ShelfActions.exit());
  }

  refreshTextbooks(): void {
    this.store.dispatch(ShelfActions.refreshTextbooks());
  }
}
