import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { SystemMaintenanceFacade } from '@app/core/store/facade/system-maintenance.facade';
import { MobileDetectUtil } from '@app/core/utils/mobile-detect.util';
import { environment } from '@environments/environment';
import { BehaviorSubject, from, map, switchMap, take, timer } from 'rxjs';
import { Device } from '@capacitor/device';
import { distinctUntilChanged, filter, first, tap } from 'rxjs/operators';
import { OperatingSystem } from '@gwo/textbook-actions';

const ONE_SECOND_MS = 1000;
const APP_WARNING_MODAL_VISIBILITY_SECONDS = 10;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnDestroy {
  private readonly warningModalCloseDisabledBS = new BehaviorSubject<boolean>(false);
  readonly appLocked$ = this.systemMaintenanceFacade.appLocked$;
  readonly htmlMessage$ = this.systemMaintenanceFacade.htmlMessage$;
  readonly applicationStoreButtonVisible$ =
    this.systemMaintenanceFacade.applicationStoreButtonVisible$;
  readonly warningModalVisible$ = this.systemMaintenanceFacade.warningModalVisible$;
  readonly warningModalCloseDisabled$ = this.warningModalCloseDisabledBS.asObservable();

  readonly warningModalCloseTimerValue$ = this.warningModalCloseDisabledBS.asObservable().pipe(
    distinctUntilChanged(),
    filter(Boolean),
    switchMap(() => timer(0, ONE_SECOND_MS).pipe(take(APP_WARNING_MODAL_VISIBILITY_SECONDS + 1))),
    map((repeats) => APP_WARNING_MODAL_VISIBILITY_SECONDS - repeats),
    tap((counter) => !counter && this.warningModalCloseDisabledBS.next(false))
  );

  private readonly warningModalCloseDisabledSubscription =
    this.systemMaintenanceFacade.warningModalVisible$
      .pipe(distinctUntilChanged(), filter(Boolean))
      .subscribe(() => this.warningModalCloseDisabledBS.next(true));

  constructor(private readonly systemMaintenanceFacade: SystemMaintenanceFacade) {}

  ngOnDestroy(): void {
    this.warningModalCloseDisabledBS.complete();
    this.warningModalCloseDisabledSubscription.unsubscribe();
  }

  onWarningClose(): void {
    this.systemMaintenanceFacade.warningMessageConfirmed();
  }

  openMobileStore(): void {
    from(Device.getInfo())
      .pipe(first())
      .subscribe((info) => {
        const operatingSystem = info?.operatingSystem;
        const isIOS =
          operatingSystem == OperatingSystem.Ios ||
          MobileDetectUtil.isIOSRequestingDesktopVersion();
        if (operatingSystem == OperatingSystem.Android) {
          window.open(environment.androidStoreAppDetailsUrl, '_blank');
        } else if (isIOS) {
          window.location.href = environment.iosStoreAppUrl;
        }
      });
  }
}
