import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { components } from './components';
import { directives } from '@app/shared/directives';
import { pipes } from './pipes';
import { services } from './services';
import { RouterModule } from '@angular/router';

const modules = [CommonModule, ReactiveFormsModule, FormsModule, RouterModule];

@NgModule({
  declarations: [...components, ...directives, ...pipes],
  imports: [...modules],
  exports: [...modules, ...components, ...directives, ...pipes],
  providers: [...services],
})
export class SharedModule {}
