import { Inject, Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
  BaseManagerApi,
  TEXTBOOK_MANAGER_TOKEN,
} from '@app/core/providers/textbook-manager.provider';
import { Marker } from '@gwo/textbook-api-client/lib/interface/marker.model';
import { MarkersState } from '@app/textbook/store/reducers/viewer.reducer';

@Injectable({
  providedIn: 'root',
})
export class MarkersResolver implements Resolve<MarkersState> {
  constructor(@Inject(TEXTBOOK_MANAGER_TOKEN) private textbookManager: BaseManagerApi) {}

  resolve(route: ActivatedRouteSnapshot): Observable<MarkersState> {
    const {
      url: [, { path: textbookId }],
    } = route;

    return of(textbookId).pipe(
      switchMap((textbookId) => this.textbookManager.getTextbookMarkers$(textbookId).pipe(first())),
      map(MarkersResolver.parseMarkers)
    );
  }

  private static parseMarkers(markers: Marker[]): MarkersState {
    if (!markers) {
      return {};
    }
    return markers.reduce((result: { [key: string]: Marker[] }, marker) => {
      return {
        ...result,
        [marker.pageId]: [...(result[marker.pageId] || []), marker],
      };
    }, {});
  }
}
