export enum AuthDictionary {
  LoginTxt = 'Zaloguj się za pomocą loginu i hasła z portalu GWO.pl.',
  RememberPassTxt = 'Nie pamiętam hasła.',
  DoNotHaveAnAccountTxt = 'Nie mam konta na GWO.pl.',
  DeleteAccountHelpTxt = 'Chcę usunąć konto.',
  PrivacyPolicyHelpTxt = 'Polityka prywatności',
  LoginOrPassDoNotExist = 'Podany login bądź hasło są niepoprawne.',
  NotLogoutTxt = 'Nie wylogowuj mnie po zamknięciu aplikacji.',
  FieldRequiredText = 'To pole jest wymagane.',
}
