import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '@environments/environment';
import { UserFacade } from '@app/core/store/facade/user.facade';
import { Subscription } from 'rxjs';
import { version } from '../../../../../package.json';
import { NetworkService } from '@app/shared/services/network.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellComponent implements OnDestroy {
  readonly platform = environment.appMode ? 'MOBILE' : 'WEB';
  readonly displayToggle = environment.reduxDevtools;
  readonly visibleMode$ = this.networkService.isOnlineMode$;
  readonly user$ = this.userFacade.selectUser$;
  readonly version = version;
  showDevTools = false;
  hideDevPanel = false;
  readonly productionMode = environment.production;
  private subscription = new Subscription();

  constructor(
    private router: Router,
    private userFacade: UserFacade,
    private networkService: NetworkService,
    private cdr: ChangeDetectorRef
  ) {
    localStorage.setItem(
      'AboutApp',
      `platform: ${this.platform}
        version: ${this.version}`
    );
  }

  // @TODO: remove with devtools
  onTogglePlatform(): void {
    localStorage.setItem('isMobile', this.platform === 'WEB' ? '1' : '0');
    this.router.navigate(['/']).then(() => window.location.reload());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleDevTools(event?: Event): void {
    if (event) event.preventDefault();
    this.showDevTools = !this.showDevTools;
  }

  onDevPanelVisibilityToggle(): void {
    this.hideDevPanel = !this.hideDevPanel;
    this.cdr.markForCheck();
  }
}
