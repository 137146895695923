import { MessageMeta } from '@gwo/textbook-api-client';

export function isMessageMeta(meta: any): meta is MessageMeta {
  const showApplicationStoreButton = (meta as MessageMeta)?.showApplicationStoreButton;
  return (
    meta &&
    (meta as MessageMeta).messageHtml?.length &&
    (showApplicationStoreButton === true || showApplicationStoreButton === false)
  );
}
