import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PageNote } from '@gwo/textbook-api-client/lib/interface/page-note.model';
import {
  TEXTBOOK_MANAGER_TOKEN,
  TextbookManagerApi,
} from '@app/core/providers/textbook-manager.provider';

@Injectable()
export class NotesService {
  constructor(@Inject(TEXTBOOK_MANAGER_TOKEN) private textbookManager: TextbookManagerApi) {}

  createNote$(
    accessId: number,
    pageId: string,
    distanceFromTop: number,
    distanceFromLeft: number,
    content: string
  ): Observable<PageNote> {
    return this.textbookManager.createNote$(
      accessId,
      pageId,
      distanceFromTop,
      distanceFromLeft,
      content
    );
  }

  updateNote$(
    noteId: string,
    accessId: number,
    distanceFromTop: number,
    distanceFromLeft: number,
    content?: string
  ): Observable<PageNote> {
    return this.textbookManager.updateNote$(
      noteId,
      accessId,
      distanceFromTop,
      distanceFromLeft,
      content
    );
  }

  // @TODO #GETNOTES zrób tu metode getNotes do pobierania wszystkich notatek w podręczniku (uderz TextbookManager)

  deleteNote$(noteId: string, accessId: number): Observable<unknown> {
    return this.textbookManager.deleteNote$(noteId, accessId);
  }
}
