import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppModeGuard implements CanActivate {
  canActivate(): boolean | UrlTree {
    if (!environment.appMode) {
      location.replace(environment.loginGwoUrl);
      return false;
    }
    return true;
  }
}
