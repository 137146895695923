import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, mapTo, switchMap } from 'rxjs/operators';
import { CoreRoute } from '@app/core/constants';
import { TextbookRouteQueryParam } from '@app/textbook/constants';
import { DownloadRegistryActions, TextbookActions } from '@app/shelf/store/actions';
import { ActivatedRoute, Router } from '@angular/router';
import {
  TEXTBOOK_MANAGER_TOKEN,
  TextbookManagerApi,
} from '@app/core/providers/textbook-manager.provider';
import { AccessStatus } from '@gwo/textbook-api-client/lib/interface/access-status.model';
import { DownloadRegistryFacade, TextbooksFacade } from '@app/shelf/store/facade';
import { SelectedTextbookFacade } from '@app/shelf/store/facade';
import { DownloadUnitStatus } from '../reducers';
import { DownloadAccessService } from '@app/shelf/services';

@Injectable()
export class TextbooksEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
    private textbooksFacade: TextbooksFacade,
    @Inject(TEXTBOOK_MANAGER_TOKEN) private textbookManager: TextbookManagerApi,
    private readonly selectedTextbookFacade: SelectedTextbookFacade,
    private readonly downloadRegistryFacade: DownloadRegistryFacade,
    private readonly downloadAccessService: DownloadAccessService
  ) {}

  enterTextbook$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TextbookActions.enterTextbook),
        switchMap((data) =>
          this.downloadRegistryFacade.getDownloadRegistryUnit(data.textbook.index).pipe(
            map((unit) => {
              const downloaded = unit?.status === DownloadUnitStatus.downloaded;
              const downloadedVersionUpToDate = unit?.version === data.textbook.version;
              if (downloaded && (downloadedVersionUpToDate || data.useDownloadedVersionOffline)) {
                this.selectedTextbookFacade.setSelectedTextbook(
                  unit,
                  data.useDownloadedVersionOffline
                );
              } else {
                this.selectedTextbookFacade.cleanSelectedTextbook();
              }
            }),
            mapTo(data)
          )
        ),
        switchMap(({ textbook: { id, firstPageNumber, access } }) => {
          return this.router.navigate([CoreRoute.Textbooks, id], {
            relativeTo: this.route,
            queryParams: {
              [TextbookRouteQueryParam.Page]: firstPageNumber,
              accessId: access.accessId,
            },
          });
        })
      ),
    { dispatch: false }
  );

  runTextbookStatus$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DownloadRegistryActions.startDownload, TextbookActions.enterTextbook),
        filter(
          ({
            textbook: {
              access: { accessStatus },
            },
          }) => accessStatus === AccessStatus.New
        ),
        switchMap(({ textbook }) => {
          const {
            access: { accessId },
          } = textbook;
          return this.textbookManager.runTextbookAccess$(accessId).pipe(
            map(() => {
              const textbookWithOpenedStatus = {
                ...textbook,
                access: { ...textbook.access, accessStatus: AccessStatus.Opened },
              };
              return this.textbooksFacade.setTextbook(textbook.index, textbookWithOpenedStatus);
            })
          );
        })
      ),
    { dispatch: false }
  );

  downloadTextbookAccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DownloadRegistryActions.startDownload),
        switchMap(({ textbook }) => this.downloadAccessService.downloadTextbookAccess(textbook))
      ),
    { dispatch: false }
  );

  uninstallManyTextbookAccesses$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DownloadRegistryActions.deleteTextbook),
        switchMap(({ textbook }) =>
          this.downloadAccessService.uninstallManyTextbookAccesses(textbook)
        )
      ),
    { dispatch: false }
  );
}
