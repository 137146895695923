import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { CoreRoute } from '@app/core/constants';
import { AuthService } from '@app/core/services';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(): boolean | UrlTree {
    if (this.authService.getToken()) {
      return this.router.createUrlTree([CoreRoute.Shelf]);
    }
    return true;
  }
}
