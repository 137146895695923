<app-modal-layout maxWidth="28rem" marginTop="2.5rem" (backdropClick)="close()">
  <div class="content">
    <button class="close-btn" (click)="close()"><app-icon name="close"></app-icon></button>

    <p class="header">Uwaga!</p>

    <p class="message-header">
      <ng-content select="[header]"></ng-content>
    </p>
    <p class="message-content">
      <ng-content select="[content]"></ng-content>
    </p>

    <div>
      <button (click)="close()">Ok</button>
    </div>
  </div>
</app-modal-layout>
