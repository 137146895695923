import { Injectable } from '@angular/core';
import { TextbookMorph, TextbookRecord } from '@app/shelf/models/textbook.model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TextbookActions } from '../actions';
import { ShelfState } from '../reducers';
import { TextbooksSelectors } from '../selectors';

@Injectable()
export class TextbooksFacade {
  constructor(private readonly store: Store<ShelfState>) {}

  setTextbooks(textbooks: TextbookRecord): void {
    this.store.dispatch(TextbookActions.setTextbooks({ textbooks }));
  }

  getTextbooks(): Observable<TextbookRecord> {
    return this.store.select(TextbooksSelectors.selectTextbooks);
  }

  setTextbook(index: string, textbook: TextbookMorph): void {
    this.store.dispatch(TextbookActions.setTextbook({ index, textbook }));
  }

  onEnterTextbook(textbook: TextbookMorph, useDownloadedVersionOffline: boolean): void {
    this.store.dispatch(
      TextbookActions.enterTextbook({
        textbook,
        useDownloadedVersionOffline: useDownloadedVersionOffline,
      })
    );
  }
}
