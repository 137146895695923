import { Pipe, PipeTransform } from '@angular/core';

export enum TextbookStatus {
  'new' = 'nierozpoczęty',
  'opened' = 'rozpoczęty',
  'finished' = 'zakończony',
  'canceled' = 'anulowany',
  'blocked' = 'zablokowany',
  'hidden' = 'ukryty w moim GWO',
}

@Pipe({
  name: 'translateTextbookStatus',
})
export class TranslateTextbookStatusPipe implements PipeTransform {
  transform(status: string | undefined): TextbookStatus {
    return TextbookStatus[status as keyof typeof TextbookStatus];
  }
}
