import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorHandlerModule } from '@app/core/modules/error-handler/error-handler.module';

import { UserFacade } from '@app/core/store/facade/user.facade';
import { MergedRouterStateSerializer } from '@app/router/merged-route-serialzer';
import { NgrxRouterStoreModule } from '@app/router/ngrx-router.module';
import { SharedModule } from '@app/shared/shared.module';
import { RouterStateSerializer } from '@ngrx/router-store';
import { containers, ShellComponent } from './containers';
import {
  AuthService,
  MarkerService,
  NavigationService,
  NotesService,
  UserContentService,
  UtilitiesService,
} from './services';
import { RootStoreModule } from './store/root-store.module';
import { AuthFacade } from '@app/core/store/facade/auth.facade';
import { components } from '@app/core/components';
import { MobileDetectProvider } from './providers/mobile-detect.provider';
import { resolveService } from '@app/core/providers/textbook-manager.provider';
import { ShelfModule } from '@app/shelf/shelf.module';
import { NetworkService } from '@app/shared/services/network.service';
import { SessionFacade } from './store/facade/session.facade';
import { SystemMaintenanceFacade } from '@app/core/store/facade/system-maintenance.facade';

@NgModule({
  providers: [
    AuthService,
    NotesService,
    MarkerService,
    UtilitiesService,
    NavigationService,
    UserContentService,
    MobileDetectProvider,
    UserFacade,
    NetworkService,
    AuthFacade,
    SessionFacade,
    SystemMaintenanceFacade,
    {
      provide: RouterStateSerializer,
      useClass: MergedRouterStateSerializer,
    },
    resolveService(),
  ],
  declarations: [...containers, ...components],
  imports: [
    SharedModule,
    NgrxRouterStoreModule,
    ErrorHandlerModule,
    RootStoreModule,
    ShelfModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [ShellComponent],
})
export class CoreModule {}
