<app-header [user]="user$ | async" (logoutClick)="logout()"></app-header>
<ng-container *ngIf="initCoverUrls$ | async"></ng-container>
<ul class="main">
  <li class="empty-shelf-info" *ngIf="emptyShelf$ | async">
    <span>
      Nie&nbsp;masz&nbsp;jeszcze&nbsp;dostępu&nbsp;do&nbsp;żadnego
      multipodręcznika&nbsp;na&nbsp;swoim&nbsp;koncie&nbsp;GWO.
    </span>
  </li>
  <ng-container *ngIf="(emptyShelf$ | async) === false">
    <li class="card-container" *ngFor="let textbook of sortedTextbooks$ | async">
      <app-cover
        [src]="coverResourcesUrls[textbook.coverResourceId]"
        [textbook]="textbook"
        [isConnected]="(isConnected | async)!"
        (openTextbook)="onOpenTextbook(textbook)"
        (stopDownload)="onStopDownload(textbook)"
        (downloadModalVisible)="onStartDownloadClick(textbook)"
        (download)="download(textbook)"
        (showDetails)="showDetailsModal(textbook)"
        (deleteDownloaded)="onDeleteDownloadClick(textbook)"
        (showExhaustedDownloadLimitModal)="toggleExhaustedDownloadLimitModal(true)"
        (updateUserData)="onUpdateUserData(textbook)"
      ></app-cover>
    </li>
  </ng-container>
</ul>

<app-information-modal
  *ngIf="isActiveFinishedOrExpiredModalModal$ | async"
  (exit)="toggleExpiredOrFinishedModal(false)"
>
  <p header>Wygasł termin ważności tego multipodręcznika.</p>
  <p content>
    Odnów swój dostęp do tej publikacji lub usuń ją <br />
    ze swojego urządzenia.
  </p>
</app-information-modal>

<app-information-modal
  *ngIf="isActiveExhaustedDownloadLimitModal$ | async"
  (exit)="toggleExhaustedDownloadLimitModal(false)"
>
  <p content>
    Wyczerpałeś swój limit pobrań tego multipodręcznika. <br />
    Korzystaj z publikacji w trybie online lub usuń ją z innego urządzenia mobilnego.
  </p>
</app-information-modal>

<app-textbook-details-modal
  *ngIf="isActiveTextbookDetailsModal$ | async"
  [textbook]="selectedTextbook!"
  (closeClick)="hideDetailsModal()"
></app-textbook-details-modal>

<app-confirm-download-modal
  *ngIf="isActiveConfirmDownloadModal$ | async"
  [textbook]="selectedTextbook!"
  (confirm)="onDownloadConfirm($event)"
  (reject)="onDownloadReject()"
></app-confirm-download-modal>

<app-confirm-delete-modal
  *ngIf="isActiveConfirmDeleteModal$ | async"
  [textbook]="selectedTextbook!"
  (confirm)="deleteConfirm($event)"
  (reject)="deleteReject()"
></app-confirm-delete-modal>

<app-offline-warning-modal
  *ngIf="isActiveOfflineWarningModal$ | async"
  class="offline-warning"
  (closeClick)="hideOfflineWarningModal()"
>
</app-offline-warning-modal>

<app-no-free-memory-modal
  *ngIf="isActiveNoFreeMemoryModal$ | async"
  (exit)="onNoFreeMemoryModalExit()"
></app-no-free-memory-modal>

<app-update-user-data-modal
  *ngIf="isActiveUpdateTextbookModal$ | async"
  [declineLabel]="'Nie – otwórz nieaktualną wersję offline'"
  [optionalOtion]="'Nie – otwórz aktualną wersję online'"
  (exit)="updateTextbookConfirm($event)"
>
  <span id="header">Uwaga!</span>
  <span id="question">
    Wersja offline Twojego multipodręcznika jest nieaktualna - niektóre funkcje są w niej
    niedostępne. <br />
    Aktualizacja może zająć trochę czasu. Czy chcesz ją teraz pobrać?
  </span>
</app-update-user-data-modal>
<app-update-user-data-modal
  *ngIf="isActiveUpdateTextbookOnDemandModal$ | async"
  (exit)="updateTextbookOnDemandConfirm($event)"
>
  <span id="header">Uwaga! Dostępna aktualizacja!</span>
  <span id="question">
    Może ona zająć trochę czasu. <br />
    Czy chcesz teraz zaktualizować swój multipodręcznik?
  </span>
</app-update-user-data-modal>

<app-preloader
  class="preloader"
  [style.--display]="(showLoader$ | async) ? 'flex' : 'none'"
></app-preloader>
