import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TextbookClient } from '@gwo/textbook-api-client';
import { User } from '@gwo/textbook-api-client/lib/interface/user.model';

export const TOKEN_KEY = 'token';

@Injectable()
export class AuthService implements Partial<TextbookClient> {
  constructor(private textbookClient: TextbookClient) {}
  private readonly isUserLoggingIn = new BehaviorSubject<boolean>(false);
  readonly isUserLoggingIn$ = this.isUserLoggingIn.asObservable();

  changeUserLoginStatus(status: boolean): void {
    this.isUserLoggingIn.next(status);
  }

  loginWithCredentials$(login: string, password: string): Observable<{ token: string }> {
    return this.textbookClient.loginWithCredentials$(login, password);
  }

  loginWithToken$(token: string, accessId?: number): Observable<{ token: string }> {
    return this.textbookClient.loginWithToken$(token, accessId);
  }

  logout$(): Observable<void> {
    return this.textbookClient.logout$();
  }

  setToken(token: string): void {
    localStorage.setItem(TOKEN_KEY, token);
  }

  clearToken(): void {
    localStorage.removeItem(TOKEN_KEY);
  }

  getToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  }

  getAuthenticatedUser$(): Observable<User> {
    return this.textbookClient.getAuthenticatedUser$();
  }

  recreateAuthenticatedUser(accessId?: number): Observable<User> {
    return this.textbookClient.recreateAuthenticatedUser$(accessId);
  }

  recreateUserToken$(): Observable<{
    token: string;
  }> {
    return this.textbookClient.recreateUserToken$();
  }
}
