import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthActions, ErrorActions, SessionActions, UserActions } from '../actions';
import { DownloadRegistryFacade, ShelfFacade } from '@app/shelf/store/facade';
import { filter, interval, map, switchMapTo, first, merge, Observable } from 'rxjs';
import { ShelfActions } from '@app/shelf/store/actions';
import { AuthService, UserContentService } from '@app/core/services';
import { ShelfService, TaskRunnerService } from '@app/shelf/services';
import { combineLatestWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { environment } from '@environments/environment.dev';
import { NetworkService } from '@app/shared/services/network.service';
import { isEmpty } from '@app/shared/utils/is-empty';
import { ShelfStatus } from '@app/core/models';
import { UserFacade } from '../facade/user.facade';
import { Router } from '@angular/router';
import { Action } from '@ngrx/store/src/models';

const FETCH_PROJECTION_THROTTLE_TIME = 1000 * 60 * 10;
const FETCH_PROJECTION_INTERVAL = 1000 * 10 + 1;

const FETCH_CHECK_SESSION_INTERVAL = 1000 * 60;
const TASK_RUNNER_INTERVAL = environment.taskRunnerInterval;

const PROJECTION_FETCHED_TIME_STORAGE_KEY = 'PROJECTION_FETCHED_TIME';
const SINGLE_PROJECTION_FETCHED_TIME_STORAGE_KEY = 'SINGLE_PROJECTION_FETCHED_TIME';
type SingleProjectionFetchedItem = { accessId: number; time: number };

const UNAUTHORIZED_HTTP_ERROR_STATUS = 401;

@Injectable()
export class SessionEffects {
  private getAccessThrottleInterval(accessId: number, force: boolean): boolean {
    const accessProjectionItem = localStorage.getItem(SINGLE_PROJECTION_FETCHED_TIME_STORAGE_KEY);
    let singleProjectionFetchItem: SingleProjectionFetchedItem = accessProjectionItem
      ? JSON.parse(accessProjectionItem)
      : null;
    if (force || this.isProjectionRefreshRequired(accessId, singleProjectionFetchItem)) {
      singleProjectionFetchItem = { accessId, time: Date.now() };
      localStorage.setItem(
        SINGLE_PROJECTION_FETCHED_TIME_STORAGE_KEY,
        JSON.stringify(singleProjectionFetchItem)
      );
      return true;
    }
    return false;
  }

  private isProjectionRefreshRequired(
    accessId: number,
    singleProjectionFetchItem: SingleProjectionFetchedItem | null
  ): boolean {
    if (!singleProjectionFetchItem || singleProjectionFetchItem.accessId !== accessId) {
      return this.isShelfProjectionTimeout();
    }
    return this.isProjectionTimeout(singleProjectionFetchItem.time);
  }

  private getShelfThrottleInterval(force: boolean): boolean {
    if (force || this.isShelfProjectionTimeout()) {
      localStorage.setItem(PROJECTION_FETCHED_TIME_STORAGE_KEY, String(Date.now()));
      localStorage.removeItem(SINGLE_PROJECTION_FETCHED_TIME_STORAGE_KEY);
      return true;
    }
    return false;
  }

  private isShelfProjectionTimeout(): boolean {
    const time = parseInt(localStorage.getItem(PROJECTION_FETCHED_TIME_STORAGE_KEY) || '0');
    return this.isProjectionTimeout(time);
  }

  private isProjectionTimeout(projectionFetchTime: number): boolean {
    const diff = Date.now() - projectionFetchTime;
    return diff >= FETCH_PROJECTION_THROTTLE_TIME;
  }

  private userAuthenticatedFilter = <T>(source$: Observable<T>): Observable<T> => {
    return source$.pipe(filter(() => !!this.authService.getToken()));
  };

  private userReloggedAfterAuthErrorFilter = <T>(source$: Observable<T>): Observable<T> => {
    return source$.pipe(
      withLatestFrom(
        merge(
          this.actions$.pipe(ofType(UserActions.updateUser)),
          this.actions$.pipe(
            ofType(ErrorActions.errorOccurred),
            filter(({ httpErrorStatus }) => UNAUTHORIZED_HTTP_ERROR_STATUS === httpErrorStatus)
          )
        )
      ),
      filter(([_, action]: [T, Action]) => action.type === UserActions.updateUser.type),
      map(([source, _]: [T, Action]) => source)
    );
  };

  fetchProjectionInterval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUser),
      switchMapTo(interval(FETCH_PROJECTION_INTERVAL)),
      map(() => SessionActions.fetchProjection({ force: false }))
    )
  );

  fetchProjection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.fetchProjection),
      this.userAuthenticatedFilter,
      this.userReloggedAfterAuthErrorFilter,
      map(({ force }) => ({ force, accessId: this.getAccessIdFromRoute() })),
      filter(({ force, accessId }) =>
        accessId
          ? this.getAccessThrottleInterval(accessId, force)
          : this.getShelfThrottleInterval(force)
      ),
      map(() => AuthActions.recreateUser({ accessId: this.getAccessIdFromRoute() }))
    )
  );

  fetchProjectionOnShelfInit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShelfActions.init),
      first(),
      filter(() => this.getShelfThrottleInterval(true)),
      map(() => AuthActions.recreateUser({}))
    )
  );

  fetchSessionInterval$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUser),
      switchMapTo(interval(FETCH_CHECK_SESSION_INTERVAL)),
      this.userAuthenticatedFilter,
      this.userReloggedAfterAuthErrorFilter,
      map(() => SessionActions.fetchSession())
    )
  );

  recreateUserToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.fetchSession),
      map(() => AuthActions.recreateUserToken())
    )
  );

  isConnectionAndRegistryUnit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.fetchSession),
      switchMap(() => this.networkService.isOnlineMode$.pipe(filter(Boolean))),
      switchMap(() => this.shelfService.isShelfActive$.pipe(filter(Boolean))),
      switchMap(() =>
        this.downloadRegistryFacade
          .getDownloadRegistry()
          .pipe(filter((registry) => !isEmpty(registry)))
      ),
      map(() => SessionActions.isConnectedAndAnyRegistryUnit())
    )
  );

  getProjection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SessionActions.isConnectedAndAnyRegistryUnit),
      switchMap(() => this.authService.getAuthenticatedUser$()),
      map((user) => SessionActions.getProjection({ user }))
    )
  );

  markRegistryUnitsReadyToUpdate = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SessionActions.getProjection),
        switchMap(({ user: { textbookAccesses } }) =>
          this.userContentService.markRegistryUnitsReadyToUpdate$(textbookAccesses)
        )
      ),
    { dispatch: false }
  );

  runTaskRunner$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.updateUser),
        switchMap(() => interval(TASK_RUNNER_INTERVAL)),
        combineLatestWith(this.userFacade.selectUser$),
        filter(([, user]) => !!user.externalId),
        switchMap(() => this.taskRunnerService.initQueue())
      ),
    { dispatch: false }
  );

  shelfIsVisible$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShelfActions.init),
        map(() => this.shelfService.setShelfStatus(ShelfStatus.Show))
      ),
    { dispatch: false }
  );

  shelfIsNotVisible$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShelfActions.exit),
        map(() => this.shelfService.setShelfStatus(ShelfStatus.Hide))
      ),
    { dispatch: false }
  );

  private getAccessIdFromRoute(): number | undefined {
    //FIXME Do uporządkowania - wyciągnięcia ze store - select
    const accessId =
      !!this.router['rawUrlTree'] && !!this.router['rawUrlTree']?.queryParams?.accessId
        ? +this.router['rawUrlTree'].queryParams.accessId
        : undefined;
    return accessId;
  }

  constructor(
    private actions$: Actions,
    private readonly authService: AuthService,
    private readonly networkService: NetworkService,
    private readonly taskRunnerService: TaskRunnerService,
    private readonly downloadRegistryFacade: DownloadRegistryFacade,
    private readonly userContentService: UserContentService,
    private readonly shelfFacade: ShelfFacade,
    private readonly userFacade: UserFacade,
    private readonly shelfService: ShelfService,
    private router: Router
  ) {}
}
