import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { SystemMaintenanceActions } from '../actions';
import { filter, interval, map, switchMap } from 'rxjs';
import { tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { NetworkService } from '@app/shared/services/network.service';
import { Action, Store } from '@ngrx/store';
import { AppState } from '@app/core/store/reducers';
import { AppMessage, TextbookClient } from '@gwo/textbook-api-client';
import { version } from '../../../../../package.json';
import { isAppLockMessage } from '@app/core/models/type-guards/is-app-lock-message';
import { AppMessagesUtil } from '@app/core/utils/app-messages.util';
import { SystemMaintenanceFacade } from '@app/core/store/facade/system-maintenance.facade';
import { isAppWarningMessage } from '@app/core/models/type-guards/is-app-warning-message';

const ONE_MINUTE_IN_MS = 1000 * 60;

const WARNING_VALIDATION_INTERVAL = ONE_MINUTE_IN_MS * 0.2;
const APP_MESSAGES_FETCH_VALIDATION_IDLE_TIME = ONE_MINUTE_IN_MS * 5;

const FETCH_APP_MESSAGES_THROTTLE_TIME = ONE_MINUTE_IN_MS * 60 * 6;
const APP_MESSAGES_FETCHED_TIME_STORAGE_KEY = 'APP_MESSAGES_FETCHED_TIME';

const APP_MESSAGE_STORAGE_KEY = 'APP_MESSAGE';
const WARNING_MESSAGE_DISPLAY_TIME_STORAGE_KEY = 'WARNING_MESSAGE_DISPLAY_TIME';
type WarningMessageDisplayTime = { id: AppMessage['id']; timestamp: number };

@Injectable()
export class SystemMaintenanceEffects implements OnInitEffects {
  readonly loadStoredMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemMaintenanceActions.initMobileAppBackgroundEffects),
      map(() => this.getStoredAppMessage()),
      filter(Boolean),
      map((message) => SystemMaintenanceActions.setAppMessage({ message }))
    )
  );

  readonly validateWarning$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemMaintenanceActions.setAppMessage),
      switchMap(({ message }) => interval(WARNING_VALIDATION_INTERVAL).pipe(map(() => message))),
      withLatestFrom(
        this.systemMaintenanceFacade.appLocked$,
        this.systemMaintenanceFacade.warningModalVisible$
      ),
      map(([message, locked, warningVisible]) => {
        const calculatedLockedStatus = AppMessagesUtil.isAppLocked(message);
        if (calculatedLockedStatus !== locked) {
          return SystemMaintenanceActions.updateLockStatus({ locked: calculatedLockedStatus });
        }
        if (
          !warningVisible &&
          !locked &&
          isAppWarningMessage(message) &&
          AppMessagesUtil.isWarningPeriodMatching(
            message.meta.periodicity,
            this.getStoredWarningTimestamp(message.id)
          ) &&
          AppMessagesUtil.isWarningTimeMatching(message)
        ) {
          return SystemMaintenanceActions.setWarningModalVisibility({ visible: true });
        }
        return null;
      }),
      filter(Boolean)
    )
  );

  readonly fetchAppMessagesCron$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SystemMaintenanceActions.initMobileAppBackgroundEffects),
        switchMap(() => interval(APP_MESSAGES_FETCH_VALIDATION_IDLE_TIME)),
        filter(() => this.isAppMessagesFetchRequired()),
        tap(() =>
          this.networkService.isOnlineMode
            .pipe(filter(Boolean))
            .subscribe(() => this.store.dispatch(SystemMaintenanceActions.fetchAppMessages()))
        )
      ),
    { dispatch: false }
  );

  readonly fetchAppMessagesOnReconnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemMaintenanceActions.initMobileAppBackgroundEffects),
      switchMap(() => this.networkService.isOnlineMode$),
      filter((isOnline) => isOnline && this.isAppMessagesFetchRequired()),
      map(() => SystemMaintenanceActions.fetchAppMessages())
    )
  );

  readonly fetchAppMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemMaintenanceActions.fetchAppMessages),
      switchMap(() => this.textbookClient.getAppMessage(version)),
      tap((message) => this.storeAppMessage(message)),
      withLatestFrom(this.systemMaintenanceFacade.messageId$),
      filter(([message, messageId]) => message?.id != messageId),
      map(([message]) => SystemMaintenanceActions.setAppMessage({ message }))
    )
  );

  readonly warningMessageConfirmed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SystemMaintenanceActions.warningMessageConfirmed),
      withLatestFrom(this.systemMaintenanceFacade.messageId$),
      tap(([_, messageId]) => messageId && this.storeWarningTimestamp(messageId, Date.now())),
      map(() => SystemMaintenanceActions.setWarningModalVisibility({ visible: false }))
    )
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly actions$: Actions,
    private readonly networkService: NetworkService,
    private readonly textbookClient: TextbookClient,
    private readonly systemMaintenanceFacade: SystemMaintenanceFacade
  ) {}

  ngrxOnInitEffects(): Action {
    return environment.appMode
      ? SystemMaintenanceActions.initMobileAppBackgroundEffects()
      : SystemMaintenanceActions.mobileAppBackgroundEffectsInitializationSkipped();
  }

  private isAppMessagesFetchRequired(): boolean {
    const lastFetchTimestamp = this.getAppMessagesLastFetchTimestamp();
    return this.isAppMessagesIdleTimeout(lastFetchTimestamp);
  }

  private getAppMessagesLastFetchTimestamp(): number {
    const timestamp = parseInt(localStorage.getItem(APP_MESSAGES_FETCHED_TIME_STORAGE_KEY) || '0');
    return timestamp;
  }

  private isAppMessagesIdleTimeout(appMessagesLastFetchTime: number): boolean {
    const diff = Date.now() - appMessagesLastFetchTime;
    return diff >= FETCH_APP_MESSAGES_THROTTLE_TIME;
  }

  private getStoredAppMessage(): AppMessage | null {
    const item = localStorage.getItem(APP_MESSAGE_STORAGE_KEY);
    const storedAppMessage = item ? JSON.parse(item) : null;
    if (isAppWarningMessage(storedAppMessage) || isAppLockMessage(storedAppMessage)) {
      return storedAppMessage;
    }
    return null;
  }

  private getStoredWarningTimestamp(messageId: AppMessage['id']): number {
    const item = localStorage.getItem(WARNING_MESSAGE_DISPLAY_TIME_STORAGE_KEY);
    const storedData = item ? JSON.parse(item) : null;
    const warningDisplayInfo = storedData as WarningMessageDisplayTime | null;
    const timestamp = +(warningDisplayInfo?.timestamp?.toString() || 0);
    if (messageId === warningDisplayInfo?.id && timestamp && !isNaN(timestamp)) {
      return timestamp;
    }
    return 0;
  }

  private storeAppMessage(message: AppMessage | null): void {
    if (!message) {
      localStorage.removeItem(APP_MESSAGE_STORAGE_KEY);
      return;
    }
    const stringifiedMessage = JSON.stringify(message);
    localStorage.setItem(APP_MESSAGE_STORAGE_KEY, stringifiedMessage);
  }

  private storeWarningTimestamp(messageId: AppMessage['id'], timestamp: number): void {
    const toStore: WarningMessageDisplayTime = { id: messageId, timestamp };
    localStorage.setItem(WARNING_MESSAGE_DISPLAY_TIME_STORAGE_KEY, JSON.stringify(toStore));
  }
}
