import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../reducers';
import {
  systemMaintenanceFeatureKey,
  SystemMaintenanceState,
} from '@app/core/store/reducers/system-maintenance.reducer';
import { AppMessagesUtil } from '@app/core/utils/app-messages.util';
import { isAppWarningMessage } from '@app/core/models/type-guards/is-app-warning-message';

export const selectSystemMaintenanceState = createFeatureSelector<SystemMaintenanceState>(
  systemMaintenanceFeatureKey
);

export const selectWarningModalVisibility = createSelector<
  AppState,
  SystemMaintenanceState,
  SystemMaintenanceState['warningModalVisible']
>(selectSystemMaintenanceState, ({ warningModalVisible }) => warningModalVisible);

export const selectMessageId = createSelector<AppState, SystemMaintenanceState, string | null>(
  selectSystemMaintenanceState,
  ({ appMessage }) => appMessage?.id || null
);

export const selectAppLocked = createSelector<AppState, SystemMaintenanceState, boolean>(
  selectSystemMaintenanceState,
  ({ locked }) => locked
);

export const selectHtmlMessage = createSelector<AppState, SystemMaintenanceState, string>(
  selectSystemMaintenanceState,
  ({ appMessage }) => AppMessagesUtil.getHtmlMessage(appMessage)
);

export const selectApplicationStoreButtonVisibility = createSelector<
  AppState,
  SystemMaintenanceState,
  boolean
>(selectSystemMaintenanceState, ({ appMessage }) => {
  if (isAppWarningMessage(appMessage)) {
    return AppMessagesUtil.isAppLocked(appMessage)
      ? !!appMessage.meta.lockAppInfo?.message.showApplicationStoreButton
      : appMessage.meta.showApplicationStoreButton;
  }
  if (AppMessagesUtil.isAppLocked(appMessage)) {
    return !!appMessage?.meta.showApplicationStoreButton;
  }
  return false;
});
