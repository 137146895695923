<div
  *ngIf="!productionMode"
  class="dev-panel"
  [ngClass]="hideDevPanel ? 'hidden' : ''"
  (contextmenu)="toggleDevTools($event)"
>
  <button type="button" class="dev-panel-hide" (click)="onDevPanelVisibilityToggle()">x</button>
  PLATFORM: <strong>{{ platform }} {{ productionMode }}</strong>
  <button *ngIf="displayToggle" (click)="onTogglePlatform()">Toggle</button>
  NETWORK_STATUS: <strong>{{ visibleMode$ | async }}</strong> AUTHENTICATED:
  <strong>{{ (user$ | async)?.firstName }} {{ (user$ | async)?.lastName }}</strong> APP VERSION:
  <strong>{{ version }}</strong>
  <button>UPDATE</button>
</div>
<main class="txtb-main">
  <ng-content select="[main]"></ng-content>
  <div class="download-wrapper">
    <ng-content select="[download]"></ng-content>
  </div>
</main>

<app-error-modal-wrapper></app-error-modal-wrapper>

<app-mobile-portrait-blocker></app-mobile-portrait-blocker>
