import { Component, EventEmitter, Input, Output } from '@angular/core';

const textAlign = ['left', 'center', 'right'] as const;
const theme = ['minimal', 'basic'] as const;

type TextAlign = typeof textAlign[number];
type Theme = typeof theme[number];

@Component({
  selector: 'app-info-modal-layout',
  templateUrl: './info-modal-layout.component.html',
  styleUrls: ['./info-modal-layout.component.scss'],
})
export class InfoModalLayoutComponent {
  @Input() title = '';
  @Input() titleAlign: TextAlign = 'left';
  @Input() theme: Theme = 'basic';
  @Input() closeDisabled = false;
  @Output() closeClick = new EventEmitter<void>();

  onCloseModal(): void {
    if (this.closeDisabled) {
      return;
    }
    this.closeClick.emit();
  }
}
