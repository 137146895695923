export function stringToBlob(data: string, type?: string): Blob {
  const meta = data.split(',');
  /* eslint-disable */
  if (!type) type = (/:([a-zA-Z0-9\/]+);/.exec(meta[0]) as string[])[1];
  const byteCharacters = atob(meta[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type });
}
