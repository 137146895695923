import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressComponent {
  @Input() color = '#fff';
  @Input() set value(value: number) {
    this.value$ubject.next(value);
  }

  radius = 50;
  thickness = 5;
  circumference = this.radius * 2 * Math.PI;
  strokeDasharray = `${this.circumference} ${this.circumference}`;
  viewBoxSide = 2 * this.radius + this.thickness;
  viewBox = `0 0 ${this.viewBoxSide} ${this.viewBoxSide}`;
  private value$ubject = new BehaviorSubject(0);
  percent$ = this.value$ubject.pipe(
    map((value) => {
      const fractionDigits = value === 100 ? 0 : 1;
      return value.toFixed(fractionDigits) + '%';
    })
  );
  strokeDashoffset$ = this.value$ubject.pipe(
    map((value) => this.circumference * (1 - value / 100))
  );
}
