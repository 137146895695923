import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreRoute, CoreRouteParam } from './core/constants';
import { AppModeGuard, AuthGuard, LoginGuard, TextbooksGuard } from './core/guards';
import { LegacyInfographicRedirectComponent } from '@app/core/components/legacy-infographic-redirect/legacy-infographic-redirect.component';
import {
  NotesResolver,
  PlayerTypeResolver,
  TextbookDataResolver,
  resolvers,
} from '@app/textbook/resolvers';
import { MarkersResolver } from '@app/textbook/resolvers/markers.resolver';
import { ErrorsModule } from './errors/errors.module';
import { AppLockGuard } from '@app/core/guards/app-lock.guard';

const sharedResolvers = {
  playerType: PlayerTypeResolver,
  textbookData: TextbookDataResolver,
};

const routes: Routes = [
  {
    path: CoreRoute.LegacyInfographicRedirect,
    component: LegacyInfographicRedirectComponent,
  },
  {
    path: CoreRoute.Auth,
    loadChildren: async () => (await import('./auth/auth.module')).AuthModule,
    canActivate: [AppLockGuard, LoginGuard, AppModeGuard],
  },
  {
    path: CoreRoute.Error,
    loadChildren: () => ErrorsModule,
    canActivate: [AppLockGuard],
  },

  {
    path: CoreRoute.Shelf,
    loadChildren: async () => (await import('./shelf/shelf.module')).ShelfModule,
    canActivate: [AppLockGuard, AuthGuard, AppModeGuard],
  },
  {
    path: `${CoreRoute.Textbooks}/:${CoreRouteParam.TextbookId}`,
    canActivate: [AppLockGuard, AuthGuard, TextbooksGuard],
    loadChildren: async () =>
      (await import('./textbook-full-version/textbook-full-version.module'))
        .TextbookFullVersionModule,
    resolve: {
      ...sharedResolvers,
      notes: NotesResolver,
      markers: MarkersResolver,
    },
  },
  {
    path: `${CoreRoute.Infographics}/:${CoreRouteParam.TextbookId}`,
    canActivate: [AppLockGuard],
    loadChildren: async () =>
      (await import('./textbook-infographics-version/textbook-infographics-version.module'))
        .TextbookInfographicsVersionModule,
    resolve: {
      ...sharedResolvers,
    },
  },
  {
    path: `${CoreRoute.Demos}/:${CoreRouteParam.TextbookId}`,
    canActivate: [AppLockGuard],
    loadChildren: async () =>
      (await import('./textbook-demo-version/textbook-demo-version.module'))
        .TextbookDemoVersionModule,
    resolve: {
      ...sharedResolvers,
    },
  },
  {
    path: '**',
    redirectTo: CoreRoute.Shelf,
  },
];

@NgModule({
  providers: [...resolvers, AppLockGuard],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
