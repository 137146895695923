<div class="background" (click)="onCloseModal()"></div>
<section class="modal" [class]="theme" [appDraggable]="true">
  <header>
    <h5 [style.text-align]="titleAlign">{{ title }}</h5>
    <button class="button-close" [class.is-disabled]="closeDisabled" [disabled]="closeDisabled" (click)="onCloseModal()">
      <app-icon name="close"></app-icon>
    </button>
  </header>
  <main>
    <ng-content select="[main]"></ng-content>
  </main>
  <footer>
    <ng-content select="[footer]"></ng-content>
  </footer>
</section>
