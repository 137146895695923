import { CoreRoute } from '@app/core/constants';
import { AuthEffects } from '@app/core/store/effects/auth.effects';
import { SessionEffects } from '@app/core/store/effects/session.effects';
import { UserEffects } from '@app/core/store/effects/user.effects';
import { ErrorEffects } from './error.effects';
import { SystemMaintenanceEffects } from './system-maintenance.effects';

const allPlayersEffects = [
  SessionEffects,
  AuthEffects,
  UserEffects,
  ErrorEffects,
  SystemMaintenanceEffects,
];
const demoInfographicsPlayerEffects = [SessionEffects, SystemMaintenanceEffects];

export const effects = [CoreRoute.Demos, CoreRoute.Infographics].some((coreRoute) =>
  location.href.includes(coreRoute)
)
  ? demoInfographicsPlayerEffects
  : allPlayersEffects;
