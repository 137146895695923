import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';

import { UserFacade } from '@app/core/store/facade/user.facade';
import { AuthService } from '@app/core/services';

const AUTH_HEADER = 'X-Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private userFacade: UserFacade, private authService: AuthService) {}

  private interceptAuthRequest(request: HttpRequest<unknown>): void {
    if (request.url.includes('user-tokens') && request.method === 'POST') {
      this.authService.changeUserLoginStatus(true);
    }
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.interceptAuthRequest(request);
    return this.userFacade.selectUser$.pipe(
      first(),
      switchMap(() => {
        const token = this.authService.getToken();
        return token
          ? next.handle(request.clone({ setHeaders: { [AUTH_HEADER]: token } }))
          : next.handle(request);
      }),
      catchError((error) => this.handleError(error))
    );
  }

  private handleError(error: HttpErrorResponse): never {
    if (error.status >= 500) {
      this.authService.changeUserLoginStatus(false);
    }
    throw error;
  }
}
