<div>
  <app-icon name="gwo logo" class="logo"></app-icon>
</div>
<h6><strong>Multi</strong>teka GWO</h6>
<div
  appMulticlick
  [clicks]="5"
  (multiclickDone)="onErrorsShowClick()"
  class="error-log-trigger"
></div>

<div class="user-name" *ngIf="user">
  <p>{{ user.firstName }}<br />{{ user.lastName }}</p>
</div>

<button *ngIf="isLogOutVisible" (click)="onLogoutClick()">
  <app-icon name="logout"></app-icon>
</button>

<button data-cy="header-help" (click)="onHelpOpen()">
  <app-icon name="help"></app-icon>
</button>

<app-help-modal *ngIf="isHelpOpened" (closeClick)="onHelpClose()" @fadeInOut></app-help-modal>
