export enum TextbookRoute {
  Root = '',
}

export enum TextbookChildRoute {
  Help = 'help',
  Search = 'search',
  Action = 'action',
  SandboxDrawer = 'sandbox-drawer',
}

export enum TextbookRouteQueryParam {
  Page = 'page',
}
