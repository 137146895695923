import { Textbook } from '@gwo/textbook-api-client';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

export const parseTextbooks =
  () =>
  (source: Observable<Textbook[]>): Observable<Textbook[]> =>
    source.pipe(
      map((textbooks) =>
        textbooks
          .map((textbook) =>
            textbook.accesses.map((access) => ({
              ...textbook,
              downloadTextbookId: textbook.id + access.accessId,
              accesses: [access],
            }))
          )
          .reduce((acc, textbookAccesses) => [...acc, ...textbookAccesses], [])
          .map((textbook, index) => ({ ...textbook, order: index }))
      )
    );
