import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'humanReadableFormat',
})
export class HumanReadableFormatPipe implements PipeTransform {
  transform(value = 0): string | undefined {
    return value
      .toString()
      .match(/.{1,2}/g)
      ?.join('-');
  }
}
