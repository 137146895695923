import { Injectable } from '@angular/core';
import { UserFacade } from '@app/core/store/facade/user.facade';
import { NetworkService } from '@app/shared/services/network.service';
import { TextbookClient } from '@gwo/textbook-api-client';
import { ImageSize } from '@gwo/textbook-api-client/lib/interface/image-size';
import {
  combineLatestWith,
  concatMap,
  first,
  forkJoin,
  from,
  last,
  map,
  mapTo,
  Observable,
  of,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import { TextbookMorph } from '../models/textbook.model';
import { DownloadRegistryFacade, SelectedTextbookFacade } from '../store/facade';
import {
  DownloadingChunk,
  DownloadQueueUnit,
  DownloadQueueUnitPackage,
  DownloadRegistryUnit,
  DownloadUnitStatus,
} from '../store/reducers';
import { DataCleanerService } from './data-cleaner.service';
import { RuntimeService, TextbookMetaScope } from './runtime.service';
import { TextbookAction } from '@gwo/textbook-api-client/lib/actions';
import { mapToQueuePackages, mapToResourcesQueue } from '@app/shelf/utils/resources-download-util';
import { TextbookActionGroup } from '@gwo/textbook-api-client/lib/interface/textbook-action-group.model';
import { catchError, withLatestFrom } from 'rxjs/operators';
import { User } from '@gwo/textbook-api-client/lib/interface/user.model';
import { stringToBlob } from '@app/shared/utils/stringtoBlob';
import { cloneDeep as _cloneDeep } from 'lodash';
import { BlobResourceResponse } from '@gwo/textbook-api-client/lib/interface/blob-resource-response.model';
import { MemoryExhaustedError } from '@app/core/modules/error-handler/errors/memory-exhausted.error';

type DownloadProgress = Pick<
  DownloadRegistryUnit,
  'downloadPercent' | 'downloadingChunk' | 'lastDownloaded' | 'downloadQueueLength'
>;

@Injectable()
export class DownloadService {
  constructor(
    private readonly textbookClient: TextbookClient,
    private readonly userFacade: UserFacade,
    private readonly runtimeService: RuntimeService,
    private readonly dataCleanerService: DataCleanerService,
    private readonly downloadRegistryFacade: DownloadRegistryFacade,
    private readonly networkService: NetworkService,
    private readonly selectedTextbookFacade: SelectedTextbookFacade
  ) {
    this.networkService.isOnlineMode$
      .pipe(combineLatestWith(this.downloadRegistryFacade.selectDownloadingTextbook$()))
      .subscribe(([online, unit]) => {
        if (!online && unit) {
          this.stopDownload(unit.textbookIndex);
        }
      });
  }

  private downloadSubscriptions: Record<string, Subscription> = {};

  private readonly pagesPercent = 2;
  private readonly textualPagesPercent = 4;
  private readonly resourcesPercent = 10;
  private readonly actionsPercent = 90;
  private readonly legendPercent = 91;
  private readonly actionTriggersPercent = 96;
  private readonly actionGroupsPercent = 100;
  // TODO Określenie % postępu pobierania dla treści użytkownika - do przywrócenia po opracowaniu mechanizmu
  //  aktualizacji treści użytkownika
  // private notesPercent = 96;
  // private markersPercent = 97;
  // private drawingsPercent = 99;
  // private actionNotesPercent = 100;
  // private queueSpeed = 10;
  private readonly sharedResourcesDownloadStepEnd: DownloadProgress = {
    downloadPercent: this.actionsPercent,
    downloadingChunk: DownloadingChunk.legend,
    lastDownloaded: undefined,
    downloadQueueLength: 1,
  };

  private createEmptyDownloadRegistryUnit(textbook: TextbookMorph): DownloadRegistryUnit {
    return {
      textbookIndex: textbook.index,
      textbookId: textbook.id,
      textbookAccessId: textbook.access.accessId,
      downloadPercent: 0,
      downloadingChunk: DownloadingChunk.pages,
      requestedBy: {},
      status: DownloadUnitStatus.process,
      textbookAccessLevel: textbook.access.accessLevel,
      version: textbook.version,
      expireDate: textbook.access.endDate ? new Date(textbook.access.endDate) : undefined,
      isUserContentModified: false,
    };
  }

  private supplyDownloadRegistryUnit(
    unit: DownloadRegistryUnit,
    textbook: TextbookMorph
  ): Observable<DownloadRegistryUnit> {
    unit = _cloneDeep(unit);
    return this.userFacade.selectUser$.pipe(
      first(),
      map((user) => {
        unit.requestedBy[user.externalId.toString()] = true;
        unit.status = DownloadUnitStatus.process;
        unit.textbookIndex = textbook.index;
        unit.textbookId = textbook.id;
        unit.textbookAccessId = textbook.access.accessId;
        unit.textbookAccessLevel = textbook.access.accessLevel;
        return unit;
      })
    );
  }

  private getDownloadRegistryUnit(textbook: TextbookMorph): Observable<DownloadRegistryUnit> {
    return from(this.runtimeService.readTextbookSharedMeta(textbook.id)).pipe(
      map((unit) => (unit ? unit : this.createEmptyDownloadRegistryUnit(textbook))),
      switchMap((unit) => this.supplyDownloadRegistryUnit(unit, textbook))
    );
  }

  private setDownloadRegistryUnit(
    id: string,
    unit: DownloadRegistryUnit,
    scope: TextbookMetaScope,
    overrideAccessDownloadProgress?: DownloadProgress
  ): Observable<DownloadRegistryUnit> {
    return this.downloadRegistryFacade.setDownloadRegistryUnit(unit).pipe(
      switchMap(() => from(this.runtimeService.writeTextbookMetaData(id, unit, scope))),
      withLatestFrom(this.userFacade.selectUser$),
      switchMap(([_, selectedUser]) =>
        from(
          this.writeTextbookMetaAccessForUser(unit, selectedUser, overrideAccessDownloadProgress)
        )
      ),
      switchMap((accessDownloadUnit) =>
        this.downloadRegistryFacade.setDownloadRegistryUnit(accessDownloadUnit)
      ),
      mapTo(_cloneDeep(unit))
    );
  }

  private async writeTextbookMetaAccessForUser(
    higherScopeUnit: DownloadRegistryUnit,
    user: User,
    overrideAccessDownloadProgress?: DownloadProgress
  ): Promise<DownloadRegistryUnit> {
    const userAccessMetaUnit = _cloneDeep(higherScopeUnit);
    const keysToClear = Object.keys(userAccessMetaUnit.requestedBy).filter(
      (userId) => userId != user.externalId.toString()
    );
    keysToClear.forEach((key) => delete userAccessMetaUnit.requestedBy[key]);
    if (overrideAccessDownloadProgress) {
      this.writeDownloadProgress(userAccessMetaUnit, overrideAccessDownloadProgress);
    }
    await this.runtimeService.writeTextbookMetaAccessScope(
      userAccessMetaUnit.textbookId,
      userAccessMetaUnit.textbookAccessId.toString(),
      userAccessMetaUnit
    );
    return userAccessMetaUnit;
  }

  private setDownloadRegistryUnitTextbookScope(
    id: string,
    unit: DownloadRegistryUnit
  ): Observable<DownloadRegistryUnit> {
    return this.setDownloadRegistryUnit(id, unit, TextbookMetaScope.textbook);
  }

  private startChunkDownload(
    id: string,
    unit: DownloadRegistryUnit,
    scope: TextbookMetaScope
  ): Observable<DownloadRegistryUnit> {
    unit = _cloneDeep(unit);
    unit.downloadQueueLength = 1;
    return this.setDownloadRegistryUnit(id, unit, scope);
  }

  private completeChunkDownload(
    id: string,
    unit: DownloadRegistryUnit,
    scope: TextbookMetaScope,
    progressPercent: number | null = null
  ): Observable<DownloadRegistryUnit> {
    unit = _cloneDeep(unit);
    delete unit.downloadQueueLength;
    delete unit.lastDownloaded;
    console.log('DOWNLOADED CHUNK', unit.downloadingChunk);
    unit.downloadingChunk++;
    if (progressPercent) unit.downloadPercent = progressPercent;
    return this.setDownloadRegistryUnit(id, unit, scope);
  }

  private processDownloadPages(unit: DownloadRegistryUnit): Observable<DownloadRegistryUnit> {
    return this.startChunkDownload(unit.textbookId, unit, TextbookMetaScope.textbook).pipe(
      switchMap(() => this.textbookClient.getTextbookPages$(unit.textbookId, unit.version)),
      switchMap((pages) => this.runtimeService.writeTextbookPages(unit.textbookId, pages)),
      switchMap(() =>
        this.completeChunkDownload(
          unit.textbookId,
          unit,
          TextbookMetaScope.textbook,
          this.pagesPercent
        )
      )
    );
  }

  private async getResourcesDownloadQueue(
    unit: DownloadRegistryUnit
  ): Promise<DownloadQueueUnit[]> {
    const pages = await this.runtimeService.readTextbookPages(unit.textbookId);
    return [ImageSize.LARGE]
      .map((size) =>
        pages.map((page) => ({
          id: page.jpegResourceId,
          meta: { size },
          index: page.jpegResourceId,
        }))
      )
      .flat()
      .sort();
  }

  private sliceDownloadedFromQueue<T extends DownloadQueueUnitPackage | DownloadQueueUnit>(
    queue: T[],
    lastDownloaded?: T
  ): T[] {
    if (!lastDownloaded) {
      return queue;
    }
    return queue.slice(queue.findIndex((unit) => unit.index === lastDownloaded.index));
  }

  private calcDownloadedPercent(percent: number, max: number, reduce: number): number {
    const portion = percent - reduce;
    return portion / max;
  }

  private processDownloadResources(unit: DownloadRegistryUnit): Observable<DownloadRegistryUnit> {
    const reduce = unit.downloadPercent;
    const startTime = new Date().getTime();
    return from(this.getResourcesDownloadQueue(unit)).pipe(
      tap((queue) => console.log('RESOURCES QUEUE SIZE', queue.length)),
      mapToQueuePackages,
      switchMap((queue) => {
        unit.downloadQueueLength = queue.length;
        return this.setDownloadRegistryUnitTextbookScope(unit.textbookId, unit).pipe(mapTo(queue));
      }),
      map(
        (queue) =>
          this.sliceDownloadedFromQueue(queue, unit.lastDownloaded) as DownloadQueueUnitPackage[]
      ),
      switchMap((queue) =>
        from(queue).pipe(
          concatMap((queuePackage) => {
            const ids = queuePackage.units.map((queueUnit) => queueUnit.id);
            return this.downloadResources(unit.textbookId, ids, ImageSize.LARGE).pipe(
              map((blobPackage) => {
                const resources = new Map();
                blobPackage.forEach((resource: BlobResourceResponse) =>
                  Object.entries(resource).forEach(([key, value]) =>
                    resources.set(key, stringToBlob(value))
                  )
                );
                return resources;
              }),
              switchMap((resources: Map<string, Blob>) =>
                from(this.saveResourcesPackage(resources, unit.textbookId))
              ),
              switchMap(() =>
                this.updateStatus(unit, queuePackage, reduce || 0, this.resourcesPercent)
              ),
              tap((updatedUnit) => (unit = updatedUnit)),
              mapTo(true)
            );
          }),
          last()
        )
      ),
      tap(() => this.logDownloadTime('SAVED RESOURCES IN(s):', startTime)),
      switchMap(() => this.completeChunkDownload(unit.textbookId, unit, TextbookMetaScope.textbook))
    );
  }

  private processDownloadActions(unit: DownloadRegistryUnit): Observable<DownloadRegistryUnit> {
    const reduce = unit.downloadPercent;
    const actionsResourcesQueue: Observable<DownloadQueueUnitPackage[]> =
      this.downloadAndGetSavedActions(unit).pipe(
        mapToResourcesQueue,
        tap((queue) => console.log('ACTIONS RESOURCES QUEUE SIZE', queue.length)),
        mapToQueuePackages,
        switchMap((queue) => {
          unit.downloadQueueLength = queue.length;
          return this.setDownloadRegistryUnitTextbookScope(unit.textbookId, unit).pipe(
            mapTo(queue)
          );
        }),
        map(
          (queue) =>
            this.sliceDownloadedFromQueue(queue, unit.lastDownloaded) as DownloadQueueUnitPackage[]
        )
      );
    const resourcesStartTime = new Date().getTime();
    return actionsResourcesQueue.pipe(
      switchMap((queue) =>
        from(queue).pipe(
          concatMap((queuePackage) => {
            const ids = queuePackage.units.map((queueUnit) => queueUnit.id);
            return this.downloadResources(unit.textbookId, ids, null).pipe(
              map((blobPackage) => {
                const resources = new Map();
                blobPackage.forEach((resource: BlobResourceResponse) =>
                  Object.entries(resource).forEach(([key, value]) =>
                    resources.set(key, stringToBlob(value))
                  )
                );
                return resources;
              }),
              switchMap((resources: Map<string, Blob>) =>
                from(this.saveResourcesPackage(resources, unit.textbookId))
              ),
              switchMap(() =>
                this.updateStatus(unit, queuePackage, reduce || 0, this.actionsPercent)
              ),
              tap((updatedUnit) => (unit = updatedUnit)),
              mapTo(true)
            );
          }),
          last()
        )
      ),
      tap(() => this.logDownloadTime('SAVED ACTIONS RESOURCES IN(s):', resourcesStartTime)),
      switchMap(() => this.completeChunkDownload(unit.textbookId, unit, TextbookMetaScope.textbook))
    );
  }

  private downloadResources(
    textbookId: string,
    ids: string[],
    imageSize: ImageSize | null,
    splitRequest = false
  ): Observable<BlobResourceResponse[]> {
    if (splitRequest) {
      const middleIndex = Math.ceil(ids.length / 2);
      const firstHalf = ids.splice(0, middleIndex);
      const secondHalf = ids.splice(-middleIndex);
      return forkJoin([
        this.downloadResources(textbookId, firstHalf, imageSize),
        this.downloadResources(textbookId, secondHalf, imageSize),
      ]).pipe(map(([firstResult, secondResult]) => firstResult.concat(secondResult)));
    }
    return this.textbookClient.getResources$(textbookId, ids, imageSize).pipe(
      catchError((err) => {
        const memoryExhaustedError =
          err.status == MemoryExhaustedError.MEMORY_EXHAUSTED_ERROR_STATUS &&
          err.message == MemoryExhaustedError.MEMORY_EXHAUSTED_ERROR_TITLE;
        if (memoryExhaustedError && ids.length >= 2) {
          return this.downloadResources(textbookId, ids, imageSize, true);
        }
        throw err;
      })
    );
  }

  private updateStatus(
    unitInProgress: DownloadRegistryUnit,
    downloadedPackage: DownloadQueueUnitPackage,
    reducePercent: number,
    sectionPercents: number
  ): Observable<DownloadRegistryUnit> {
    unitInProgress = _cloneDeep(unitInProgress);
    unitInProgress.lastDownloaded = downloadedPackage;
    const percent = this.calcDownloadedPercent(
      sectionPercents,
      unitInProgress.downloadQueueLength || 0,
      reducePercent
    );
    unitInProgress.downloadPercent += percent;
    return this.setDownloadRegistryUnitTextbookScope(
      unitInProgress.textbookId,
      unitInProgress
    ).pipe(mapTo(unitInProgress));
  }

  private downloadAndGetSavedActions(unit: DownloadRegistryUnit): Observable<TextbookAction[]> {
    const startTime = new Date().getTime();
    return this.textbookClient.getTextbookActions$(unit.textbookId, unit.version).pipe(
      switchMap((actionsMap) =>
        from(this.runtimeService.writeAllTextbookActions(unit.textbookId, actionsMap)).pipe(
          mapTo(actionsMap)
        )
      ),
      tap(() => this.logDownloadTime('SAVED ACTIONS IN(s):', startTime)),
      map((actionsMap) => Object.values(actionsMap))
    );
  }

  private async saveResourcesPackage(
    blobPackage: Map<string, Blob>,
    textbookId: string
  ): Promise<void> {
    const saveStart = new Date().getTime();
    for (const entry of blobPackage.entries()) {
      await this.runtimeService.writeStringResource(textbookId, entry[0], entry[1]);
    }
    this.logDownloadTime('SAVED RESOURCES PACKAGE IN(s):', saveStart);
  }

  private processDownloadTextualPages(
    unit: DownloadRegistryUnit
  ): Observable<DownloadRegistryUnit> {
    return this.startChunkDownload(unit.textbookId, unit, TextbookMetaScope.textbook).pipe(
      switchMap(() => this.textbookClient.getTextbookTextualPages$(unit.textbookId, unit.version)),
      switchMap((pages) => this.runtimeService.writeTextbookTextualPages(unit.textbookId, pages)),
      switchMap(() =>
        this.completeChunkDownload(
          unit.textbookId,
          unit,
          TextbookMetaScope.textbook,
          this.textualPagesPercent
        )
      )
    );
  }

  // TODO Pobiernie notatek dla użytkownika - do przywrócenia po opracowaniu mechanizmu aktualizacji
  //  treści użytkownika
  // private downloadNotes(unit: DownloadRegistryUnit): Observable<void> {
  //   return this.textbookClient
  //     .getTextbookNotes$(unit.textbookId)
  //     .pipe(switchMap((notes) => this.runtimeService.writeUserNotes(unit.textbookId, notes)));
  // }
  //
  // private processDownloadNotes(unit: DownloadRegistryUnit): Observable<DownloadRegistryUnit> {
  //   const download = this.startChunkDownload(unit.textbookId, unit, TextbookMetaScope.user).pipe(
  //     switchMap(() => this.downloadNotes(unit)),
  //     switchMap(() =>
  //       this.completeChunkDownload(unit.textbookId, unit, TextbookMetaScope.user, this.notesPercent)
  //     )
  //   );
  //   return from(this.runtimeService.readTextbookUserMetaById(unit.textbookId)).pipe(
  //     switchMap((metaUnit) => {
  //       if (!metaUnit || metaUnit.textbookIndex !== unit.textbookIndex) return download;
  //       unit = _cloneDeep(unit);
  //       unit.downloadingChunk = DownloadingChunk.NULL;
  //       return this.setDownloadRegistryUnit(unit.textbookId, unit, TextbookMetaScope.user);
  //     })
  //   );
  // }

  // TODO Pobiernie markerów/zaznaczeń dla użytkownika - do przywrócenia po opracowaniu mechanizmu aktualizacji
  //  treści użytkownika
  // private downloadMarkers(unit: DownloadRegistryUnit): Observable<void> {
  //   return this.textbookClient
  //     .getTextbookMarkers$(unit.textbookId)
  //     .pipe(switchMap((markers) => this.runtimeService.writeUserMarkers(unit.textbookId, markers)));
  // }
  //
  // private processDownloadMarkers(unit: DownloadRegistryUnit): Observable<DownloadRegistryUnit> {
  //   return this.startChunkDownload(unit.textbookId, unit, TextbookMetaScope.user).pipe(
  //     switchMap(() => this.downloadMarkers(unit)),
  //     switchMap(() =>
  //       this.completeChunkDownload(
  //         unit.textbookId,
  //         unit,
  //         TextbookMetaScope.user,
  //         this.markersPercent
  //       )
  //     )
  //   );
  // }

  private processDownloadLegend(unit: DownloadRegistryUnit): Observable<DownloadRegistryUnit> {
    const download = this.startChunkDownload(unit.textbookId, unit, TextbookMetaScope.access).pipe(
      switchMap(() =>
        this.textbookClient.getTextbookLegend$(unit.textbookId, unit.version, unit.textbookAccessId)
      ),
      switchMap((legend) =>
        this.runtimeService.writeTextbookLegend(unit.textbookId, unit.textbookAccessLevel, legend)
      ),
      switchMap(() =>
        this.completeChunkDownload(
          unit.textbookId,
          unit,
          TextbookMetaScope.access,
          this.legendPercent
        )
      )
    );
    return from(
      this.runtimeService.readTextbookAccessLevelMetaById(unit.textbookId, unit.textbookAccessLevel)
    ).pipe(
      switchMap((metaUnit) => {
        if (!metaUnit || metaUnit.textbookIndex !== unit.textbookIndex) return download;
        unit = _cloneDeep(unit);
        unit.downloadingChunk = DownloadingChunk.notes;
        return this.setDownloadRegistryUnit(unit.textbookId, unit, TextbookMetaScope.access);
      })
    );
  }

  private processDownloadActionTriggers(
    unit: DownloadRegistryUnit
  ): Observable<DownloadRegistryUnit> {
    return this.startChunkDownload(unit.textbookId, unit, TextbookMetaScope.access).pipe(
      switchMap(() =>
        this.textbookClient.getTextbookActionTriggers$(
          unit.textbookId,
          unit.version,
          unit.textbookAccessId
        )
      ),
      switchMap((triggers) =>
        this.runtimeService.writeTextbookActionTriggers(
          unit.textbookId,
          unit.textbookAccessLevel,
          triggers
        )
      ),
      switchMap(() =>
        this.completeChunkDownload(
          unit.textbookId,
          unit,
          TextbookMetaScope.access,
          this.actionTriggersPercent
        )
      )
    );
  }

  private processDownloadActionGroups(
    unit: DownloadRegistryUnit
  ): Observable<DownloadRegistryUnit> {
    return this.startChunkDownload(unit.textbookId, unit, TextbookMetaScope.access).pipe(
      switchMap(() =>
        this.textbookClient.getTextbookActionGroups$(
          unit.textbookId,
          unit.version,
          unit.textbookAccessId
        )
      ),
      switchMap((groups: TextbookActionGroup[]) =>
        this.runtimeService.writeTextbookActionGroups(
          unit.textbookId,
          unit.textbookAccessLevel,
          groups
        )
      ),
      switchMap(() =>
        this.completeChunkDownload(
          unit.textbookId,
          unit,
          TextbookMetaScope.access,
          this.actionGroupsPercent
        )
      )
    );
  }

  // TODO Pobiernie rysunków dla użytkownika - do przywrócenia po opracowaniu mechanizmu aktualizacji
  //  treści użytkownika
  // private downloadDrawings(unit: DownloadRegistryUnit): Observable<void | void[]> {
  //  return this.textbookClient.getTextbookDrawings$(unit.textbookId).pipe(
  //     switchMap((userDrawings) => from(userDrawings)),
  //     mergeMap(
  //       (drawing: Drawing) =>
  //         this.runtimeService.writeUserActionDrawing(
  //           unit.textbookId,
  //           drawing.actionId,
  //           drawing
  //         ),
  //       this.queueSpeed
  //     ),
  //     toArray(),
  //     mapTo(void 0)
  //   );
  // }
  //
  // private processDownloadDrawings(unit: DownloadRegistryUnit): Observable<DownloadRegistryUnit> {
  //   return this.startChunkDownload(unit.textbookId, unit, TextbookMetaScope.user).pipe(
  //     switchMap(() => this.downloadDrawings(unit)),
  //     switchMap(() =>
  //       this.completeChunkDownload(
  //         unit.textbookId,
  //         unit,
  //         TextbookMetaScope.user,
  //         this.drawingsPercent
  //       )
  //     )
  //   );
  // }

  // TODO Pobiernie treści tekstowej drawera dla użytkownika - do przywrócenia po opracowaniu mechanizmu aktualizacji
  //  treści użytkownika
  // private downloadActionNotes(unit: DownloadRegistryUnit): Observable<void | void[]> {
  //   return this.textbookClient.getTextbookActionNotes$(unit.textbookId).pipe(
  //     switchMap((userNotes) => from(userNotes)),
  //     mergeMap(
  //       (userNote: ActionNote) =>
  //         this.runtimeService.writeUserActionNote(
  //           unit.textbookId,
  //           userNote.actionId,
  //           userNote
  //         ),
  //       this.queueSpeed
  //     ),
  //     toArray(),
  //     mapTo(void 0)
  //   );
  // }
  //
  // private processDownloadActionNotes(unit: DownloadRegistryUnit): Observable<DownloadRegistryUnit> {
  //   return this.startChunkDownload(unit.textbookId, unit, TextbookMetaScope.user).pipe(
  //     switchMap(() => this.downloadActionNotes(unit)),
  //     switchMap(() =>
  //       this.completeChunkDownload(
  //         unit.textbookId,
  //         unit,
  //         TextbookMetaScope.user,
  //         this.actionNotesPercent
  //       )
  //     )
  //   );
  // }

  private completeDownload(unit: DownloadRegistryUnit): Observable<DownloadRegistryUnit> {
    return of(unit).pipe(
      switchMap(() => from(this.runtimeService.readTextbookSharedMeta(unit.textbookId))),
      switchMap((textbookLevelUnit) => {
        if (textbookLevelUnit) {
          this.writeDownloadProgress(textbookLevelUnit, this.sharedResourcesDownloadStepEnd);
          return from(
            this.runtimeService.writeTextbookMetaData(unit.textbookId, textbookLevelUnit)
          );
        }
        return of(textbookLevelUnit);
      }),
      tap(() => (unit.status = DownloadUnitStatus.downloaded)),
      // FIXME Linia niżej (zapis statusu zakończenia pobierania dla poziomu dostępu)
      //  do usunięcia po wdrożeniu mechanizmu aktualizacji treści użytkownika
      switchMap(() =>
        from(
          this.runtimeService.writeTextbookMetaData(unit.textbookId, unit, TextbookMetaScope.access)
        )
      ),
      switchMap(() => this.setDownloadRegistryUnit(unit.textbookId, unit, TextbookMetaScope.user)),
      tap(() => this.downloadRegistryFacade.downloadStopped(unit.textbookIndex)),
      tap((unit) => !!unit.textbookIndex && this.closeDownloadSubscription(unit.textbookIndex))
    );
  }

  private closeDownloadSubscription(textbookIndex: string): void {
    this.downloadSubscriptions[textbookIndex].unsubscribe();
    delete this.downloadSubscriptions[textbookIndex];
  }

  private processDownloadRegistryUnit(
    unit: DownloadRegistryUnit
  ): Observable<DownloadRegistryUnit> {
    let pipeline = of(unit);
    if (unit.downloadingChunk === DownloadingChunk.NULL) {
      return this.completeDownload(unit);
    } else if (unit.downloadingChunk === DownloadingChunk.pages) {
      pipeline = this.processDownloadPages(unit);
    } else if (unit.downloadingChunk === DownloadingChunk.textualPages) {
      pipeline = this.processDownloadTextualPages(unit);
    } else if (unit.downloadingChunk === DownloadingChunk.resources) {
      pipeline = this.processDownloadResources(unit);
    } else if (unit.downloadingChunk === DownloadingChunk.actions) {
      pipeline = this.processDownloadActions(unit);
    } else if (unit.downloadingChunk === DownloadingChunk.legend) {
      pipeline = this.processDownloadLegend(unit);
    } else if (unit.downloadingChunk === DownloadingChunk.actionsTriggers) {
      pipeline = this.processDownloadActionTriggers(unit);
    } else if (unit.downloadingChunk === DownloadingChunk.actionsGroups) {
      pipeline = this.processDownloadActionGroups(unit);
      // TODO Pobiernie treści dla użytkownika - do przywrócenia po opracowaniu mechanizmu aktualizacji
      // } else if (unit.downloadingChunk === DownloadingChunk.notes) {
      //   pipeline = this.processDownloadNotes(unit);
      // } else if (unit.downloadingChunk === DownloadingChunk.markers) {
      //   pipeline = this.processDownloadMarkers(unit);
      // } else if (unit.downloadingChunk === DownloadingChunk.drawings) {
      //   pipeline = this.processDownloadDrawings(unit);
      // } else if (unit.downloadingChunk === DownloadingChunk.actionNotes) {
      //   pipeline = this.processDownloadActionNotes(unit);
    } else {
      return this.completeDownload(unit);
    }
    return pipeline.pipe(switchMap(($) => this.processDownloadRegistryUnit($)));
  }

  startDownload(textbook: TextbookMorph): void {
    const startDate = new Date().getTime();
    this.downloadSubscriptions[textbook.index] = this.getDownloadRegistryUnit(textbook)
      .pipe(switchMap((unit) => this.processDownloadRegistryUnit(unit)))
      .subscribe(() => this.logDownloadTime('TEXTBOOK DOWNLOAD TIME(s):', startDate));
  }

  // TODO Aktualizacja treści użytkownika - do przywrócenia po opracowaniu mechanizmu aktualizacji
  // startUserDataUpdate(textbook: TextbookMorph): void {
  //   of(this.selectedTextbookFacade.cleanSelectedTextbook())
  //     .pipe(
  //       switchMap(() => this.getDownloadRegistryUnit(textbook)),
  //       switchMap((unit) => {
  //         unit = _cloneDeep(unit);
  //         unit.status = DownloadUnitStatus.updating;
  //         return this.setDownloadRegistryUnit(unit.textbookId, unit, TextbookMetaScope.access).pipe(
  //           switchMap(() => this.runtimeService.removeCurrentUserTextbookData(unit.textbookId)),
  //           switchMap(() => this.downloadNotes(unit)),
  //           switchMap(() => this.downloadMarkers(unit)),
  //           switchMap(() => this.downloadDrawings(unit)),
  //           switchMap(() => this.downloadActionNotes(unit)),
  //           mapTo(unit)
  //         );
  //       }),
  //       switchMap((unit) => {
  //         unit = _cloneDeep(unit);
  //         unit.status = DownloadUnitStatus.downloaded;
  //         return this.setDownloadRegistryUnit(unit.textbookId, unit, TextbookMetaScope.access);
  //       })
  //     )
  //     .subscribe();
  // }

  stopDownload(index: string): void {
    this.closeDownloadSubscription(index);
    this.downloadRegistryFacade
      .getDownloadRegistryUnit(index)
      .pipe(
        switchMap((unit) => {
          unit = _cloneDeep(unit);
          unit.status = DownloadUnitStatus.paused;
          if (unit.downloadingChunk >= this.sharedResourcesDownloadStepEnd.downloadingChunk) {
            const downloadProgressSnapshot = {} as DownloadProgress;
            this.writeDownloadProgress(downloadProgressSnapshot, unit);
            this.writeDownloadProgress(unit, this.sharedResourcesDownloadStepEnd);
            return this.setDownloadRegistryUnit(
              unit.textbookId,
              unit,
              TextbookMetaScope.textbook,
              downloadProgressSnapshot
            );
          }
          return this.setDownloadRegistryUnit(unit.textbookId, unit, TextbookMetaScope.textbook);
        }),
        tap(() => this.downloadRegistryFacade.downloadStopped(index))
      )
      .subscribe();
  }

  private writeDownloadProgress<T extends DownloadProgress>(target: T, source: T): void {
    target.downloadingChunk = source.downloadingChunk;
    target.downloadPercent = source.downloadPercent;
    target.lastDownloaded = source.lastDownloaded;
    target.downloadQueueLength = source.downloadQueueLength;
  }

  private markForDrop(unit: DownloadRegistryUnit): Observable<DownloadRegistryUnit> {
    unit = _cloneDeep(unit);
    unit.status = DownloadUnitStatus.markedForDrop;
    return this.userFacade.selectUser$.pipe(
      first(),
      switchMap((user) => {
        delete unit.requestedBy[user.externalId.toString()];
        return this.setDownloadRegistryUnit(unit.textbookId, unit, TextbookMetaScope.access);
      })
    );
  }

  private deleteTextbook(textbook: TextbookMorph, exhausted?: boolean): Observable<void> {
    return of(
      this.downloadSubscriptions[textbook.index] ? this.stopDownload(textbook.index) : 0
    ).pipe(
      switchMap(() => this.runtimeService.readTextbookAccessLevelMeta(textbook)),
      switchMap((unit) => (unit ? of(unit) : this.runtimeService.readTextbookMeta(textbook))),
      switchMap((unit) =>
        exhausted && !unit ? this.runtimeService.readTextbookSharedMeta(textbook.id) : of(unit)
      ),
      switchMap((unit) =>
        unit
          ? this.supplyDownloadRegistryUnit(unit, textbook).pipe(
              switchMap(($) => this.markForDrop($)),
              switchMap(($) => this.dataCleanerService.addDeleteTextbookTask($, exhausted)),
              switchMap(() => this.downloadRegistryFacade.getDownloadRegistry()),
              map((registry) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { [textbook.index]: _, ...rest } = registry;
                return this.downloadRegistryFacade.setDownloadRegistry(rest);
              })
            )
          : of(undefined)
      )
    );
  }

  deleteDownloadedTextbook(textbook: TextbookMorph, exhausted?: boolean): void {
    this.deleteTextbook(textbook, exhausted).subscribe();
  }

  updateTextbookData(textbook: TextbookMorph): void {
    this.deleteTextbook(textbook, true)
      .pipe(map(() => this.startDownload(textbook)))
      .subscribe();
  }

  private logDownloadTime(msg: string, startTime: number): void {
    console.log(msg, (new Date().getTime() - startTime) / 1000);
  }
}
