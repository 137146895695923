import { map, Observable } from 'rxjs';
import { TextbookAction } from '@gwo/textbook-api-client/lib/actions';
import { DownloadQueueUnit, DownloadQueueUnitPackage } from '@app/shelf/store/reducers';

export const mapToResourcesQueue = (
  actions$: Observable<TextbookAction[]>
): Observable<DownloadQueueUnit[]> =>
  actions$.pipe(
    map(
      (actions) =>
        new Set(actions.reduce((joined: string[], action) => joined.concat(action.resourceIds), []))
    ),
    map((resourcesIds) => Array.from(resourcesIds)),
    map((resourcesIds) =>
      resourcesIds.map((id) => ({ id: id, meta: {}, index: id } as DownloadQueueUnit))
    )
  );

export const mapToQueuePackages = (
  queue$: Observable<DownloadQueueUnit[]>
): Observable<DownloadQueueUnitPackage[]> => {
  return queue$.pipe(
    map((queue) => {
      const queuePackages: DownloadQueueUnitPackage[] = [];
      const packageSize = 10;
      let packageIndex = 0;
      for (let i = 0; i < queue.length; i += packageSize) {
        queuePackages[packageIndex] = {
          index: packageIndex,
          units: queue.slice(i, i + packageSize),
        };
        packageIndex++;
      }
      return queuePackages;
    })
  );
};
