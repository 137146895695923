<app-modal-layout maxWidth="32rem" marginTop="2.5rem" (backdropClick)="onCloseClick()">
  <div class="content">
    <button class="close-btn" (click)="onCloseClick()"><app-icon name="close"></app-icon></button>
    <p class="header">{{ textbook!.access.name }}</p>

    <div class="section">
      <div>
        <p>
          Status:
          <span class="status">
            <span *ngIf="this.textbook!.access.freeDistribution; else defaultDistribution">
              {{ textbookStatusOpened }}
            </span>
            <ng-template #defaultDistribution>
              {{ textbook!.access.accessStatus | translateTextbookStatus }}
            </ng-template>
          </span>
        </p>
        <p>
          Typ dostępu:<span class="info">
            {{ textbook!.access.accessLevel | translateTextbookLevel }}
          </span>
        </p>
        <p *ngIf="!textbook!.access.freeDistribution">
          Dostęp ważny do:<span class="info">
            {{ (textbook!.access.endDate | date: 'dd.MM.yyyy') || '-' }}
          </span>
        </p>
      </div>
      <div>
        <p>Wersja: {{ textbook!.version | humanReadableFormat }}</p>
        <p>Wielkość pliku: ok. {{ textbook!.size * CONVERSION_RATIO | convertSize }}</p>
      </div>
    </div>
  </div>
</app-modal-layout>
