<app-modal-layout maxWidth="24rem" marginTop="2.5rem" (backdropClick)="onReject()">
  <div class="content">
    <button class="close-btn" (click)="onReject()"><app-icon name="close"></app-icon></button>

    <p class="header">Uwaga!</p>

    <p>
      <i> {{ textbook.access.name }} </i>
    </p>

    <p class="question">
      Czy na pewno chcesz usunąć multipodręcznik ze<br />
      swojego urządzenia?
    </p>

    <div>
      <button (click)="onConfirm()">Tak</button>
      <button (click)="onReject()">Nie</button>
    </div>
  </div>
</app-modal-layout>
