import {
  BehaviorSubject,
  from,
  fromEvent,
  Observable,
  startWith,
  Subject,
  Subscription,
} from 'rxjs';
import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { resizeObservable } from '@app/core/observables';
import { Device, DeviceInfo } from '@capacitor/device';

export enum WindowOrientation {
  Landscape,
  Portrait,
}

@Injectable()
export class UtilitiesService implements OnDestroy {
  private readonly currentClickedTarget$ubject = new Subject<HTMLElement | null>();
  private readonly windowOrientation$ubject = new BehaviorSubject<WindowOrientation | null>(null);
  private readonly windowBounds$ubject = new BehaviorSubject<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  private currentDeviceInfo!: DeviceInfo;
  private readonly subscription = new Subscription();
  private readonly mobileBlockerVisible$ubject = new BehaviorSubject<boolean>(false);

  get windowBounds$(): Observable<{ width: number; height: number }> {
    return this.windowBounds$ubject.asObservable();
  }

  get currentClickedTarget$(): Observable<HTMLElement | null> {
    return this.currentClickedTarget$ubject.asObservable();
  }

  get windowOrientation$(): Observable<WindowOrientation | null> {
    return this.windowOrientation$ubject.asObservable();
  }

  get isTouchDevice(): boolean {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  get isVirtualKeyboardSupported(): boolean {
    if (!this.currentDeviceInfo) {
      return false;
    }
    const { operatingSystem } = this.currentDeviceInfo;
    return !['mac', 'ios'].includes(operatingSystem);
  }

  get mobileBlockerVisible$(): Observable<boolean> {
    return this.mobileBlockerVisible$ubject.asObservable();
  }

  constructor(private ngZone: NgZone) {
    ngZone.runOutsideAngular(() => {
      document.addEventListener('mousedown', this.onDocumentClick.bind(this), { passive: true });
      document.addEventListener('touchstart', this.onDocumentClick.bind(this), { passive: true });
      this.subscription.add(fromEvent(window, 'resize').subscribe(() => this.onWindowResize()));
      this.subscription.add(
        resizeObservable(document.body)
          .pipe(startWith(null))
          .subscribe((e) => this.onOrientationChange(e as ResizeObserverEntry[]))
      );
      this.subscription.add(
        from(Device.getInfo()).subscribe((info) => (this.currentDeviceInfo = info))
      );
    });
  }

  updateMobileBlockerVisibility(visible: boolean): void {
    this.mobileBlockerVisible$ubject.next(visible);
  }

  private onDocumentClick(e: MouseEvent | TouchEvent): void {
    this.currentClickedTarget$ubject.next(e.target as HTMLElement);
  }

  private onOrientationChange(entries: ResizeObserverEntry[]): void {
    const width = entries ? entries[0]?.target?.clientWidth : window.innerWidth;
    const height = entries ? entries[0].target.clientHeight : window.innerHeight;
    this.windowOrientation$ubject.next(
      width > height ? WindowOrientation.Landscape : WindowOrientation.Portrait
    );
  }

  private onWindowResize(): void {
    const { innerWidth: width, innerHeight: height } = window;
    this.windowBounds$ubject.next({ width, height });
  }

  ngOnDestroy(): void {
    document.removeEventListener('mousedown', this.onDocumentClick);
    document.removeEventListener('touchstart', this.onDocumentClick);
    this.subscription.unsubscribe();
  }
}
