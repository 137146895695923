import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@app/core/services';
import { Router } from '@angular/router';
import { UserActions } from '@app/core/store/actions';
import { Observable, pipe, tap, UnaryFunction } from 'rxjs';
import { concatMapTo, map, switchMap } from 'rxjs/operators';
import { CoreRoute } from '@app/core/constants';
import { NetworkService } from '@app/shared/services/network.service';

@Injectable()
export class UserEffects {
  setUser$ = createEffect(() =>
    this.actions$.pipe(ofType(UserActions.updateUser), this.setUserData())
  );

  setUserAndRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.updateUserAndRedirectToShelf),
        switchMap(() => this.networkService.isOnlineMode),
        tap((connected) => {
          connected && this.setUserData();
          this.router.navigate([CoreRoute.Shelf]);
        })
      ),
    { dispatch: false }
  );

  setUserData(): UnaryFunction<Observable<unknown>, Observable<Action>> {
    return pipe(
      concatMapTo(this.authService.getAuthenticatedUser$()),
      map((user) => UserActions.setUserData({ user }))
    );
  }

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private readonly networkService: NetworkService
  ) {}
}
