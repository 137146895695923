import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TextbookClient } from '@gwo/textbook-api-client';
import { map, Observable } from 'rxjs';
import { TextbookRecord } from '../models/textbook.model';
import { TextbookModifications } from '../modifications';

@Injectable()
export class FetchService {
  constructor(
    private readonly textbookClient: TextbookClient,
    private readonly textbookModifications: TextbookModifications,
    private readonly sanitizer: DomSanitizer
  ) {}

  getResource(resourceId: string): Observable<string> {
    return this.textbookClient.getResource$(resourceId, null).pipe(
      map((blob) => URL.createObjectURL(blob)),
      map((url) => this.sanitizer.bypassSecurityTrustUrl(url) as string)
    );
  }

  getTextbooks$(): Observable<TextbookRecord> {
    return this.textbookClient
      .getTextbooks$()
      .pipe(map((textbooks) => this.textbookModifications.fullExtend(textbooks)));
  }
}
