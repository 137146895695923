<a [routerLink]="['/']"><h1>ERROR LOG</h1></a>
<div *ngIf="productionMode" class="dev-panel-production">
  <p>
    platform: {{ platform }}, networks status: {{ isOnlineMode$ | async }}, auth:
    {{ (user$ | async)?.firstName }} {{ (user$ | async)?.lastName }} version: {{ version }}
  </p>
</div>
<main>
  <div *ngFor="let item of errorsList$ | async" (click)="loadError(item)">{{ item }}</div>
</main>

<div id="viewer" *ngIf="currentError">
  <div id="close" (click)="exit()">CLOSE</div>
  <h2>{{ currentErrorName }}</h2>
  <p>{{ currentError }}</p>
</div>
