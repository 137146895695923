import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Unauthorized } from 'http-errors';
import { ErrorRoute } from '@app/errors/constants/routes';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, forkJoin, map, of, switchMap } from 'rxjs';
import { ErrorActions } from '../actions';
import { IndexDBError } from '@app/core/modules/error-handler/errors/indexDB.error';
import { OfflineError } from '@app/core/modules/error-handler/errors/offline.error';
import { LocalError } from '@app/core/modules/error-handler/errors/local.error';
import { UnknownTextbookError } from '@app/core/modules/error-handler/errors/unknown-request.error';
import { AuthService } from '@app/core/services';

@Injectable()
export class ErrorEffects {
  constructor(
    private actions$: Actions,
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  private unauthorizedErrorHandled(): boolean {
    if (this.authService.getToken()) {
      this.router.navigate(['error', ErrorRoute.NO_AUTH]);
    }
    return false;
  }

  private async isHandledError(error: Error): Promise<boolean> {
    if (
      error instanceof OfflineError ||
      error instanceof IndexDBError ||
      error instanceof LocalError
    ) {
      return false;
    } else if (error instanceof Unauthorized) {
      return this.unauthorizedErrorHandled();
    } else if (error instanceof UnknownTextbookError) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }

  errorEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorActions.errorOccurred),
      switchMap((data) => forkJoin([of(data), this.isHandledError(data.error)])),
      filter(([, handled]) => handled),
      map(([error]) => ErrorActions.setError(error))
    )
  );
}
