import { Inject, Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import {
  BaseManagerApi,
  TEXTBOOK_MANAGER_TOKEN,
} from '@app/core/providers/textbook-manager.provider';
import { PageNote } from '@gwo/textbook-api-client/lib/interface/page-note.model';
import { first, map } from 'rxjs/operators';
import { NotesState } from '@app/textbook/store/reducers/viewer.reducer';

@Injectable({
  providedIn: 'root',
})
export class NotesResolver implements Resolve<NotesState> {
  constructor(@Inject(TEXTBOOK_MANAGER_TOKEN) private textbookManager: BaseManagerApi) {}

  resolve(route: ActivatedRouteSnapshot): Observable<NotesState> {
    const {
      url: [, { path: textbookId }],
    } = route;

    return of(textbookId).pipe(
      switchMap((textbookId) => this.textbookManager.getTextbookNotes$(textbookId).pipe(first())),
      map(NotesResolver.parseNotes)
    );
  }

  private static parseNotes(pageNotes: PageNote[]): NotesState {
    if (!pageNotes) {
      return {};
    }
    const checkPositionNotes = pageNotes.map((note) => {
      if (note.distanceFromLeft > 64.99) {
        note.distanceFromLeft = 64.99;
      }
      return note;
    });

    return checkPositionNotes.reduce((result: { [key: string]: PageNote[] }, pageNote) => {
      return {
        ...result,
        [pageNote.pageId]: [...(result[pageNote.pageId] || []), pageNote],
      };
    }, {});
  }
}
