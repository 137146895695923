import { createAction, props } from '@ngrx/store';

export interface ErrorProp {
  error: Error;
  hash: string;
  httpErrorStatus?: number;
}

export const errorOccurred = createAction(
  '[Global Error Handler] Error Occurred',
  props<ErrorProp>()
);

export const setError = createAction('[Global Error Handler] Set Error', props<ErrorProp>());
