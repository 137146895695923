import { AppState } from '@app/core/store/reducers';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ShelfState, textbookFeatureKey } from '../reducers';
import { TextbooksState } from '../reducers';
import { shelfFeatureKey } from '../shelf-store.module';

export interface State extends AppState {
  [textbookFeatureKey]: TextbooksState;
}

const selectShelfState = createFeatureSelector<ShelfState>(shelfFeatureKey);

const selectTextbooksFeatureState = createSelector(selectShelfState, ({ textbooks }) => textbooks);

export const selectTextbooks = createSelector(
  selectTextbooksFeatureState,
  ({ textbooks }) => textbooks
);
