import { Injectable } from '@angular/core';
import { Textbook } from '@gwo/textbook-api-client';
import { TextbookAccessMorph, TextbookMorph, TextbookRecord } from '../models/textbook.model';

@Injectable()
export class TextbookModifications {
  extendsWithTextbookAccesses(textbook: Textbook): TextbookAccessMorph[] {
    const { accesses, ...rest } = textbook;
    return accesses.map((access) => ({ ...rest, access }));
  }

  extendWithUniqueId(textbooks: TextbookMorph[]): TextbookRecord {
    const res = {} as TextbookRecord;
    for (const textbook of textbooks) {
      textbook.index = String(textbook.access.accessId);
      res[textbook.index] = textbook;
    }
    return res;
  }

  fullExtend(textbooks: Textbook[]): TextbookRecord {
    const morphed = textbooks.map((textbook) => this.extendsWithTextbookAccesses(textbook)).flat();
    return this.extendWithUniqueId(morphed as TextbookMorph[]);
  }
}
