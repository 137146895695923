import {
  AfterViewInit,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { animationFrameScheduler, from, fromEvent, Subscription } from 'rxjs';
import { filter, map, mergeMap, subscribeOn, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DragHandleDirective } from './drag-handle.directive';

interface MouseCoords {
  x: number;
  y: number;
}

@Directive({
  selector: '[appDraggable]',
})
export class DraggableDirective implements AfterViewInit, OnDestroy {
  subscription = new Subscription();
  @Input() appDraggable = true;
  @Output() appDragStart = new EventEmitter<void>();
  @Output() appDragEnd = new EventEmitter<void>();
  @ContentChild(DragHandleDirective) dragHandle!: DragHandleDirective;
  @HostBinding('style.will-change') willChange = 'transform';

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const dragHandle = this.dragHandle?.elementRef.nativeElement || this.elementRef.nativeElement;
    const mousedown$ = from(['mousedown', 'touchstart']).pipe(
      mergeMap((event) => fromEvent<MouseEvent | TouchEvent>(dragHandle, event))
    );
    const mousemove$ = from(['mousemove', 'touchmove']).pipe(
      mergeMap((event) => fromEvent<MouseEvent | TouchEvent>(document, event))
    );
    const mouseup$ = from(['mouseup', 'touchend']).pipe(
      mergeMap((event) => fromEvent<MouseEvent | TouchEvent>(document, event))
    );

    const drag$ = mousedown$.pipe(
      tap(() => this.appDragStart.emit()),
      filter(() => this.appDraggable),
      map((event) => {
        const startCoords = this.getMousePosition(event);
        const transform = this.elementRef.nativeElement.style.transform;
        if (transform) {
          const transformValues = transform.match(/[-]?\d+/g);

          return {
            x: Math.round(startCoords.x - transformValues[0]),
            y: Math.round(startCoords.y - transformValues[1]),
          };
        }
        return startCoords;
      }),
      switchMap((startCoords) => {
        return mousemove$.pipe(
          map((move) => {
            move.preventDefault();
            const moveCoords = this.getMousePosition(move);

            return {
              left: Math.round(moveCoords.x - startCoords.x),
              top: Math.round(moveCoords.y - startCoords.y),
            };
          }),
          takeUntil(mouseup$.pipe(tap(() => this.appDragEnd.emit())))
        );
      }),
      subscribeOn(animationFrameScheduler)
    );

    this.subscription.add(
      drag$.subscribe((pos) => {
        this.elementRef.nativeElement.style.transform = `translateX(${pos.left}px) translateY(${pos.top}px)`;
      })
    );
  }

  getMousePosition(e: MouseEvent | TouchEvent): MouseCoords {
    const { clientX: x, clientY: y } =
      window.TouchEvent && e instanceof TouchEvent ? e.changedTouches[0] : (e as MouseEvent);

    return { x: Math.round(x), y: Math.round(y) };
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
