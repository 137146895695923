import { Component, OnDestroy, OnInit } from '@angular/core';
import { TextbookMorph, TextbookRecord } from '@app/shelf/models/textbook.model';
import { FetchService, ShelfService, RuntimeService } from '@app/shelf/services';
import { ShelfFacade, TextbooksFacade } from '@app/shelf/store/facade';
import { DownloadRegistryFacade } from '@app/shelf/store/facade/download-registry.facade';
import { UserFacade } from '@app/core/store/facade/user.facade';
import { AuthFacade } from '@app/core/store/facade/auth.facade';
import { asyncScheduler, concatMap, from, lastValueFrom, Observable, of, toArray } from 'rxjs';
import {
  combineLatestWith,
  distinctUntilChanged,
  filter,
  first,
  map,
  mapTo,
  skip,
  subscribeOn,
  switchMap,
  tap,
} from 'rxjs/operators';
import { NavigationService } from '@app/core/services';
import { NetworkService } from '@app/shared/services/network.service';
import { Router } from '@angular/router';
import { ErrorRoute } from '@app/errors/constants/routes';
import { DownloadUnitStatus } from '@app/shelf/store/reducers';
import * as dayjs from 'dayjs';
import { BytesToMegabytes } from '@app/shared/utils';
import { AccessStatus } from '@gwo/textbook-api-client/lib/interface/access-status.model';
import { App } from '@capacitor/app';
import { TextbookStatusUtil } from '@app/textbook/utils/textbook-status-util';
import { UpdateUserDataConfirmation } from '@app/shelf/components/update-user-data-modal/update-user-data-modal.component';
import { ProjectionUtil } from '@app/shelf/utils/projection-util';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-shelf',
  templateUrl: './shelf.component.html',
  styleUrls: ['./shelf.component.scss'],
})
export class ShelfComponent implements OnInit, OnDestroy {
  constructor(
    private readonly authFacade: AuthFacade,
    private readonly fetchService: FetchService,
    private readonly shelfService: ShelfService,
    private readonly navigationService: NavigationService,
    private readonly textbooksFacade: TextbooksFacade,
    private readonly userFacade: UserFacade,
    private readonly downloadRegistryFacade: DownloadRegistryFacade,
    private readonly networkService: NetworkService,
    private readonly shelfFacade: ShelfFacade,
    private readonly router: Router,
    private readonly runtimeService: RuntimeService
  ) {}

  user$ = this.userFacade.selectUser$;
  private readonly textbooks$: Observable<TextbookRecord> = this.textbooksFacade.getTextbooks();
  readonly sortedTextbooks$: Observable<TextbookMorph[]> = this.textbooks$.pipe(
    map((textbooksRecord: TextbookRecord) =>
      Object.keys(textbooksRecord).map((key: string) => textbooksRecord[key])
    ),
    map((textbookMorph: TextbookMorph[]) => textbookMorph.sort(this.textbookMorphCompareFn))
  );

  readonly emptyShelf$ = this.shelfService.shelfInitializationDone$.pipe(
    filter(Boolean),
    switchMap(() => this.textbooks$),
    map((record) => !Object.keys(record).length)
  );
  readonly coverResourcesUrls: { [coverResourceId: string]: string } = {};
  readonly initCoverUrls$ = this.textbooks$.pipe(
    map((textbooks) => Object.values(textbooks)),
    map((textbooks) => textbooks.map((textbook) => textbook.coverResourceId)),
    switchMap((coversResourceIds) => this.initCoverResourcesUrls(coversResourceIds))
  );
  showLoader$ = this.navigationService.showLoader$.pipe(subscribeOn(asyncScheduler));
  selectedTextbook!: TextbookMorph;
  isActiveTextbookDetailsModal$ = this.shelfService.isActiveTextbookDetailsModal$;
  isActiveConfirmDownloadModal$ = this.shelfService.isActiveConfirmDownloadModal$;
  isActiveConfirmDeleteModal$ = this.shelfService.isActiveConfirmDeleteModal$;
  isActiveOfflineWarningModal$ = this.shelfService.isActiveOfflineWarningModal$;
  isActiveFinishedOrExpiredModalModal$ = this.shelfService.isActiveFinishedOrExpiredModalModal$;
  isActiveExhaustedDownloadLimitModal$ = this.shelfService.isActiveExhaustedDownloadLimitModal$;
  isActiveNoFreeMemoryModal$ = this.shelfService.isActiveNoFreeMemoryModal$;
  isActiveUpdateTextbookModal$ = this.shelfService.isActiveUpdateTextbookModal$;
  readonly isActiveUpdateTextbookOnDemandModal$ =
    this.shelfService.isActiveUpdateTextbookOnDemandModal$;
  readonly refreshTextbooksSubscription$ = this.user$
    .pipe(
      filter((user) => !!user?.projectionId?.length),
      distinctUntilChanged(
        (prev, curr) =>
          !ProjectionUtil.isProjectionChangePresent(prev.textbookAccesses, curr.textbookAccesses)
      ),
      skip(1)
    )
    .subscribe(() => this.shelfFacade.refreshTextbooks());

  isConnected = this.networkService.isOnlineMode$.pipe(
    tap((connected) => this.isActiveOfflineWarningModal$.next(!connected))
  );

  private async onAppStateChange(isActive: boolean): Promise<void> {
    try {
      if (!isActive) {
        await lastValueFrom(
          this.downloadRegistryFacade
            .getDownloadingTextbook()
            .pipe(
              map((textbook) =>
                textbook
                  ? this.downloadRegistryFacade.stopDownload(textbook.textbookIndex)
                  : undefined
              )
            )
        );
      }
    } catch (e) {
      !environment.production && console.error(e);
    }
  }

  ngOnInit(): void {
    this.shelfFacade.init();
    App.addListener('appStateChange', ({ isActive }) => this.onAppStateChange(isActive));
  }

  ngOnDestroy(): void {
    App.removeAllListeners();
    this.refreshTextbooksSubscription$.unsubscribe();
  }

  onOpenTextbook(textbook: TextbookMorph): void {
    this.isConnected
      .pipe(
        first(),
        combineLatestWith(this.downloadRegistryFacade.getDownloadRegistryUnit(textbook.index)),
        switchMap(([online, unit]) => {
          if (!online && (!unit || unit.status !== DownloadUnitStatus.downloaded)) {
            this.router.navigate(['error', ErrorRoute.OFFLINE]);
            return of(false);
          } else if (this.isAccessExpired(textbook)) {
            this.isActiveFinishedOrExpiredModalModal$.next(true);
            return of(false);
          } else if (unit?.status === DownloadUnitStatus.downloaded) {
            return this.isFullUpdateAvailable$(textbook).pipe(
              tap((fullUpdate) => {
                if (fullUpdate && online) {
                  this.textbookForUpdate = textbook;
                  this.isActiveUpdateTextbookModal$.next(true);
                } else if (fullUpdate && !online) {
                  this.enterTextbook(textbook, true);
                }
              }),
              map((fullUpdate) => !fullUpdate)
            );
          }
          return of(true);
        }),
        filter(Boolean)
      )
      .subscribe(() => this.enterTextbook(textbook, false));
  }

  async onStartDownloadClick(textbook: TextbookMorph): Promise<void> {
    this.selectTextbook(textbook);
    this.isActiveConfirmDownloadModal$.next(true);
  }

  private async validateAndDownload(textbook: TextbookMorph) {
    if (
      BytesToMegabytes(await this.runtimeService.getFreeMemory()) < BytesToMegabytes(textbook.size)
    ) {
      return this.isActiveNoFreeMemoryModal$.next(true);
    }
    const isUpdate = await this.runtimeService.isUpdateRequired(textbook);
    if (isUpdate) {
      this.downloadRegistryFacade.updateTextbookData(textbook);
    } else {
      this.downloadRegistryFacade.startDownload(textbook);
    }
  }

  async onDownloadConfirm(textbook: TextbookMorph): Promise<void> {
    this.shelfService.isActiveConfirmDownloadModal$.next(false);
    return this.validateAndDownload(textbook);
  }

  download(textbook: TextbookMorph): void {
    this.validateAndDownload(textbook);
  }

  onDownloadReject(): void {
    this.shelfService.isActiveConfirmDownloadModal$.next(false);
  }

  onStopDownload(textbook: TextbookMorph): void {
    this.downloadRegistryFacade.stopDownload(textbook.index);
  }

  selectTextbook(textbook: TextbookMorph): void {
    this.selectedTextbook = textbook;
  }

  showDetailsModal(textbook: TextbookMorph): void {
    this.selectTextbook(textbook);
    this.toggleDisplayDetailsModal(true);
  }

  hideDetailsModal(): void {
    this.toggleDisplayDetailsModal(false);
  }

  hideOfflineWarningModal(): void {
    this.shelfService.isActiveOfflineWarningModal$.next(false);
  }

  toggleDisplayDetailsModal(show: boolean): void {
    this.shelfService.isActiveTextbookDetailsModal$.next(show);
  }

  logout(): void {
    this.authFacade.logout();
  }

  deleteReject(): void {
    this.shelfService.isActiveConfirmDeleteModal$.next(false);
  }

  deleteConfirm(textbook: TextbookMorph): void {
    this.downloadRegistryFacade.deleteTextbook(textbook);
    this.shelfService.isActiveConfirmDeleteModal$.next(false);
  }

  onDeleteDownloadClick(textbook: TextbookMorph): void {
    this.selectTextbook(textbook);
    this.shelfService.isActiveConfirmDeleteModal$.next(true);
  }

  toggleExpiredOrFinishedModal(show: boolean): void {
    this.shelfService.isActiveFinishedOrExpiredModalModal$.next(show);
  }

  toggleExhaustedDownloadLimitModal(show: boolean): void {
    this.shelfService.isActiveExhaustedDownloadLimitModal$.next(show);
  }

  onNoFreeMemoryModalExit(): void {
    this.shelfService.isActiveNoFreeMemoryModal$.next(false);
  }

  textbookForUpdate?: TextbookMorph;

  updateTextbookConfirm(confirmationStatus: UpdateUserDataConfirmation): void {
    if (this.textbookForUpdate && confirmationStatus === UpdateUserDataConfirmation.YES) {
      this.downloadRegistryFacade.updateTextbookData(this.textbookForUpdate);
    } else if (this.textbookForUpdate && confirmationStatus === UpdateUserDataConfirmation.NO) {
      this.enterTextbook(this.textbookForUpdate, true);
    } else if (
      this.textbookForUpdate &&
      confirmationStatus === UpdateUserDataConfirmation.OPTIONAL_OPTION
    ) {
      this.enterTextbook(this.textbookForUpdate, false);
    }
    this.shelfService.isActiveUpdateTextbookModal$.next(false);
  }

  updateTextbookOnDemandConfirm(confirmationStatus: UpdateUserDataConfirmation): void {
    if (this.textbookForUpdate && confirmationStatus === UpdateUserDataConfirmation.YES) {
      this.downloadRegistryFacade.updateTextbookData(this.textbookForUpdate);
    }
    this.isActiveUpdateTextbookOnDemandModal$.next(false);
  }

  onUpdateUserData(textbook: TextbookMorph): void {
    this.textbookForUpdate = textbook;
    this.isFullUpdateAvailable$(textbook).subscribe((fullUpdate) =>
      fullUpdate
        ? this.isActiveUpdateTextbookOnDemandModal$.next(true)
        : this.downloadRegistryFacade.updateUserData(textbook)
    );
  }

  private isFullUpdateAvailable$(textbook: TextbookMorph): Observable<boolean> {
    return this.downloadRegistryFacade
      .getDownloadRegistryUnit(textbook.index)
      .pipe(map((unit) => TextbookStatusUtil.isFullUpdateAvailable(unit, textbook)));
  }

  private enterTextbook(textbook: TextbookMorph, useDownloadedVersionOffline: boolean): void {
    this.shelfFacade.exit();
    this.textbooksFacade.onEnterTextbook(textbook, !!useDownloadedVersionOffline);
  }

  private isAccessExpired(textbook: TextbookMorph): boolean {
    return (
      textbook.access.accessStatus === AccessStatus.Finished ||
      dayjs(textbook.access.endDate).isBefore(dayjs())
    );
  }

  private initCoverResourcesUrls(coversResourceIds: string[]): Observable<void> {
    const resourceIds = new Set(coversResourceIds);
    return from(resourceIds).pipe(
      filter((resourceId) => !this.coverResourcesUrls[resourceId]),
      concatMap((resourceId) =>
        this.fetchService
          .getResource(resourceId)
          .pipe(tap((url) => (this.coverResourcesUrls[resourceId] = url)))
      ),
      toArray(),
      mapTo(void 0)
    );
  }

  private textbookMorphCompareFn = (a: TextbookMorph, b: TextbookMorph) => {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return a.access.order - b.access.order;
  };
}
