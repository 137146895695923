import { Component, EventEmitter, Input, Output } from '@angular/core';

export enum UpdateUserDataConfirmation {
  YES = 'YES',
  NO = 'NO',
  CANCEL = 'CANCEL',
  OPTIONAL_OPTION = 'OPTIONAL_OPTION',
}

@Component({
  selector: 'app-update-user-data-modal',
  templateUrl: './update-user-data-modal.component.html',
  styleUrls: ['./update-user-data-modal.component.scss'],
})
export class UpdateUserDataModalComponent {
  @Input() declineLabel = 'Nie';
  @Input() optionalOtion?: string;
  @Output() exit = new EventEmitter<UpdateUserDataConfirmation>();
  readonly UpdateUserDataConfirmation = UpdateUserDataConfirmation;

  onExit(status = UpdateUserDataConfirmation.CANCEL): void {
    this.exit.emit(status);
  }
}
