import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertSize',
})
export class ConvertSizePipe implements PipeTransform {
  transform(value = 0): string {
    if (value === 0) {
      return `0 Bytes`;
    }
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const kilobyte = 1024;
    const exponent = Math.floor(Math.log(value) / Math.log(kilobyte));
    const fractionDigits = sizes.indexOf('MB') < exponent ? 2 : 0;
    return `${parseFloat((value / Math.pow(kilobyte, exponent)).toFixed(fractionDigits))} ${
      sizes[exponent]
    }`;
  }
}
