import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';
import { CoreRoute } from '@app/core/constants';

@Component({
  selector: 'app-legacy-infographic-redirect',
  template: '',
})
export class LegacyInfographicRedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const { fragment } = this.route.snapshot;
    const mhbId = fragment?.match(/^\/publication\/0\/(\d+)\//)?.[1];
    window.location.href = mhbId
      ? `${environment.launcherApiUrl}/run/${CoreRoute.Infographics}/${mhbId}`
      : environment.loginGwoUrl;
  }
}
