import { AppLockMessage, MessageTypeEnum } from '@gwo/textbook-api-client';
import { isMessageMeta } from '@app/core/models/type-guards/is-message-meta';

export function isAppLockMessage(message: any): message is AppLockMessage {
  const lockMessage = message as AppLockMessage | undefined;
  return (
    MessageTypeEnum.LOCK === lockMessage?.type &&
    !!lockMessage?.id &&
    isMessageMeta(lockMessage?.meta)
  );
}
