import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-no-free-memory-modal',
  templateUrl: './no-free-memory-modal.component.html',
  styleUrls: ['./no-free-memory-modal.component.scss'],
})
export class NoFreeMemoryModalComponent {
  @Output() exit = new EventEmitter<void>();

  close(): void {
    this.exit.emit();
  }
}
