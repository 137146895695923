import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreRouteParam } from '@app/core/constants';
import { ErrorLogComponent, NoAuthComponent, OfflineErrorComponent } from './components';
import { ErrorRoute } from './constants/routes';
import { DemoInactiveErrorComponent } from '@app/errors/components/demo-inactive-error/demo-inactive-error.component';

const routes: Routes = [
  {
    path: ErrorRoute.OFFLINE,
    component: OfflineErrorComponent,
  },
  {
    path: ErrorRoute.NO_AUTH,
    component: NoAuthComponent,
  },
  {
    path: ErrorRoute.LOG,
    component: ErrorLogComponent,
  },
  {
    path: `${ErrorRoute.INACTIVE_TEXTBOOK_DEMO}/:${CoreRouteParam.TextbookId}`,
    component: DemoInactiveErrorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ErrorsRoutingModule {}
