import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { containers } from './containers';
import { components } from './components';
import { ShelfRoutingModule } from './shelf-routing.module';
import { services } from './services';
import { modifications } from './modifications';
import { ShelfStoreModule } from './store/shelf-store.module';
import { facades } from './store/facade';

@NgModule({
  declarations: [...containers, ...components],
  imports: [SharedModule, ShelfRoutingModule, ShelfStoreModule],
  exports: [],
  providers: [...services, ...modifications, ...facades],
})
export class ShelfModule {}
