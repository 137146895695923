import { Injectable, NgZone } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { first, map, share } from 'rxjs/operators';
import { ConnectionStatus, Network } from '@capacitor/network';

@Injectable()
export class NetworkService {
  constructor(private ngZone: NgZone) {
    Network.addListener('networkStatusChange', this.updateNetworkStatus.bind(this));
    Network.getStatus().then(this.updateNetworkStatus.bind(this));
  }

  private updateNetworkStatus(status: ConnectionStatus): void {
    this.ngZone.run(() => this.networkStatusSubject.next(status));
  }

  private networkStatusSubject = new ReplaySubject<ConnectionStatus>(1);

  networkStatus$ = this.networkStatusSubject.asObservable();

  isOnlineMode$ = this.networkStatus$.pipe(map(({ connected }) => connected));
  isOnlineMode = this.isOnlineMode$.pipe(first(), share());
}
