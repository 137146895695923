import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, first, map, mapTo, switchMap, toArray, withLatestFrom } from 'rxjs/operators';
import { RuntimeService } from '@app/shelf/services';
import { NetworkService } from '@app/shared/services/network.service';
import { User } from '@gwo/textbook-api-client/lib/interface/user.model';
import { Textbook, TextbookClient, TextbookClientEndpointEnum } from '@gwo/textbook-api-client';
import {
  DownloadRegistryFacade,
  SelectedTextbookFacade,
  TextbooksFacade,
} from '@app/shelf/store/facade';
import { AccessLevel } from '@gwo/textbook-api-client/lib/interface/access-level.model';
import { Drawing } from '@gwo/textbook-api-client/lib/interface/drawing.model';
import { PageNote } from '@gwo/textbook-api-client/lib/interface/page-note.model';
import { Marker } from '@gwo/textbook-api-client/lib/interface/marker.model';
import { ActionNote } from '@gwo/textbook-api-client/lib/interface/action-note.model';
import { TextbookAccess } from '@gwo/textbook-api-client/lib/interface/textbook-access.model';
import { SelectedTextbook } from '@app/shelf/store/reducers/selected-textbook';
import {
  DownloadRegistry,
  DownloadRegistryUnit,
  DownloadUnitStatus,
} from '@app/shelf/store/reducers';
import {
  ACCESS_ID_QUERY_PARAM,
  DOWNLOAD_IN_BACKGROUND_ENDPOINTS,
  MUTABLE_USER_CONTENT_ENDPOINTS,
  MUTATION_ENDPOINTS,
  OFFLINE_CONTENT_ENDPOINTS,
  OfflineContentUtil,
  PROJECTION_ENDPOINTS,
} from '@app/core/utils/offline-content.util';
import HTTPMethod from 'http-method-enum';
import { environment } from '@environments/environment';
import { URL_PARTS_SEPARATOR } from '@app/textbook/utils/url-manipulation-util';

type PartialActionNote = Omit<ActionNote, 'id'>;
type PartialDrawing = Pick<Drawing, 'actionId' | 'content'>;

type accessId = Pick<TextbookAccess, 'accessId'>;
type ActionNoteErrorBody = PartialActionNote & accessId;
type DrawingErrorBody = PartialDrawing & accessId;

@Injectable()
export class OfflineInterceptor implements HttpInterceptor {
  constructor(
    private readonly selectedTextbookFacade: SelectedTextbookFacade,
    private readonly runtimeService: RuntimeService,
    private readonly networkService: NetworkService,
    private readonly textbookClient: TextbookClient,
    private readonly textbooksFacade: TextbooksFacade,
    private readonly downloadRegistryFacade: DownloadRegistryFacade
  ) {}

  private supplyHttpResponse(data: unknown): HttpResponse<unknown> {
    return new HttpResponse({ status: 200, body: { data } });
  }

  private supplyHttpResponseWithBlob(blob: unknown): HttpResponse<unknown> {
    return new HttpResponse({ status: 200, body: blob });
  }

  private getTextbookId(url: string): string {
    /* eslint-disable */
    const regexp = /textbooks\/([a-z0-9\-]+)\/{0,}/.exec(url);
    return regexp ? regexp[1] : '';
  }

  private getResourceId(url: string): string {
    /* eslint-disable */
    const regexp = /resources\/([a-zA-Z0-9\-]+)/.exec(url);
    return regexp ? regexp[1] : '';
  }

  private getActionId(url: string): string {
    /* eslint-disable */
    const regexp = /textbook-actions\/([a-zA-Z0-9\-]+)/.exec(url);
    return regexp ? regexp[1] : '';
  }

  private getActionIdFromLocation(url: string = location.href): string {
    /* eslint-disable */
    const regexp = /action\/([a-zA-Z0-9\-]+)/.exec(url);
    return regexp ? regexp[1] : '';
  }

  private getResponseWithDataFromReader<T>(
    reader: Promise<T> | Observable<T>,
    supplier = this.supplyHttpResponse
  ): Observable<HttpResponse<unknown>> {
    if ('pipe' in reader) {
      return reader.pipe(map(supplier.bind(this)));
    }
    return from(reader).pipe(map(supplier.bind(this)));
  }

  private interceptResource(req: HttpRequest<unknown>): Observable<HttpResponse<unknown>> {
    const id = this.getTextbookId(location.href);
    return this.getResponseWithDataFromReader(
      id === ''
        ? this.runtimeService.readTemporaryResource(this.getResourceId(req.url))
        : this.runtimeService.readResource(id, this.getResourceId(req.url)),
      this.supplyHttpResponseWithBlob
    );
  }

  private interceptUserData(): Observable<HttpResponse<unknown>> {
    return this.getResponseWithDataFromReader(this.runtimeService.readUserPayload());
  }

  private interceptPages(req: HttpRequest<unknown>): Observable<HttpResponse<unknown>> {
    return this.getResponseWithDataFromReader(
      this.runtimeService.readTextbookPages(this.getTextbookId(req.url))
    );
  }

  private interceptTextualPages(req: HttpRequest<unknown>): Observable<HttpResponse<unknown>> {
    return this.getResponseWithDataFromReader(
      this.runtimeService.readTextbookTextualPages(this.getTextbookId(req.url))
    );
  }

  private interceptMarkers(req: HttpRequest<unknown>): Observable<HttpResponse<unknown>> {
    return this.getResponseWithDataFromReader(
      of([])
      // FIXME Do przywrócenia po implementacji aktualizacji user contentu
      // this.runtimeService.readUserMarkers(this.getTextbookId(req.url))
    );
  }

  private interceptNotes(req: HttpRequest<unknown>): Observable<HttpResponse<unknown>> {
    return this.getResponseWithDataFromReader(
      of([])
      // FIXME Do przywrócenia po implementacji aktualizacji user contentu
      // this.runtimeService.readUserNotes(this.getTextbookId(req.url))
    );
  }

  private interceptTextbooks(req: HttpRequest<unknown>): Observable<HttpResponse<unknown>> {
    return this.getResponseWithDataFromReader(this.runtimeService.readTextbooksPayload()).pipe(
      map((payload) => {
        if (OfflineContentUtil.isMatching(req, TextbookClientEndpointEnum.GET_TEXTBOOK)) {
          const data = (payload.body as { data: Textbook[] }).data.find(
            (textbook) => textbook.id === this.getTextbookId(req.url)
          );
          return payload.clone({ body: { data } });
        }
        return payload;
      })
    );
  }

  private interceptActionTriggers(req: HttpRequest<unknown>): Observable<HttpResponse<unknown>> {
    return this.selectedTextbookFacade
      .getSelectedTextbook()
      .pipe(
        switchMap((unit) =>
          this.getResponseWithDataFromReader(
            this.runtimeService.readTextbookActionTriggers(
              this.getTextbookId(req.url),
              unit?.textbookAccessLevel as AccessLevel
            )
          )
        )
      );
  }

  private interceptLegend(req: HttpRequest<unknown>): Observable<HttpResponse<unknown>> {
    return this.selectedTextbookFacade
      .getSelectedTextbook()
      .pipe(
        switchMap((unit) =>
          this.getResponseWithDataFromReader(
            this.runtimeService.readTextbookLegend(
              this.getTextbookId(req.url),
              unit?.textbookAccessLevel as AccessLevel
            )
          )
        )
      );
  }

  private interceptAction(req: HttpRequest<unknown>): Observable<HttpResponse<unknown>> {
    const actionId = this.getActionId(req.url);

    return this.selectedTextbookFacade
      .getSelectedTextbook()
      .pipe(
        switchMap((unit) =>
          this.getResponseWithDataFromReader(
            this.runtimeService.readTextbookAction(unit?.textbookId as string, actionId as string)
          )
        )
      );
  }

  private interceptDrawings(req: HttpRequest<unknown>): Observable<HttpResponse<unknown>> {
    return this.selectedTextbookFacade.getSelectedTextbook().pipe(
      switchMap((unit) =>
        this.getResponseWithDataFromReader(
          of(null)
          // FIXME Do przywrócenia po implementacji aktualizacji user contentu
          // this.runtimeService.readUserActionDrawing(
          //   unit?.textbookId as string,
          //   this.getActionId(req.url)
          // )
        )
      )
    );
  }

  private interceptActionNotes(req: HttpRequest<unknown>): Observable<HttpResponse<unknown>> {
    return this.selectedTextbookFacade.getSelectedTextbook().pipe(
      switchMap((unit) =>
        this.getResponseWithDataFromReader(
          of(null)
          // FIXME Do przywrócenia po implementacji aktualizacji user contentu
          // this.runtimeService.readUserActionNote(
          //   unit?.textbookId as string,
          //   this.getActionId(req.url)
          // )
        )
      )
    );
  }

  private interceptGroups(req: HttpRequest<unknown>): Observable<HttpResponse<unknown>> {
    return this.selectedTextbookFacade
      .getSelectedTextbook()
      .pipe(
        switchMap((unit) =>
          this.getResponseWithDataFromReader(
            this.runtimeService.readTextbookActionGroups(
              this.getTextbookId(req.url),
              unit?.textbookAccessLevel as AccessLevel
            )
          )
        )
      );
  }

  private extractMarkerId(url: string): string {
    /* eslint-disable */
    const regexp = /\/markers\/([a-zA-Z0-9\-]+)/.exec(url);
    return regexp ? regexp[1] : '';
  }

  private mutateCreateMarker(textbookId: string, marker: Marker): Observable<void> {
    return from(this.runtimeService.readUserMarkers(textbookId)).pipe(
      switchMap((markers) => {
        markers.push(marker);
        return this.runtimeService.writeUserMarkers(textbookId, markers);
      })
    );
  }

  private mutateDeleteMarker(textbookId: string, id: string): Observable<void> {
    return from(this.runtimeService.readUserMarkers(textbookId)).pipe(
      switchMap((markers) => {
        const index = markers.findIndex((marker) => marker.id === id);
        if (index !== -1) {
          markers.splice(index, 1);
        }
        return this.runtimeService.writeUserMarkers(textbookId, markers);
      })
    );
  }

  private extractNoteId(url: string): string {
    /* eslint-disable */
    const regexp = /\/notes\/([a-zA-Z0-9\-]+)/.exec(url);
    return regexp ? regexp[1] : '';
  }

  private mutateCreateNote(textbookId: string, note: PageNote): Observable<void> {
    return from(this.runtimeService.readUserNotes(textbookId)).pipe(
      switchMap((notes) => {
        notes.push(note);
        return this.runtimeService.writeUserNotes(textbookId, notes);
      })
    );
  }

  private mutatePatchNote(textbookId: string, note: PageNote): Observable<void> {
    return from(this.runtimeService.readUserNotes(textbookId)).pipe(
      switchMap((notes) => {
        const index = notes.findIndex((note_) => note.id === note_.id);
        if (index !== -1) {
          notes.splice(index, 1, note);
        }
        return this.runtimeService.writeUserNotes(textbookId, notes);
      })
    );
  }

  private mutateDeleteNote(textbookId: string, id: string): Observable<void> {
    return from(this.runtimeService.readUserNotes(textbookId)).pipe(
      switchMap((notes) => {
        const index = notes.findIndex((note) => note.id === id);
        if (index !== -1) {
          notes.splice(index, 1);
        }
        return this.runtimeService.writeUserNotes(textbookId, notes);
      })
    );
  }

  private mutatePostDrawings(
    textbookId: string,
    actionId: string,
    drawing: Drawing
  ): Observable<void> {
    return from(this.runtimeService.writeUserActionDrawing(textbookId, actionId, drawing));
  }

  private mutatePatchDrawings(
    textbookId: string,
    actionId: string,
    drawing: Drawing
  ): Observable<void> {
    return this.mutatePostDrawings(textbookId, actionId, drawing);
  }

  private mutateDeleteDrawings(textbookId: string, actionId: string): Observable<void> {
    return from(this.runtimeService.deleteUserActionDrawing(textbookId, actionId));
  }

  private mutatePostActionNotes(
    textbookId: string,
    actionId: string,
    note: ActionNote
  ): Observable<void> {
    return from(this.runtimeService.writeUserActionNote(textbookId, actionId, note));
  }

  private mutatePatchActionNotes(
    textbookId: string,
    actionId: string,
    note: ActionNote
  ): Observable<void> {
    return this.mutatePostActionNotes(textbookId, actionId, note);
  }

  private mutateDeleteActionNotes(textbookId: string, actionId: string): Observable<void> {
    return from(this.runtimeService.deleteUserActionNote(textbookId, actionId));
  }

  private updateActionNote(reqBody: ActionNoteErrorBody): Observable<ActionNote> {
    const { actionId, accessId, notes } = reqBody;
    return this.textbookClient
      .getActionNote$(actionId)
      .pipe(switchMap(({ id }) => this.textbookClient.updateActionNote$(id, accessId, notes)));
  }

  private updateDrawing(reqBody: DrawingErrorBody): Observable<Drawing> {
    const { actionId, accessId, content } = reqBody;
    return this.textbookClient
      .getActionDrawing$(actionId)
      .pipe(switchMap(({ id }) => this.textbookClient.updateDrawing$(id, accessId, content)));
  }

  private interceptMutationError(
    err: HttpResponse<unknown> | any,
    req: HttpRequest<unknown>
  ): Observable<HttpEvent<unknown>> {
    const response = new HttpResponse({ status: 200 });
    return this.selectedTextbookFacade.getSelectedTextbook().pipe(
      switchMap((unit) => {
        if (
          err.status === 409 &&
          OfflineContentUtil.isMatching(req, MUTATION_ENDPOINTS.actionNotesPost)
        ) {
          return this.updateActionNote(req.body as ActionNoteErrorBody).pipe(
            map((actionNote) => new HttpResponse({ status: 200, body: { data: actionNote } }))
          );
        }
        if (
          err.status === 409 &&
          OfflineContentUtil.isMatching(req, MUTATION_ENDPOINTS.drawingsPost)
        ) {
          return this.updateDrawing(req.body as DrawingErrorBody).pipe(
            map((drawing) => new HttpResponse({ status: 200, body: { data: drawing } }))
          );
        }

        if (
          err.status === 409 &&
          req.url.includes('action-notes') &&
          err?.error?.errors[0]?.title === 'Conflict'
        ) {
          return EMPTY;
        }
        if (!unit) return of(response);
        if (
          err.status === 404 &&
          OfflineContentUtil.isMatching(req, MUTATION_ENDPOINTS.markerDelete)
        ) {
          return this.mutateDeleteMarker(unit.textbookId, this.extractMarkerId(req.url)).pipe(
            mapTo(response)
          );
        } else if (
          err.status === 404 &&
          OfflineContentUtil.isMatching(req, MUTATION_ENDPOINTS.noteDelete)
        ) {
          return this.mutateDeleteNote(unit.textbookId, this.extractNoteId(req.url)).pipe(
            mapTo(response)
          );
        } else if (
          err.status === 404 &&
          OfflineContentUtil.isMatching(req, MUTATION_ENDPOINTS.notePatch)
        ) {
          const { distanceFromLeft, distanceFromTop, content } = req.body as PageNote;
          const id = this.extractNoteId(req.url);
          return from(this.runtimeService.readUserNotes(unit.textbookId)).pipe(
            switchMap((notes) => {
              const note = notes.find((note) => note.id === id) as PageNote;
              return this.textbookClient.createNote$(
                unit.textbookAccessId,
                note.pageId,
                distanceFromTop,
                distanceFromLeft,
                content
              );
            }),
            switchMap((note) => this.mutateDeleteNote(unit.textbookId, id).pipe(mapTo(note))),
            map((note) => new HttpResponse({ status: 200, body: { data: note } }))
          );
        }
        return of(response);
      })
    );
  }

  private interceptMutation(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const intercept = (ev: HttpEvent<unknown>) => {
      if (ev.type !== HttpEventType.Response) {
        return of(ev);
      }
      return this.selectedTextbookFacade.getSelectedTextbook().pipe(
        switchMap((unit) => {
          if (!unit) return of(ev);
          if (OfflineContentUtil.isMatching(req, MUTATION_ENDPOINTS.markerPost)) {
            const { data } = ev.body as { data: Marker };
            return this.mutateCreateMarker(unit.textbookId, data).pipe(mapTo(ev));
          } else if (OfflineContentUtil.isMatching(req, MUTATION_ENDPOINTS.markerDelete)) {
            return this.mutateDeleteMarker(unit.textbookId, this.extractMarkerId(req.url)).pipe(
              mapTo(ev)
            );
          } else if (OfflineContentUtil.isMatching(req, MUTATION_ENDPOINTS.notePost)) {
            const { data } = ev.body as { data: PageNote };
            return this.mutateCreateNote(unit.textbookId, data).pipe(mapTo(ev));
          } else if (OfflineContentUtil.isMatching(req, MUTATION_ENDPOINTS.noteDelete)) {
            return this.mutateDeleteNote(unit.textbookId, this.extractNoteId(req.url)).pipe(
              mapTo(ev)
            );
          } else if (OfflineContentUtil.isMatching(req, MUTATION_ENDPOINTS.notePatch)) {
            const { data } = ev.body as { data: PageNote };
            return this.mutatePatchNote(unit.textbookId, data).pipe(mapTo(ev));
          } else if (OfflineContentUtil.isMatching(req, MUTATION_ENDPOINTS.drawingsPost)) {
            const { data } = ev.body as { data: Drawing };
            return this.mutatePostDrawings(data.textbookId, data.actionId, data).pipe(mapTo(ev));
          } else if (OfflineContentUtil.isMatching(req, MUTATION_ENDPOINTS.drawingsPatch)) {
            const { data } = ev.body as { data: Drawing };
            return this.mutatePatchDrawings(data.textbookId, data.actionId, data).pipe(mapTo(ev));
          } else if (OfflineContentUtil.isMatching(req, MUTATION_ENDPOINTS.drawingsDelete)) {
            return this.mutateDeleteDrawings(unit.textbookId, this.getActionIdFromLocation()).pipe(
              mapTo(ev)
            );
          } else if (OfflineContentUtil.isMatching(req, MUTATION_ENDPOINTS.actionNotesPost)) {
            const { data } = ev.body as { data: ActionNote };
            return this.mutatePostActionNotes(unit.textbookId, data.actionId, data).pipe(mapTo(ev));
          } else if (OfflineContentUtil.isMatching(req, MUTATION_ENDPOINTS.actionNotesPatch)) {
            const { data } = ev.body as { data: ActionNote };
            return this.mutatePatchActionNotes(unit.textbookId, data.actionId, data).pipe(
              mapTo(ev)
            );
          } else if (OfflineContentUtil.isMatching(req, MUTATION_ENDPOINTS.actionNotesDelete)) {
            return this.mutateDeleteActionNotes(
              unit.textbookId,
              this.getActionIdFromLocation()
            ).pipe(mapTo(ev));
          }
          return of(ev);
        })
      );
    };
    return next.handle(req).pipe(
      catchError((err) => this.interceptMutationError(err as HttpResponse<unknown>, req)),
      switchMap((ev) => intercept(ev))
    ) as Observable<HttpEvent<unknown>>;
  }

  private offlineIntercept(req: HttpRequest<unknown>): Observable<HttpResponse<unknown>> {
    if (OfflineContentUtil.isAnyMatching(req, OFFLINE_CONTENT_ENDPOINTS.resource)) {
      return this.interceptResource(req);
    } else if (OfflineContentUtil.isAnyMatching(req, OFFLINE_CONTENT_ENDPOINTS.drawings)) {
      return this.interceptDrawings(req);
    } else if (OfflineContentUtil.isAnyMatching(req, OFFLINE_CONTENT_ENDPOINTS.actionNotes)) {
      return this.interceptActionNotes(req);
    } else if (OfflineContentUtil.isAnyMatching(req, OFFLINE_CONTENT_ENDPOINTS.users)) {
      return this.interceptUserData();
    } else if (OfflineContentUtil.isAnyMatching(req, OFFLINE_CONTENT_ENDPOINTS.textualPages)) {
      return this.interceptTextualPages(req);
    } else if (OfflineContentUtil.isAnyMatching(req, OFFLINE_CONTENT_ENDPOINTS.pages)) {
      return this.interceptPages(req);
    } else if (OfflineContentUtil.isAnyMatching(req, OFFLINE_CONTENT_ENDPOINTS.markers)) {
      return this.interceptMarkers(req);
    } else if (OfflineContentUtil.isAnyMatching(req, OFFLINE_CONTENT_ENDPOINTS.notes)) {
      return this.interceptNotes(req);
    } else if (OfflineContentUtil.isAnyMatching(req, OFFLINE_CONTENT_ENDPOINTS.actionTriggers)) {
      return this.interceptActionTriggers(req);
    } else if (OfflineContentUtil.isAnyMatching(req, OFFLINE_CONTENT_ENDPOINTS.legend)) {
      return this.interceptLegend(req);
    } else if (OfflineContentUtil.isAnyMatching(req, OFFLINE_CONTENT_ENDPOINTS.textbooks)) {
      return this.interceptTextbooks(req);
    } else if (OfflineContentUtil.isAnyMatching(req, OFFLINE_CONTENT_ENDPOINTS.action)) {
      return this.interceptAction(req);
    } else if (OfflineContentUtil.isAnyMatching(req, OFFLINE_CONTENT_ENDPOINTS.groups)) {
      return this.interceptGroups(req);
    }
    return of(new HttpResponse({ status: 200 }));
  }

  private onlineIntercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const intercept = (response: HttpEvent<unknown>) => {
      if (response.type !== HttpEventType.Response) {
        return of(response);
      }
      const userDataEndpoints = [
        TextbookClientEndpointEnum.RECREATE_AUTHENTICATED_USER,
        TextbookClientEndpointEnum.GET_AUTHENTICATED_USER,
      ];
      const isUserDataRequest = OfflineContentUtil.isAnyMatching(req, userDataEndpoints);
      if (isUserDataRequest) {
        const payload = response.body as { data: User };
        return from(this.runtimeService.writeUserPayload(payload.data)).pipe(mapTo(response));
      } else if (OfflineContentUtil.isMatching(req, TextbookClientEndpointEnum.GET_TEXTBOOKS)) {
        const payload = response.body as { data: Textbook[] };
        const accessPayload = payload.data.flatMap(($) => $.accesses);
        return from(this.runtimeService.writeTextbooksPayload(payload.data)).pipe(
          switchMap(() => this.downloadRegistryFacade.getDownloadRegistry()),
          switchMap((registry) => from(Object.values(registry))),
          switchMap((unit) => {
            const newUnit = accessPayload.find(($) => $.accessId === unit.textbookAccessId);
            if (newUnit?.endDate && new Date(newUnit.endDate) !== unit.expireDate) {
              unit = JSON.parse(JSON.stringify(unit));
              unit.expireDate = new Date(newUnit.endDate);
              return this.runtimeService.writeTextbookMetaAccessScope(
                unit.textbookId,
                unit.textbookIndex,
                unit
              );
            }
            return of(undefined);
          }),
          toArray(),
          mapTo(response)
        );
      } else if (
        OfflineContentUtil.isMatching(req, TextbookClientEndpointEnum.GET_RESOURCE) &&
        !req.url.includes('imageSize')
      ) {
        const resourceId = req.url.split(URL_PARTS_SEPARATOR).pop() as string;
        const writePipe = from(this.runtimeService.readTemporaryResource(resourceId)).pipe(
          switchMap((blob) =>
            blob
              ? of(blob)
              : this.runtimeService.writeTemporaryResource(resourceId, response.body as Blob)
          )
        );
        return this.textbooksFacade.getTextbooks().pipe(
          first(),
          switchMap((textbooks) => {
            if (
              Object.values(textbooks).some((textbook) => textbook.coverResourceId === resourceId)
            ) {
              return writePipe;
            }
            return of(undefined);
          }),
          mapTo(response)
        );
      }
      return of(response);
    };
    return next.handle(req).pipe(switchMap((ev) => intercept(ev))) as Observable<
      HttpEvent<unknown>
    >;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const onlineIntercept = this.selectedTextbookFacade.getSelectedTextbook().pipe(
      switchMap(() => {
        if (
          request.method !== HTTPMethod.GET &&
          OfflineContentUtil.isAnyMatching(request, Object.values(MUTATION_ENDPOINTS))
        ) {
          return this.interceptMutation(request, next);
        }
        return this.onlineIntercept(request, next);
      })
    );
    return this.networkService.isOnlineMode.pipe(
      withLatestFrom(
        this.downloadRegistryFacade.getDownloadRegistry(),
        this.selectedTextbookFacade.getSelectedTextbook(),
        this.selectedTextbookFacade.isOutdatedTextbookSelected(),
        this.downloadRegistryFacade.getDownloadingTextbook()
      ),
      switchMap(
        ([connected, downloadRegistry, selectedTextbook, isOutdated, downloadingTextbook]) => {
          if (
            !environment.appMode &&
            !this.isTextbookDownloaded(selectedTextbook, downloadRegistry)
          ) {
            return onlineIntercept;
          }
          return !connected ||
            (environment.appMode &&
              this.isTextbookDownloaded(selectedTextbook, downloadRegistry) &&
              this.isOfflineInterceptionPossible(request, isOutdated) &&
              !this.isDownloadInBackground(request, downloadingTextbook, selectedTextbook))
            ? this.offlineIntercept(request)
            : onlineIntercept;
        }
      )
    );
  }

  private isDownloadInBackground(
    request: HttpRequest<unknown>,
    backgroundDownload: DownloadRegistryUnit | null,
    currentTextbook?: SelectedTextbook
  ): boolean {
    if (!!backgroundDownload?.textbookId && !!backgroundDownload?.textbookAccessId) {
      const downloadInBackgroundEndpoint = OfflineContentUtil.isAnyMatching(
        request,
        DOWNLOAD_IN_BACKGROUND_ENDPOINTS
      );
      const requestUrl = request.urlWithParams;
      const accessIdParam = `${ACCESS_ID_QUERY_PARAM}=${backgroundDownload.textbookAccessId}`;
      const accessCheckRequired = backgroundDownload.textbookId === currentTextbook?.textbookId;
      return (
        downloadInBackgroundEndpoint &&
        (accessCheckRequired
          ? requestUrl.includes(accessIdParam)
          : requestUrl.includes(backgroundDownload.textbookId))
      );
    }
    return false;
  }

  private isTextbookDownloaded(
    currentTextbook: SelectedTextbook,
    downloadRegistry: DownloadRegistry
  ): boolean {
    if (!!currentTextbook?.textbookAccessId && !!downloadRegistry) {
      const textbookRegistryUnit = downloadRegistry[currentTextbook.textbookAccessId];
      return (
        !!textbookRegistryUnit &&
        textbookRegistryUnit.version === currentTextbook.version &&
        textbookRegistryUnit.status === DownloadUnitStatus.downloaded
      );
    }
    return false;
  }

  private isOfflineInterceptionPossible(
    request: HttpRequest<unknown>,
    isOutdated: boolean
  ): boolean {
    const userContentRequest = OfflineContentUtil.isAnyMatching(
      request,
      MUTABLE_USER_CONTENT_ENDPOINTS
    );
    const projectionRequest = OfflineContentUtil.isAnyMatching(request, PROJECTION_ENDPOINTS);
    const contentDownloadable = Object.values(OFFLINE_CONTENT_ENDPOINTS).some((endpoints) =>
      OfflineContentUtil.isAnyMatching(request, endpoints)
    );
    return (
      request.method === HTTPMethod.GET &&
      contentDownloadable &&
      !projectionRequest &&
      (isOutdated || !userContentRequest)
    );
  }
}
