import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-full-screen-modal',
  templateUrl: './full-screen-modal.component.html',
  styleUrls: ['./full-screen-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullScreenModalComponent {
  @Input() @HostBinding('style.z-index') zIndex = 50;
}
