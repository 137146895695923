import { AppWarningMeta, MessagePeriodicityEnum } from '@gwo/textbook-api-client';
import { isMessageMeta } from '@app/core/models/type-guards/is-message-meta';

export function isAppWarningMeta(meta: any): meta is AppWarningMeta {
  const warningMeta = meta as AppWarningMeta | undefined;
  return (
    isMessageMeta(meta) &&
    !!warningMeta?.startTime24H?.length &&
    !!warningMeta?.endTime24H?.length &&
    isLockAppInfo(warningMeta?.lockAppInfo) &&
    Object.values(MessagePeriodicityEnum).includes(warningMeta?.periodicity)
  );
}

export function isLockAppInfo(lockAppInfo: any): lockAppInfo is AppWarningMeta['lockAppInfo'] {
  return (
    lockAppInfo === null ||
    (!isNaN(new Date(lockAppInfo.lockDate)?.getTime()) && isMessageMeta(lockAppInfo?.message))
  );
}
