import { ActionReducerMap } from '@ngrx/store';
import {
  DownloadRegistry,
  downloadRegistryFeatureKey,
  downloadRegistryReducer,
} from './download-registry.reducer';
import {
  SelectedTextbookState,
  selectedTextbookFeatureKey,
  selectedTextbookReducer,
} from './selected-textbook';
import { textbookFeatureKey, textbookReducer, TextbooksState } from './textbooks.reducer';

export * from './textbooks.reducer';
export * from './download-registry.reducer';

export interface ShelfState {
  [textbookFeatureKey]: TextbooksState;
  [downloadRegistryFeatureKey]: DownloadRegistry;
  [selectedTextbookFeatureKey]: SelectedTextbookState;
}

export const reducers: ActionReducerMap<ShelfState> = {
  [textbookFeatureKey]: textbookReducer,
  [downloadRegistryFeatureKey]: downloadRegistryReducer,
  [selectedTextbookFeatureKey]: selectedTextbookReducer,
};
