function json(name: string): string {
  return `${name}.json`;
}

export const PATH_DELIMITER = '/';

export const ROOT_DIR = 'GWO_DATA';
export const RESOURCES_DIR = 'RESOURCES';
export const TEXTBOOKS_DIR = 'TEXTBOOKS';
export const USERS_DIR = 'USERS';
export const TEMPORARY_DIR = 'TMP';
export const TEXTBOOK_ACTIONS_DIR = 'ACTIONS';

export const ERROR_LOGS_DIR = 'ERROR_LOGS';

export const TEXTBOOK_META_FILE = json('META');
export const TEXTBOOK_PAGES_FILE = json('PAGES');
export const TEXTBOOK_TEXTUAL_PAGES_FILE = json('TEXTUAL_PAGES');

export const USER_ACTION_DRAWINGS_DIR = 'ACTION_DRAWINGS';
export const USER_ACTION_NOTES_DIR = 'ACTION_NOTES';

export const TEXTBOOK_ACTIONS_TRIGGERS_FILE = json('ACTIONS_TRIGGERS');
export const TEXTBOOK_ACTIONS_GROUPS_FILE = json('ACTIONS_GROUPS');
export const TEXTBOOK_LEGEND_FILE = json('LEGEND');

export const USER_NOTES_FILE = json('NOTES');
export const USER_MARKERS_FILE = json('MARKERS');
export const USER_TASKS_FILE = json('TASKS');
export const TEXTBOOK_ACTIONS_FILE = json('ACTIONS');

export const TEXTBOOKS_PAYLOAD = json('TEXTBOOKS_PAYLOAD');
export const USER_PAYLOAD = json('USER_PAYLOAD');

export const DATA_CLEANER_META = json('DATA_CLEANER');
