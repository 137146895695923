import { Injectable } from '@angular/core';
import { Textbook, TextbookAction, TextbookClient, TextbookPage } from '@gwo/textbook-api-client';
import { Observable, of } from 'rxjs';
import { TextbookLegend } from '@gwo/textbook-api-client/lib/interface/textbook-legend.model';
import { ImageSize } from '@gwo/textbook-api-client/lib/interface/image-size';
import { TextbookActionGroup } from '@gwo/textbook-api-client/lib/interface/textbook-action-group.model';
import { Drawing } from '@gwo/textbook-api-client/lib/interface/drawing.model';
import { TextbookManagerApi } from '@app/core/providers/textbook-manager.provider';
import { TextbookTextualPage } from '@gwo/textbook-api-client/lib/interface/textbook-textual-page.model';
import { PageNote } from '@gwo/textbook-api-client/lib/interface/page-note.model';
import { Marker } from '@gwo/textbook-api-client/lib/interface/marker.model';
import { ActionNote } from '@gwo/textbook-api-client/lib/interface/action-note.model';
import { parseTextbooks } from '@app/shared/utils';
import { ActionTrigger } from '@gwo/textbook-api-client/lib/actions';
import { User } from '@gwo/textbook-api-client/lib/interface/user.model';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/store/reducers';
import { SingleNote } from '@gwo/textbook-api-client/lib/interface/single-note.model';

@Injectable()
export class TextbookManagerService implements TextbookManagerApi {
  constructor(private textbookClient: TextbookClient, private store: Store<AppState>) {}

  runTextbookAccess$(accessId: number): Observable<unknown> {
    return this.textbookClient.runTextbookAccess$(accessId);
  }

  createActionNote$(
    accessId: number,
    actionId: string,
    notes: SingleNote[]
  ): Observable<ActionNote> {
    return this.textbookClient.createActionNote$(accessId, actionId, notes);
  }

  deleteActionNote$(noteId: string, accessId: number): Observable<unknown> {
    return this.textbookClient.deleteActionNote$(noteId, accessId);
  }

  updateActionNote$(
    actionNoteId: string,
    accessId: number,
    notes: SingleNote[]
  ): Observable<ActionNote> {
    return this.textbookClient.updateActionNote$(actionNoteId, accessId, notes);
  }

  getAllActionNotes$(): Observable<ActionNote[]> {
    return this.textbookClient.getAllActionNotes$();
  }

  getActionNote$(actionId: string): Observable<ActionNote> {
    return this.textbookClient.getActionNote$(actionId);
  }

  createMarker$(
    accessId: number,
    pageId: string,
    distanceFromTop: number,
    distanceFromLeft: number,
    width: number,
    height: number
  ): Observable<Marker> {
    return this.textbookClient.createMarker$(
      accessId,
      pageId,
      distanceFromTop,
      distanceFromLeft,
      width,
      height
    );
  }

  updateMarker$(
    markerId: string,
    accessId: number,
    distanceFromTop: number,
    distanceFromLeft: number,
    width: number,
    height: number
  ): Observable<Marker> {
    return this.textbookClient.updateMarker$(
      markerId,
      accessId,
      distanceFromTop,
      distanceFromLeft,
      width,
      height
    );
  }

  deleteMarker$(markerId: string, accessId: number): Observable<unknown> {
    return this.textbookClient.deleteMarker$(markerId, accessId);
  }

  createNote$(
    accessId: number,
    pageId: string,
    distanceFromTop: number,
    distanceFromLeft: number,
    content: string
  ): Observable<PageNote> {
    return this.textbookClient.createNote$(
      accessId,
      pageId,
      distanceFromTop,
      distanceFromLeft,
      content
    );
  }

  updateNote$(
    noteId: string,
    accessId: number,
    distanceFromTop: number,
    distanceFromLeft: number,
    content?: string
  ): Observable<PageNote> {
    return this.textbookClient.updateNote$(
      noteId,
      accessId,
      distanceFromTop,
      distanceFromLeft,
      content
    );
  }

  deleteNote$(noteId: string, accessId: number): Observable<unknown> {
    return this.textbookClient.deleteNote$(noteId, accessId);
  }

  getTextbooks$(): Observable<Textbook[]> {
    return this.textbookClient.getTextbooks$().pipe(parseTextbooks());
  }

  getTextbooksFirstTime$(): Observable<Textbook[]> {
    return this.textbookClient.getTextbooks$().pipe(parseTextbooks());
  }

  getTextbook$(textbookId: string): Observable<Textbook> {
    return this.textbookClient.getTextbook$(textbookId);
  }

  getTextbookPages$(textbookId: string, textbookVersion: number): Observable<TextbookPage[]> {
    return this.textbookClient.getTextbookPages$(textbookId, textbookVersion);
  }

  readTextBookPagesMap(textbookId: string, textbookVersion: number): Observable<TextbookPage[]> {
    return this.textbookClient.getTextbookPages$(textbookId, textbookVersion);
  }

  getTextbookTextualPages$(
    textbookId: string,
    textbookVersion: number
  ): Observable<TextbookTextualPage[]> {
    return this.textbookClient.getTextbookTextualPages$(textbookId, textbookVersion);
  }

  getTextbookActions$(
    textbookId: string,
    textbookVersion: number,
    accessId?: number
  ): Observable<{ [actionId: string]: TextbookAction }> {
    return this.textbookClient.getTextbookActions$(textbookId, textbookVersion, accessId);
  }

  getTextbookActionById$(
    textbookId: string,
    actionId: string,
    textbookVersion: number
  ): Observable<TextbookAction> {
    return this.textbookClient.getTextbookActionById$(actionId, textbookVersion);
  }

  getResource$(resourceId: string, imageSize: ImageSize | null = null): Observable<Blob> {
    return this.textbookClient.getResource$(resourceId, imageSize);
  }

  getResourceUrl$(resourceId: string): Observable<string> {
    return of(this.textbookClient.getResourceUrl(resourceId));
  }

  getTextbookPageByTextbookIdAndPageNumber$(
    textbookId: string,
    textbookVersion: number,
    pageNumber: number
  ): Observable<TextbookPage> {
    return this.textbookClient.getTextbookPageByTextbookIdAndPageNumber$(
      textbookId,
      textbookVersion,
      pageNumber
    );
  }

  getTextbookLegend$(
    textbookId: string,
    textbookVersion: number,
    accessId?: number
  ): Observable<TextbookLegend> {
    return this.textbookClient.getTextbookLegend$(textbookId, textbookVersion, accessId);
  }

  getTextbookActionGroups$(
    textbookId: string,
    textbookVersion: number,
    accessId: number
  ): Observable<TextbookActionGroup[]> {
    return this.textbookClient.getTextbookActionGroups$(textbookId, textbookVersion, accessId);
  }

  createDrawing$(accessId: number, actionId: string, content: string): Observable<Drawing> {
    return this.textbookClient.createDrawing$(accessId, actionId, content);
  }

  updateDrawing$(drawingId: string, accessId: number, content: string): Observable<Drawing> {
    return this.textbookClient.updateDrawing$(drawingId, accessId, content);
  }

  deleteDrawing$(drawingId: string, accessId: number): Observable<unknown> {
    return this.textbookClient.deleteDrawing$(drawingId, accessId);
  }

  getDrawings$(): Observable<Drawing[]> {
    return this.textbookClient.getDrawings$();
  }

  getActionDrawing$(actionId: string): Observable<Drawing> {
    return this.textbookClient.getActionDrawing$(actionId);
  }

  getTextbookPremiumRedirection$(textbookId: string): Observable<{ link: string }> {
    return this.textbookClient.getTextbookPremiumRedirection$(textbookId);
  }

  getTextbookActionTriggers$(
    textbookId: string,
    textbookVersion: number,
    accessId?: number
  ): Observable<ActionTrigger[]> {
    return this.textbookClient.getTextbookActionTriggers$(textbookId, textbookVersion, accessId);
  }

  getTextbookMarkers$(textbookId: string): Observable<Marker[]> {
    return this.textbookClient.getTextbookMarkers$(textbookId);
  }

  getTextbookNotes$(textbookId: string): Observable<PageNote[]> {
    return this.textbookClient.getTextbookNotes$(textbookId);
  }

  getAuthenticatedUser$(): Observable<User> {
    return this.textbookClient.getAuthenticatedUser$();
  }

  downloadTextbookAccess$(accessId: number): Observable<unknown> {
    return this.textbookClient.downloadTextbookAccess$(accessId);
  }

  uninstallManyTextbookAccesses$(accessIds: number[]): Observable<unknown> {
    return this.textbookClient.uninstallManyTextbookAccesses$(accessIds);
  }
}
