import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { catchError, filter, first, map, switchMap } from 'rxjs/operators';
import { ErrorHandlerService } from './services/errorHandler.service';
import { RuntimeService } from '@app/shelf/services';
import { SystemMaintenanceFacade } from '@app/core/store/facade/system-maintenance.facade';
import { OfflineContentUtil } from '@app/core/utils/offline-content.util';
import { TextbookClientEndpointEnum } from '@gwo/textbook-api-client';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly runtimeService: RuntimeService,
    private readonly systemMaintenanceFacade: SystemMaintenanceFacade
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.systemMaintenanceFacade.appLocked$.pipe(
      first(),
      filter(
        (locked) =>
          !locked ||
          OfflineContentUtil.isMatching(request, TextbookClientEndpointEnum.GET_APP_MESSAGE)
      ),
      switchMap(() => next.handle(request).pipe(catchError((error) => this.handleError(error))))
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    console.log('PURE ERROR', error);
    return from(this.runtimeService.writeError(error)).pipe(
      switchMap(() => this.errorHandlerService.generateError(error)),
      map(($) => {
        throw $;
      })
    );
  }
}
