import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from '@app/core/services';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.scss'],
})
export class HelpModalComponent implements OnInit {
  @Output() closeClick = new EventEmitter<void>();

  readonly isAuthToken = new BehaviorSubject(false);
  readonly pathImage = `/assets/images/help/help-contents-1.png`;
  readonly deleteAccountHelpPageUrl = environment.deleteAccountHelpPageUrl;
  readonly privacyPolicyPageUrl = environment.privacyPolicyPageUrl;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    const token = this.authService.getToken();

    if (token) {
      this.isAuthToken.next(true);
    } else this.isAuthToken.next(false);
  }

  onClose(): void {
    this.closeClick.emit();
  }
}
