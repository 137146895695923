import { TextbookMorph, TextbookRecord } from '@app/shelf/models/textbook.model';
import { createAction, props } from '@ngrx/store';

export const setTextbooks = createAction(
  '[Textbooks] Set Textbooks',
  props<{ textbooks: TextbookRecord }>()
);

export const setTextbook = createAction(
  '[Textbooks] Set Textbook',
  props<{ index: string; textbook: TextbookMorph }>()
);

export const enterTextbook = createAction(
  '[Textbooks] enter textbook',
  props<{ textbook: TextbookMorph; useDownloadedVersionOffline: boolean }>()
);
