<ng-container *ngIf="name === 'search'">
  <svg viewBox="0 0 26 26">
    <path
      d="M15.601 15.65l.1-.1a8.511 8.511 0 002.65-6.4 8.8 8.8 0 00-2.7-6.45 9.127 9.127 0 00-6.5-2.7 8.861 8.861 0 00-6.5 2.7 8.779 8.779 0 00-2.65 6.45 8.111 8.111 0 002.7 6.4 8.288 8.288 0 006.45 2.75 8.431 8.431 0 006.45-2.65m1.05-6.5a6.822 6.822 0 01-2.2 5.25 6.976 6.976 0 01-5.3 2.2 7.142 7.142 0 01-5.25-2.15 7.262 7.262 0 01-2.15-5.35 7.062 7.062 0 012.1-5.2 7.484 7.484 0 0110.6 0 6.958 6.958 0 012.2 5.25m-9.05-3.9a1.381 1.381 0 00-.7-1.05 1.563 1.563 0 00-1.55.15 3.524 3.524 0 00-1.45 1.85 5.651 5.651 0 00-.1 3.55q.5 2.2.75.1.25-2.15.9-2.65a5.608 5.608 0 011.5-.9.993.993 0 00.65-1.05M19.701 16a2.129 2.129 0 00-1.4-.55 1.79 1.79 0 00-1.3.4l-.55.65a2.234 2.234 0 00-.5 1.3 1.894 1.894 0 00.5 1.45l6.1 6.05a1.741 1.741 0 001.4.5 2.062 2.062 0 001.35-.45l.55-.65a1.463 1.463 0 00.5-1.3 1.835 1.835 0 00-.55-1.35z"
      fill="var(--primary-color)"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'collapse'">
  <svg viewBox="0 0 30 16">
    <path d="M14.85 0L0 15.55h30z" fill="#6d6d6d" />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'close'">
  <svg viewBox="-8 -8 40 40">
    <g fill="none" stroke="#707070" stroke-width="2">
      <path d="M.707 20.707l20-20" />
      <path d="M.707.707l17.5 17.5 2.5 2.5" />
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="name === 'first page'">
  <svg viewBox="0 0 40 40">
    <path
      transform="rotate(180 13.57 16.25)"
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-linejoin="miter"
      stroke-miterlimit="3"
      stroke-width="1"
      d="M14.3 1.5v22"
    />
    <path
      transform="rotate(180 13.57 16.25)"
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-linejoin="miter"
      stroke-miterlimit="3"
      stroke-width="1"
      d="M.05 0L12.5 12.5 0 25"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'prev page'">
  <svg viewBox="0 0 40 40">
    <path
      transform="matrix(-1 0 0 -1 29.01 38.04)"
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-linejoin="miter"
      stroke-miterlimit="3"
      stroke-width="2"
      d="M.08 0L18 18l-18 18"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'next page'">
  <svg viewBox="0 0 40 40">
    <path
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-linejoin="miter"
      stroke-miterlimit="3"
      stroke-width="2"
      transform="matrix(1 0 0 1 10.99 1.96)"
      d="M.08 0L18 18l-18 18"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'last page'">
  <svg viewBox="0 0 40 40">
    <path
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-linejoin="miter"
      stroke-miterlimit="3"
      stroke-width="1"
      transform="translate(12.85 7.5)"
      d="M14.3 1.5v22"
    />
    <path
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-linejoin="miter"
      stroke-miterlimit="3"
      stroke-width="1"
      d="M.05 0L12.5 12.5 0 25"
      transform="translate(12.85 7.5)"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'back'">
  <svg width="35" height="34.998">
    <path
      d="M7.479 17.105h22.05"
      fill="#ef7c00"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
    />

    <path
      d="M29.529 17.101v8.886"
      fill="#ef7c00"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
    />

    <path
      d="M5.433 17.082l8.073-8.086"
      fill="#ef7c00"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
    />

    <path
      d="M5.599 17.246l8.075 8.086"
      fill="#ef7c00"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'backspace'">
  <svg viewBox="0 0 24 24">
    <path
      fill="var(--primary-color)"
      d="M22 3H7c-.69 0-1.23.35-1.59.88L0 12l5.41 8.11c.36.53.9.89 1.59.89h15a2 2 0 002-2V5a2 2 0 00-2-2zm-3 12.59L17.59 17 14 13.41 10.41 17 9 15.59 12.59 12 9 8.41 10.41 7 14 10.59 17.59 7 19 8.41 15.41 12 19 15.59z"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'gwo logo'">
  <svg viewBox="0 0 57.15 50">
    <path
      fill="#dc030e"
      d="M42.85 42.85L50 50l7.15-7.15h-14.3m-7.15-14.3l-7.15-7.15L14.3 35.7h28.55z"
    />
    <path
      fill="#ef7c00"
      d="M42.85 35.7h-14.3l7.15 7.15H50l-7.1-7.15M28.55 21.4l7.15 7.15L50 14.25H21.4l7.15 7.15M14.3 35.7h-6l10.1 10.1 10.1-10.1z"
    />
    <path fill="#b51b1a" d="M8.35 35.7h6l-7.2-7.15L0 35.7z" />
    <path fill="#fbb900" d="M35.7 0l-7.15 7.1 7.15 7.15 7.15-7.15z" />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'logout'">
  <svg viewBox="0 0 23 26.991">
    <path
      d="M19.615,5.244a.957.957,0,0,0,.549.5,8.832,8.832,0,0,1,3.182,2.084,8.742,8.742,0,0,1,2.665,6.413,8.689,8.689,0,0,1-2.665,6.4,8.689,8.689,0,0,1-6.4,2.665,8.742,8.742,0,0,1-6.413-2.665,8.715,8.715,0,0,1-2.649-6.4,8.769,8.769,0,0,1,2.649-6.413,9.018,9.018,0,0,1,3.182-2.084.9.9,0,0,0,.549-.5A.892.892,0,0,0,14.3,4.5a.956.956,0,0,0-.5-.549.983.983,0,0,0-.743-.032A10.877,10.877,0,0,0,9.164,6.456,10.638,10.638,0,0,0,5.95,14.241a10.585,10.585,0,0,0,3.214,7.769,10.556,10.556,0,0,0,6.526,3.166,12.317,12.317,0,0,0,2.52,0,10.489,10.489,0,0,0,6.51-3.166,10.559,10.559,0,0,0,3.231-7.769,10.612,10.612,0,0,0-3.231-7.786A10.7,10.7,0,0,0,20.827,3.92a.945.945,0,0,0-.727.032,1.021,1.021,0,0,0-.517.549.982.982,0,0,0,.032.743M16.627-1.7a1.087,1.087,0,0,0-.372.226.977.977,0,0,0-.275.695V13.757a.922.922,0,0,0,.275.678.949.949,0,0,0,.695.291.994.994,0,0,0,.969-.969V-.781a.949.949,0,0,0-.291-.695.917.917,0,0,0-.372-.226.924.924,0,0,0-.307-.048A1.073,1.073,0,0,0,16.627-1.7Z"
      transform="translate(-5.95 1.75)"
      fill="var(--primary-color)"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'check for updates'">
  <svg viewBox="0 0 24 24">
    <path
      fill="var(--primary-color)"
      d="M19 8l-4 4h3a6 6 0 01-8.8 5.3l-1.46 1.46A8 8 0 0020 12h3l-4-4zM6 12a6 6 0 018.8-5.3l1.46-1.46A8 8 0 004 12H1l4 4 4-4H6z"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'info'">
  <svg viewBox="0 0 85 85">
    <path
      fill="var(--primary-color)"
      d="M69.05 15.95v-.05Q58.09 5 42.5 5 27.01 5 16.05 15.9 4.99 27.02 5 42.5 5 58.1 16.1 69h-.05Q27.01 80 42.5 80q15.59 0 26.55-11Q80 58.1 80 42.5q0-15.47-10.95-26.55m-3.55 3.5Q75 29.07 75 42.5q0 13.5-9.5 22.95Q56 75 42.5 75q-13.41 0-22.9-9.55Q10 56.01 10 42.5q0-13.42 9.6-23.05Q29.09 10 42.5 10q13.51 0 23 9.45M48.8 34.8h-1.9l-10.65 1.7-.25 1.15h.95q1.05 0 1.75.55.7.6.7 1.15 0 1.3-.85 3.3l-4.3 15.05q-.55 1.7-.55 2.85 0 1.7 1.15 3 1.25 1.15 3.25 1.15 5.25 0 10.15-8.25l-1.05-.6q-2 3-3.35 4.3-.6.3-1.35.3-.25 0-.4-.2-.45-.4-.45-.65 0-.6.6-2.45L48.8 34.8m1.25-13.05Q48.8 20.3 46.9 20.3q-1.95 0-3.25 1.45-1.4 1.1-1.4 3.1 0 2.15 1.4 3.25 1.3 1.4 3.25 1.4 1.9 0 3.15-1.4 1.3-1.1 1.3-3.25 0-2-1.3-3.1z"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'download'">
  <svg viewBox="0 0 100 100">
    <path
      transform="translate(7.5 17)"
      fill="var(--primary-color)"
      d="M87.5 33.45h-5v28.9h-80v-28.9h-5v33.9h90v-33.9M45-1.3h-5v47.15l-17.1-17.1-3.55 3.55L42.5 55.4l23.1-23.1-3.5-3.55L45 45.85V-1.3z"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'pause'">
  <svg viewBox="0 0 24 24">
    <path fill="var(--primary-color)" d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'continue'">
  <svg viewBox="0 0 24 24">
    <path fill="var(--primary-color)" d="M8 5v14l11-7z" />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'delete'">
  <svg viewBox="0 0 40 40">
    <path
      transform="translate(-1.25 .9)"
      fill="var(--primary-color)"
      d="M11.25 13.4l2.9 19.4H28.4l2.85-19.4h-20m16.45 2.7q.25.35.2.75L26.05 29.8q-.05.4-.4.65-.3.25-.75.2-.4-.05-.65-.4-.25-.35-.2-.75l1.85-12.95q.05-.4.4-.65.3-.25.7-.2.45.05.7.4m-6.45-.55q.4 0 .7.3.3.3.3.7v13.1q0 .4-.3.7-.3.3-.7.3-.4 0-.7-.3-.3-.3-.3-.7v-13.1q0-.4.3-.7.3-.3.7-.3m-6.65 1.3q-.05-.4.2-.75t.7-.4q.4-.05.7.2.35.25.4.65l1.85 12.95q.05.4-.2.75t-.65.4q-.45.05-.75-.2-.35-.25-.4-.65L14.6 16.85m16.65-5.55l-.6-3.15h-6.4V5.4h-6v2.75h-6.4l-.6 3.15h20z"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'help'">
  <svg viewBox="0 0 23 26.991">
    <path
      d="M27.252,11.664l-.016-.016a10.6,10.6,0,0,0-7.786-3.2,10.593,10.593,0,0,0-7.753,3.2,10.666,10.666,0,0,0-3.247,7.8,10.533,10.533,0,0,0,3.263,7.786H11.7A10.567,10.567,0,0,0,19.45,30.45a10.575,10.575,0,0,0,7.786-3.214A10.613,10.613,0,0,0,30.45,19.45a10.7,10.7,0,0,0-3.2-7.786m-1.389,1.357h0a8.866,8.866,0,0,1,2.649,6.429,8.725,8.725,0,0,1-2.649,6.413,8.7,8.7,0,0,1-6.413,2.649,8.7,8.7,0,0,1-6.38-2.649h0a8.674,8.674,0,0,1-2.681-6.413,8.81,8.81,0,0,1,2.681-6.429,8.723,8.723,0,0,1,6.38-2.633,8.733,8.733,0,0,1,6.413,2.633M17.738,23.6a1.331,1.331,0,0,0,.388.921,1.339,1.339,0,0,0,1.744,0,1.361,1.361,0,0,0,.372-.921,1.092,1.092,0,0,0-.372-.84,1.116,1.116,0,0,0-1.744,0,1.072,1.072,0,0,0-.388.84m3.86-8.9a3.388,3.388,0,0,0-2.31-.711,3.35,3.35,0,0,0-2.213.662,2.082,2.082,0,0,0-.759,1.631,1.149,1.149,0,0,0,.258.791,1.006,1.006,0,0,0,.743.291.91.91,0,0,0,.888-.872,2.077,2.077,0,0,0-.339-1,1.22,1.22,0,0,1-.129-.42.439.439,0,0,1,.226-.339,1.176,1.176,0,0,1,.695-.194,1.147,1.147,0,0,1,.921.452,2.233,2.233,0,0,1,.436,1.5,3.846,3.846,0,0,1-.145,1.131,6.461,6.461,0,0,1-.549,1.163q-.355.759-.5,1.163a5.912,5.912,0,0,0-.032,1.308h.291a2.5,2.5,0,0,1,.291-1.211,2.727,2.727,0,0,1,1-.921,5.483,5.483,0,0,0,1.567-1.341,2.629,2.629,0,0,0,.4-1.389A2.233,2.233,0,0,0,21.6,14.7Z"
      transform="translate(-8.45 -3.45)"
      fill="var(--primary-color)"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'zoom plus'">
  <svg viewBox="0 0 28.6 28">
    <path
      fill="var(--primary-color)"
      d="M18.55 17.35a3.56 3.56 0 00-.95 1.25 1.78 1.78 0 00.3 2.25q3.25 3.3 6.65 6.5a1.99 1.99 0 002.85.1 2.2 2.2 0 00.85-3.15 1.17 1.17 0 00-.25-.4q-3.3-3.3-6.6-6.55a2.2 2.2 0 00-2.85 0z"
    />
    <path
      fill="none"
      stroke="var(--primary-color)"
      stroke-miterlimit="3"
      stroke-width="2"
      d="M4.97 9.95h10"
    />
    <path
      fill="var(--primary-color)"
      d="M17 2.9A9.98 9.98 0 009.95 0 9.6 9.6 0 002.9 2.9a9.54 9.54 0 00-2.9 7 8.78 8.78 0 002.95 6.95 8.95 8.95 0 007 3 9.27 9.27 0 007-2.85l.1-.15A9.25 9.25 0 0019.9 9.9a9.65 9.65 0 00-2.9-7m-1.3 1.35a7.42 7.42 0 012.4 5.65 7.34 7.34 0 01-2.4 5.7A7.6 7.6 0 019.95 18a7.74 7.74 0 01-5.7-2.3A7.94 7.94 0 011.9 9.85a7.52 7.52 0 012.3-5.6 7.89 7.89 0 015.75-2.4 8 8 0 015.75 2.4z"
    />
    <path
      fill="none"
      stroke="var(--primary-color)"
      stroke-miterlimit="3"
      stroke-width="2"
      d="M9.95 14.93v-10"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'zoom minus'">
  <svg viewBox="0 0 28.6 28">
    <path
      fill="var(--primary-color)"
      d="M18.55 17.35a3.56 3.56 0 00-.95 1.25 1.78 1.78 0 00.3 2.25q3.25 3.3 6.65 6.5a1.99 1.99 0 002.85.1 2.2 2.2 0 00.85-3.15 1.17 1.17 0 00-.25-.4q-3.3-3.3-6.6-6.55a2.2 2.2 0 00-2.85 0z"
    />
    <path
      fill="none"
      stroke="var(--primary-color)"
      stroke-miterlimit="3"
      stroke-width="2"
      d="M4.97 9.9h10"
    />
    <path
      fill="var(--primary-color)"
      d="M17 2.9A9.98 9.98 0 009.95 0 9.6 9.6 0 002.9 2.9a9.54 9.54 0 00-2.9 7 8.78 8.78 0 002.95 6.95 8.95 8.95 0 007 3 9.27 9.27 0 007-2.85l.1-.15A9.25 9.25 0 0019.9 9.9a9.65 9.65 0 00-2.9-7m-1.3 1.35a7.42 7.42 0 012.4 5.65 7.34 7.34 0 01-2.4 5.7A7.6 7.6 0 019.95 18a7.74 7.74 0 01-5.7-2.3A7.94 7.94 0 011.9 9.85a7.52 7.52 0 012.3-5.6 7.89 7.89 0 015.75-2.4 8 8 0 015.75 2.4z"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'normal screen'">
  <svg viewBox="0 0 32 32">
    <path
      d="M3.75 3.708l5.166 5.208"
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
    />
    <path
      d="M9.333 9.333H1"
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
    />
    <path
      d="M9.333 1v8.333"
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
    />
    <path
      d="M3.708 28.249l5.208-5.166"
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
    />

    <path
      d="M9.333 22.666v8.333"
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
    />
    <path
      d="M1 22.666h8.333"
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
    />
    <path
      d="M28.249 28.291l-5.166-5.208"
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
    />
    <path
      d="M22.666 22.666h8.333"
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
    />
    <path
      d="M22.666 30.999v-8.333"
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
    />
    <path
      d="M28.291 3.75l-5.208 5.166"
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
    />
    <path
      d="M22.666 9.333V1"
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
    />
    <path
      d="M30.999 9.333h-8.333"
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'full screen'">
  <svg viewBox="0 0 32 32">
    <path
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
      d="M7.7 7.75L1.5 1.5"
    />
    <path
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
      d="M1 1h10"
    />
    <path
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
      d="M1 11V1"
    />
    <path
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
      d="M7.75 24.3L1.5 30.5"
    />
    <path
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
      d="M1 31V21"
    />
    <path
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
      d="M11 31H1"
    />
    <path
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
      d="M24.3 24.25l6.2 6.25"
    />
    <path
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
      d="M31 31H21"
    />
    <path
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
      d="M31 21v10"
    />
    <path
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
      d="M24.25 7.7l6.25-6.2"
    />
    <path
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
      d="M31 1v10"
    />
    <path
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
      d="M21 1h10"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'menu'">
  <svg viewBox="0 0 31.25 25">
    <path [style.fill]="fill || 'var(--primary-color)'" d="M31.25 14.5v-4H0v4z" />
    <path [style.fill]="fill || 'var(--primary-color)'" d="M31.25 4V0H0v4z" />
    <path [style.fill]="fill || 'var(--primary-color)'" d="M31.25 25v-4H0v4z" />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'borders on'">
  <svg viewBox="0 0 37.75 24.2">
    <path
      fill="var(--primary-color)"
      d="M29.4 3.05L30.3 2A5.6 5.6 0 0026.25.4 5.7 5.7 0 0022.2 2a.14.14 0 01-.05.1 5.59 5.59 0 00-1.7 4.1 5.65 5.65 0 001.7 4.15.14.14 0 01.05.1 5.8 5.8 0 008.1 0l.1-.1a5.65 5.65 0 001.7-4.15 4.93 4.93 0 00-.15-1.2l-1.3.3a8.14 8.14 0 01.05.9 4.38 4.38 0 01-1.25 3.15 4.46 4.46 0 01-3.2 1.3 4.13 4.13 0 01-3.1-1.3 4.47 4.47 0 010-6.3 4.13 4.13 0 013.1-1.3 4.29 4.29 0 013.15 1.3m-.9.95a2.35 2.35 0 00-.6-.45 3.23 3.23 0 00-2.35-.4A3.05 3.05 0 0023.6 4.6a2.83 2.83 0 00-.35 2.35A2.9 2.9 0 0024.7 8.9a3.06 3.06 0 002.35.35 3.1 3.1 0 001.9-1.45 4.85 4.85 0 00.45-1.15 6.06 6.06 0 000-1.1l-3.15.65z"
    />
    <path
      fill="var(--primary-color)"
      d="M15.9 7.65a20.97 20.97 0 002.25 1.95 15.81 15.81 0 003.95 2.15 11.58 11.58 0 008.2 0 16.7 16.7 0 004-2.15 17.67 17.67 0 002.2-1.95q.55-.55.9-.95l.35-.45-.4-.55a5.7 5.7 0 00-.85-.9 16.03 16.03 0 00-2.25-1.95A15.81 15.81 0 0030.3.7a12.36 12.36 0 00-8.2 0 16.7 16.7 0 00-4 2.15 24.22 24.22 0 00-2.2 1.95q-.65.65-.85.9l-.4.55.4.45a7.6 7.6 0 00.85.95m.85-1.4a14.27 14.27 0 012.4-2.1 13.6 13.6 0 013.3-1.85A5.2 5.2 0 0126.2.8 5.37 5.37 0 0130 2.3a12.73 12.73 0 013.25 1.85 18.06 18.06 0 012.4 2.1 18.06 18.06 0 01-2.4 2.1 13.59 13.59 0 01-3.25 1.8 5.37 5.37 0 01-3.8 1.5 5.2 5.2 0 01-3.75-1.5 14.56 14.56 0 01-3.3-1.8 18.06 18.06 0 01-2.4-2.1z"
    />
    <path
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
      d="M26.2 15v8.2H1V5.7h11.3"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'borders off'">
  <svg viewBox="0 0 31.1 27.25">
    <path fill="var(--primary-color)" d="M25.2 17.05v8.2H2V9.75h11.3v-2H0v19.5h27.2v-10.2z" />
    <path
      fill="var(--primary-color)"
      d="M28.8 2.35A7.76 7.76 0 0023.1 0a7.71 7.71 0 00-5.7 2.35h.05A7.7 7.7 0 0015.1 8a7.76 7.76 0 002.35 5.7A7.7 7.7 0 0023.1 16a7.82 7.82 0 005.7-2.3A7.82 7.82 0 0031.1 8a7.75 7.75 0 00-2.3-5.65m-1.4 1.4h-.05A5.82 5.82 0 0129.1 8a5.83 5.83 0 01-1.7 4.25l-.05.05A5.83 5.83 0 0123.1 14a5.75 5.75 0 01-4.25-1.7A5.84 5.84 0 0117.1 8a5.8 5.8 0 011.75-4.25A5.73 5.73 0 0123.1 2a5.84 5.84 0 014.3 1.75M23.1 9.4l2.3 2.3 1.4-1.4-2.3-2.35 2.25-2.25-1.3-1.4-2.3 2.3-2.3-2.3-1.4 1.4 2.3 2.3-2.25 2.3 1.35 1.4z"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'teacher_comment_on'">
  <svg viewBox="0 0 40 40">
    <path
      transform="translate(2 2)"
      fill="var(--primary-color)"
      d="M29.15 22.55q1.4-2.7 1.4-6 0-5.35-3.85-9.2-3.8-3.8-9.15-3.8-5.4 0-9.25 3.8-3.75 3.85-3.75 9.2 0 5.4 3.75 9.15 3.85 3.85 9.25 3.85 3.5 0 6.35-1.65l9.85 4.6-4.6-9.95m-8.6-6.3v-5.7h2.3v12H19.6l-5.2-9.1h-.05q.15 2.4.15 3.45v5.65h-2.25v-12h3.15l5.2 8.95h.1q-.15-2.3-.15-3.25z"
    />
  </svg>
</ng-container>
<ng-container *ngIf="name === 'teacher_comment_off'">
  <svg viewBox="-5 -2 30 30">
    <path
      d="M22.339 1.944A6.425 6.425 0 0017.623 0a6.383 6.383 0 00-4.716 1.944h.043a6.8 6.8 0 00-.91 1.117 9.716 9.716 0 00-2.4-.29 9.362 9.362 0 00-6.868 2.855A9.546 9.546 0 000 12.452a9.276 9.276 0 002.772 6.826 9.3 9.3 0 006.867 2.9 9.121 9.121 0 004.711-1.245l7.322 3.392-3.428-7.405a8.783 8.783 0 001.034-3.889 6.222 6.222 0 003.061-1.7 6.47 6.47 0 001.9-4.716 6.414 6.414 0 00-1.9-4.675M21.181 3.1h-.041a4.817 4.817 0 011.448 3.516 4.827 4.827 0 01-1.407 3.516l-.041.041a4.665 4.665 0 01-1.9 1.158 5.049 5.049 0 01-1.613.248 4.758 4.758 0 01-3.516-1.407 5.021 5.021 0 01-.5-.579 4.638 4.638 0 01-.786-1.613 5.367 5.367 0 01-.165-1.365 4.85 4.85 0 01.99-3.015q.207-.247.455-.5a4.737 4.737 0 013.516-1.448 4.833 4.833 0 013.56 1.448M7.364 10.177h-.083q.124 1.737.124 2.565v4.22H5.75V7.984h2.317l3.847 6.7h.083q-.124-1.737-.124-2.4V9.929a6.475 6.475 0 001.077 1.406 6.319 6.319 0 00.662.579v5.047H11.17l-3.806-6.784m10.259-2.4l1.9 1.9 1.158-1.158-1.9-1.944 1.862-1.862-1.076-1.155-1.9 1.9-1.9-1.9-1.164 1.158 1.9 1.9-1.862 1.9 1.117 1.158z"
      fill="var(--primary-color)"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'highlighter'">
  <svg viewBox="0 0 34.408 34.408">
    <path
      d="M6.695 29.34l-1.627-1.625-2.395 2.395L4.8 31.235l1.894-1.894m7.69-2.513q.079-.002.154 0l-6.955-6.954v.154q.153 5.103-1.97 6.994L7.39 28.8q1.904-2.133 6.994-1.97M32.818 5.381l-3.787-3.788a.726.726 0 00-1.159-.077q-6.415 5.024-12.867 11.09Q11.682 15.7 8.36 19.1l6.955 6.955q3.392-3.34 6.531-6.685 6.029-6.43 11.051-12.83a.726.726 0 00-.08-1.156z"
      [style.fill]="fill || 'var(--primary-color)'"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'trash'">
  <svg viewBox="0 0 20 27.4">
    <path
      d="M0 8l2.9 19.4h14.25L20 8H0m16.45 2.7a1.033 1.033 0 01.2.75L14.8 24.4a.913.913 0 01-.4.65.956.956 0 01-.75.2.913.913 0 01-.65-.4 1.033 1.033 0 01-.2-.75l1.85-12.95a.913.913 0 01.4-.65.878.878 0 01.7-.2.948.948 0 01.7.4M10 10.15a1.025 1.025 0 011 1v13.1a1 1 0 01-2 0v-13.1a1.025 1.025 0 011-1m-6.65 1.3a1.033 1.033 0 01.2-.75.948.948 0 01.7-.4.878.878 0 01.7.2.913.913 0 01.4.65L7.2 24.1a1.033 1.033 0 01-.2.75.913.913 0 01-.65.4.956.956 0 01-.75-.2.913.913 0 01-.4-.65L3.35 11.45M20 5.9l-.6-3.15H13V0H7v2.75H.6L0 5.9z"
      fill="#6d6d6d"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'pin-grey'">
  <svg viewBox="0 0 18.7 25.65">
    <path d="M15.45 0H3.25Q0 0 0 3.25v22.4l9.35-5.2 9.35 5.2V3.25Q18.7 0 15.45 0z" fill="#6d6d6d" />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'pin'">
  <svg viewBox="-8 -3 34 34">
    <path
      d="M15.45 0H3.25Q0 0 0 3.25v22.4l9.35-5.2 9.35 5.2V3.25Q18.7 0 15.45 0z"
      [style.fill]="fill || 'var(--primary-color)'"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'pin note'">
  <svg viewBox="0 0 34 34">
    <path
      d="M22.5 10.15v12"
      stroke-linecap="round"
      stroke-linejoin="round"
      [style.fill]="fill || 'var(--primary-color)'"
      [style.stroke]="fill || 'var(--primary-color)'"
      stroke-width="2"
    />
    <path
      d="M19.4 6.9a5.118 5.118 0 011.05.125q2.05.544 2.05 3.125m0 12v10.4l-9.35-5.2-9.35 5.2V26.8"
      stroke-linecap="square"
      stroke-miterlimit="3"
      fill="none"
      [style.stroke]="fill || 'var(--primary-color)'"
      stroke-width="2"
    />
    <path
      d="M15.45 0H3.25Q0 0 0 3.25v22.4l9.35-5.2 9.35 5.2V3.25Q18.7 0 15.45 0z"
      [style.fill]="fill || 'var(--primary-color)'"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'drag'">
  <svg viewBox="0 0 30 30">
    <path
      d="M15.15 30l5.9-6.25h-12l6.1 6.25M30 15l-6.25-5.95v12L30 15m-13.2 2h-3.55v6.25h3.55V17m.2-3.65v3.5h6.25v-3.5H17M6.3 9.05L0 15.15l6.3 5.9v-12m.5 4.3v3.5h6.25v-3.5H6.8m10-6.55h-3.55v6.25h3.55V6.8M15 0L9.05 6.2h12z"
      fill="#6d6d6d"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'show-password'">
  <svg viewBox="0 0 39.988 29.387">
    <path
      d="M22.653 11.01C21.5134 10.3345 20.1564 10.127 18.867 10.431C18.2091 10.5794 17.5887 10.8616 17.0446 11.26C16.5004 11.6584 16.0441 12.1646 15.704 12.747C15.0366 13.9089 14.8494 15.2854 15.1823 16.5833C15.5152 17.8812 16.3418 18.9978 17.486 19.695C18.6459 20.3361 20.0074 20.5087 21.2906 20.1773C22.5739 19.8459 23.6815 19.0356 24.386 17.913C24.7376 17.3633 24.9516 16.737 25.01 16.087C25.1225 15.497 25.1072 14.8896 24.965 14.306L20.065 15.33L23.539 11.678C23.259 11.4336 22.9612 11.2103 22.648 11.01H22.653ZM29.419 15.33C29.4208 14.6875 29.3611 14.0463 29.241 13.415L27.058 13.86C27.1479 14.3448 27.1928 14.8369 27.192 15.33C27.2083 16.2744 27.0309 17.2122 26.6708 18.0854C26.3106 18.9586 25.7753 19.7487 25.098 20.407C24.4367 21.0837 23.6468 21.6214 22.7748 21.9885C21.9027 22.3556 20.9661 22.5447 20.02 22.5447C19.0738 22.5447 18.1372 22.3556 17.2651 21.9885C16.3931 21.6214 15.6032 21.0837 14.942 20.407C14.2646 19.7487 13.7294 18.9586 13.3692 18.0854C13.0091 17.2122 12.8316 16.2744 12.848 15.33C12.8372 14.3864 13.0172 13.4503 13.377 12.5779C13.7368 11.7055 14.2691 10.9147 14.942 10.253C16.2767 8.9278 18.0766 8.1768 19.9574 8.1602C21.8382 8.1435 23.651 8.86259 25.009 10.164L26.568 8.564C24.805 6.87633 22.4586 5.93427 20.018 5.93427C17.5774 5.93427 15.231 6.87633 13.468 8.564C13.4315 8.6038 13.4015 8.649 13.379 8.698C12.5034 9.5718 11.8088 10.6096 11.3349 11.7522C10.861 12.8948 10.617 14.1196 10.617 15.3565C10.617 16.5935 10.861 17.8183 11.3349 18.9608C11.8088 20.1034 12.5034 21.1412 13.379 22.015C13.4154 22.0548 13.4454 22.1 13.468 22.149C15.2221 23.8349 17.5606 24.7765 19.9935 24.7765C22.4264 24.7765 24.7649 23.8349 26.519 22.149L26.653 22.015C27.5481 21.1489 28.2559 20.1083 28.7325 18.9575C29.2092 17.8068 29.4446 16.5704 29.424 15.325L29.419 15.33Z"
      fill="#6D6D6D"
    />
    <path
      d="M27.07 5.753C22.4978 4.06331 17.4722 4.06331 12.9 5.753C10.4362 6.65135 8.11549 7.90165 6.00999 9.465C4.62818 10.4804 3.32971 11.6047 2.12699 12.827C1.60587 13.3311 1.1188 13.8693 0.668991 14.438L-0.00100708 15.305L0.681992 16.167C1.12948 16.7361 1.61493 17.2743 2.13499 17.778C3.33976 18.9996 4.6395 20.1238 6.02199 21.14C8.12446 22.7091 10.4459 23.9611 12.912 24.856C17.4828 26.5458 22.5072 26.5458 27.078 24.856C29.5418 23.9576 31.8625 22.7073 33.968 21.144C35.3486 20.1271 36.647 19.0029 37.851 17.782C38.3643 17.2704 38.851 16.7327 39.309 16.171L39.988 15.304L39.294 14.442C38.8465 13.8729 38.3611 13.3347 37.841 12.831C36.6362 11.6094 35.3365 10.4852 33.954 9.469C31.8526 7.90052 29.5326 6.64854 27.068 5.753H27.07ZM32.238 11.676C33.7175 12.7534 35.0904 13.9699 36.338 15.309C35.0904 16.6481 33.7175 17.8646 32.238 18.942C30.4781 20.245 28.5508 21.3052 26.508 22.094C24.7544 23.778 22.4169 24.7177 19.9857 24.716C17.5544 24.7144 15.2182 23.7714 13.467 22.085C11.424 21.3015 9.49752 20.2427 7.74099 18.938C6.2615 17.8606 4.88857 16.6441 3.64099 15.305C4.88857 13.9659 6.2615 12.7494 7.74099 11.672C9.49933 10.3711 11.4254 9.3139 13.467 8.529C15.2207 6.83966 17.561 5.89583 19.996 5.89583C22.431 5.89583 24.7713 6.83966 26.525 8.529C28.565 9.3112 30.4879 10.3698 32.24 11.675L32.238 11.676Z"
      fill="#6D6D6D"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'hide-password'">
  <svg viewBox="0 0 39.988 29.387">
    <path
      d="M22.653 11.01C21.5134 10.3345 20.1564 10.127 18.867 10.431C18.2091 10.5794 17.5887 10.8616 17.0446 11.26C16.5004 11.6584 16.0441 12.1646 15.704 12.747C15.0366 13.9089 14.8494 15.2854 15.1823 16.5833C15.5152 17.8812 16.3418 18.9978 17.486 19.695C18.6459 20.3361 20.0074 20.5087 21.2906 20.1773C22.5739 19.8459 23.6815 19.0356 24.386 17.913C24.7376 17.3633 24.9516 16.737 25.01 16.087C25.1225 15.497 25.1072 14.8896 24.965 14.306L20.065 15.33L23.539 11.678C23.259 11.4336 22.9612 11.2103 22.648 11.01H22.653ZM29.419 15.33C29.4208 14.6875 29.3611 14.0463 29.241 13.415L27.058 13.86C27.1479 14.3448 27.1928 14.8369 27.192 15.33C27.2083 16.2744 27.0309 17.2122 26.6708 18.0854C26.3106 18.9586 25.7753 19.7487 25.098 20.407C24.4367 21.0837 23.6468 21.6214 22.7748 21.9885C21.9027 22.3556 20.9661 22.5447 20.02 22.5447C19.0738 22.5447 18.1372 22.3556 17.2651 21.9885C16.3931 21.6214 15.6032 21.0837 14.942 20.407C14.2646 19.7487 13.7294 18.9586 13.3692 18.0854C13.0091 17.2122 12.8316 16.2744 12.848 15.33C12.8372 14.3864 13.0172 13.4503 13.377 12.5779C13.7368 11.7055 14.2691 10.9147 14.942 10.253C16.2767 8.9278 18.0766 8.1768 19.9574 8.1602C21.8382 8.1435 23.651 8.86259 25.009 10.164L26.568 8.564C24.805 6.87633 22.4586 5.93427 20.018 5.93427C17.5774 5.93427 15.231 6.87633 13.468 8.564C13.4315 8.6038 13.4015 8.649 13.379 8.698C12.5034 9.5718 11.8088 10.6096 11.3349 11.7522C10.861 12.8948 10.617 14.1196 10.617 15.3565C10.617 16.5935 10.861 17.8183 11.3349 18.9608C11.8088 20.1034 12.5034 21.1412 13.379 22.015C13.4154 22.0548 13.4454 22.1 13.468 22.149C15.2221 23.8349 17.5606 24.7765 19.9935 24.7765C22.4264 24.7765 24.7649 23.8349 26.519 22.149L26.653 22.015C27.5481 21.1489 28.2559 20.1083 28.7325 18.9575C29.2092 17.8068 29.4446 16.5704 29.424 15.325L29.419 15.33Z"
      fill="#6D6D6D"
    />
    <path
      d="M27.07 5.753C22.4978 4.06331 17.4722 4.06331 12.9 5.753C10.4362 6.65135 8.11549 7.90165 6.00999 9.465C4.62818 10.4804 3.32971 11.6047 2.12699 12.827C1.60587 13.3311 1.1188 13.8693 0.668991 14.438L-0.00100708 15.305L0.681992 16.167C1.12948 16.7361 1.61493 17.2743 2.13499 17.778C3.33976 18.9996 4.6395 20.1238 6.02199 21.14C8.12446 22.7091 10.4459 23.9611 12.912 24.856C17.4828 26.5458 22.5072 26.5458 27.078 24.856C29.5418 23.9576 31.8625 22.7073 33.968 21.144C35.3486 20.1271 36.647 19.0029 37.851 17.782C38.3643 17.2704 38.851 16.7327 39.309 16.171L39.988 15.304L39.294 14.442C38.8465 13.8729 38.3611 13.3347 37.841 12.831C36.6362 11.6094 35.3365 10.4852 33.954 9.469C31.8526 7.90052 29.5326 6.64854 27.068 5.753H27.07ZM32.238 11.676C33.7175 12.7534 35.0904 13.9699 36.338 15.309C35.0904 16.6481 33.7175 17.8646 32.238 18.942C30.4781 20.245 28.5508 21.3052 26.508 22.094C24.7544 23.778 22.4169 24.7177 19.9857 24.716C17.5544 24.7144 15.2182 23.7714 13.467 22.085C11.424 21.3015 9.49752 20.2427 7.74099 18.938C6.2615 17.8606 4.88857 16.6441 3.64099 15.305C4.88857 13.9659 6.2615 12.7494 7.74099 11.672C9.49933 10.3711 11.4254 9.3139 13.467 8.529C15.2207 6.83966 17.561 5.89583 19.996 5.89583C22.431 5.89583 24.7713 6.83966 26.525 8.529C28.565 9.3112 30.4879 10.3698 32.24 11.675L32.238 11.676Z"
      fill="#6D6D6D"
    />
    <path
      d="M8 3L31.733 26.73"
      stroke="#6D6D6D"
      stroke-width="4"
      stroke-miterlimit="3"
      stroke-linecap="square"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'arrow'">
  <svg viewBox="0 0 8.827 14.828">
    <path
      d="m1.445 1.414 5.968 6-6 6"
      fill="none"
      stroke="var(--primary-color)"
      stroke-linecap="square"
      stroke-miterlimit="3"
      stroke-width="2"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'warning'">
  <svg viewBox="0 0 20 20">
    <path
      d="M17.067 2.9A9.617 9.617 0 0 0 10 0a9.688 9.688 0 0 0-7.067 2.9A9.742 9.742 0 0 0 0 10a9.561 9.561 0 0 0 2.933 7.067A9.632 9.632 0 0 0 10 20a9.561 9.561 0 0 0 7.067-2.933A9.561 9.561 0 0 0 20 10a9.742 9.742 0 0 0-2.933-7.1Z"
      fill="#cf0000"
    />

    <path
      d="M9.1 15.067a1.367 1.367 0 0 0 2.333-.967 1.249 1.249 0 0 0-.4-.967 1.295 1.295 0 0 0-1.933 0 1.368 1.368 0 0 0 0 1.933M9.033 3.9a1.4 1.4 0 0 0-.4 1.033 11.8 11.8 0 0 0 .4 2.4L9.5 9.166a16.447 16.447 0 0 1 .367 2.333h.367a17.247 17.247 0 0 1 .367-2.333l.433-1.833a12.381 12.381 0 0 0 .433-2.433 1.367 1.367 0 0 0-1.4-1.4 1.4 1.4 0 0 0-1.034.4Z"
      fill="#fff"
    />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'sandbox-drawer'">
  <svg
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    preserveAspectRatio="none"
    x="0px"
    y="0px"
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
  >
    <defs>
      <g id="Layer0_0_FILL">
        <path
          [style.fill]="fill || 'var(--primary-color)'"
          stroke="none"
          d="M 291.45 707.5 L 294.5 707.5 299.65 699.4 296.7 699.4 291.45 707.5 M 313.85 707.5 L 308.6 699.4 305.65 699.4 310.85 707.5 313.85 707.5 M 307.35 704.15 L 306.85 703.1 298.5 703.1 297.95 704.15 307.35 704.15 M 299.95 679.5 L 299.95 681.6 305.3 681.6 305.3 679.5 299.95 679.5 M 292.25 682.4 Q 289.35 682.4 289.35 685.3 L 289.35 695.5 Q 289.35 698.4 292.25 698.4 L 313 698.4 Q 315.95 698.4 315.95 695.5 L 315.95 685.3 Q 315.95 682.4 313 682.4 L 292.25 682.4 M 291.85 686.4 Q 291.85 684.45 293.8 684.45 L 311.55 684.45 Q 313.45 684.45 313.45 686.4 L 313.45 694.4 Q 313.45 696.35 311.55 696.35 L 293.8 696.35 Q 291.85 696.35 291.85 694.4 L 291.85 686.4 Z"
        />
      </g>
    </defs>
    <g transform="matrix( 1.278564453125, 0, 0, 1.278564453125, -366.95,-866.7) ">
      <use xlink:href="#Layer0_0_FILL" />
    </g>
  </svg>
</ng-container>

<ng-container *ngIf="name === 'arrow-down'">
  <svg id="Warstwa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.61 15.72">
    <defs>
      <style>
        .cls-1 {
          fill: none;
          stroke: var(--primary-color);
          stroke-linecap: square;
          stroke-miterlimit: 3;
          stroke-width: 2px;
        }
      </style>
    </defs>
    <path class="cls-1" d="m27.19,1.47l-12.89,12.83L1.41,1.41" />
  </svg>
</ng-container>

<ng-container *ngIf="name === 'disconnected'">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    x="0px"
    y="0px"
    width="40px"
    height="40px"
    viewBox="0 0 40 40"
    fill="#cf0000"
  >
    <defs>
      <g id="Layer0_1_FILL">
        <path
          stroke="none"
          d="
M 33.85 19.65
Q 33.8482421875 14.1412109375 29.9 10.2 26.0109375 6.30390625 20.5 6.3 14.9916015625 6.305078125 11.05 10.2 7.155078125 14.1416015625 7.15 19.65 7.15390625 25.1609375 11.05 29.05 14.9912109375 32.9982421875 20.5 33 26.011328125 32.999609375 29.9 29.05 33.849609375 25.161328125 33.85 19.65
M 28.5 11.6
Q 31.8517578125 14.9587890625 31.85 19.65 31.850390625 24.3384765625 28.5 27.65 25.1884765625 31.000390625 20.5 31 15.8087890625 31.0017578125 12.45 27.65 9.1462890625 24.3392578125 9.15 19.65 9.1447265625 14.958203125 12.45 11.6 15.808203125 8.2947265625 20.5 8.3 25.1892578125 8.2962890625 28.5 11.6 Z"
        />
      </g>
      <g id="Layer0_3_FILL">
        <path
          stroke="none"
          d="
M 116.4 48
L 116.35 48
Q 113.3 48.05 111.2 50.15 110.05 51.4 109.45 52.8
L 111.6 54.95 111.65 54.7
Q 111.8 53.15 112.9 52 114.4 50.55 116.35 50.5
L 116.4 50.5
Q 118.35 50.55 119.85 52 120.95 53.15 121.25 54.7
L 121.3 54.85 123.35 52.75
Q 122.75 51.35 121.7 50.15 119.45 48.05 116.4 48
M 126.4 42.85
Q 128.2 44.7 129.25 46.9
L 131.15 45
Q 129.95 42.9 128.15 41 123.35 36.25 116.4 36.1
L 116.35 36.1
Q 109.55 36.25 104.6 41 102.8 42.9 101.65 45
L 103.5 46.85
Q 104.55 44.7 106.35 42.85 110.55 38.75 116.35 38.7
L 116.4 38.7
Q 122.2 38.75 126.4 42.85
M 127.1 49.05
Q 126.2 47.3 124.7 45.8 121.25 42.2 116.4 42.2
L 116.35 42.2
Q 111.45 42.2 108.05 45.8 106.55 47.35 105.75 49.1
L 107.6 50.95
Q 108.3 49.1 109.8 47.55 112.55 44.7 116.35 44.7
L 116.4 44.7
Q 120.2 44.7 122.95 47.55 124.45 49.1 125.2 50.95
L 127.1 49.05 Z"
        />
      </g>
      <g id="Layer0_1_MEMBER_0_FILL">
        <path
          stroke="none"
          d="
M 83.25 55.35
Q 83.25 54 82.3 53.05 81.35 52.1 80 52.1 78.65 52.1 77.7 53.05 76.75 54 76.75 55.35 76.75 56.7 77.7 57.65 78.65 58.6 80 58.6 81.35 58.6 82.3 57.65 83.25 56.7 83.25 55.35 Z"
        />
      </g>
      <g id="Layer0_2_FILL">
        <path
          stroke="none"
          d="
M 30.85 4.85
Q 30.233984375 4.8552734375 29.8 5.3
L 5.5 29.6
Q 5.0552734375 30.033984375 5.05 30.65 5.0552734375 31.266015625 5.5 31.7 5.933984375 32.1447265625 6.55 32.15 7.1658203125 32.14453125 7.6 31.7
L 31.9 7.4
Q 32.34453125 6.9658203125 32.35 6.35 32.3447265625 5.733984375 31.9 5.3 31.466015625 4.8552734375 30.85 4.85 Z"
        />
      </g>
    </defs>
    <g transform="matrix( 1.4169921875, 0, 0, 1.4169921875, -9.05,-7.85) ">
      <use xlink:href="#Layer0_1_FILL" />
    </g>
    <g transform="matrix( 0.8135528564453125, 0, 0, 0.812255859375, -74.7,-19.6) ">
      <use xlink:href="#Layer0_3_FILL" />
    </g>
    <g transform="matrix( 0.8761138916015625, 0, 0, 0.8747100830078125, -50.1,-20.95) ">
      <use xlink:href="#Layer0_1_MEMBER_0_FILL" />
    </g>
    <g transform="matrix( 1, 0, 0, 1, 1.3,1.5) ">
      <use xlink:href="#Layer0_2_FILL" />
    </g>
  </svg>
</ng-container>
