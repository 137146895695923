import { AccessLevel } from '@gwo/textbook-api-client/lib/interface/access-level.model';
import { createReducer, on } from '@ngrx/store';
import { DownloadRegistryActions } from '../actions';

export const downloadRegistryFeatureKey = 'downloadRegistry';

export enum DownloadingChunk {
  NULL,
  pages,
  textualPages,
  resources,
  actions,
  legend,
  actionsTriggers,
  actionsGroups,
  notes,
  markers,
  drawings,
  actionNotes,
}

export enum DownloadUnitStatus {
  free,
  process,
  paused,
  downloaded,
  updating,
  markedForDrop,
}

export interface DownloadQueueUnit {
  id: string;
  meta: Record<string, unknown>;
  index: string;
}

export interface DownloadQueueUnitPackage {
  units: DownloadQueueUnit[];
  index: number;
}

export interface DownloadRegistryUnit {
  textbookIndex: string;
  textbookId: string;
  textbookAccessId: number;
  downloadPercent: number;
  downloadQueueLength?: number;
  downloadingChunk: DownloadingChunk;
  lastDownloaded?: DownloadQueueUnit | DownloadQueueUnitPackage;
  requestedBy: Record<string, boolean>;
  status: DownloadUnitStatus;
  textbookAccessLevel: AccessLevel;
  version: number;
  expireDate?: Date;
  isUserContentModified: boolean;
}

export type DownloadRegistry = Record<string, DownloadRegistryUnit>;

const initialState: DownloadRegistry = {};

export const downloadRegistryReducer = createReducer(
  initialState,
  on(DownloadRegistryActions.setDownloadRegistry, (_state, { registry }) => registry)
);
