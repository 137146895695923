import { Injectable } from '@angular/core';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { DIRECTION_ALL } from 'hammerjs';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: DIRECTION_ALL },
  };
}

export const hammerConfig = {
  provide: HAMMER_GESTURE_CONFIG,
  useClass: HammerConfig,
};
