import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthActions } from '@app/core/store/actions';
import { selectIsFormValid } from '@app/core/store/selectors';
import { AppState } from '@app/core/store/reducers';

@Injectable()
export class AuthFacade {
  selectIsFormValid$ = this.store.select(selectIsFormValid);

  constructor(private store: Store<AppState>) {}

  loginWithCredentials(login: string, password: string): void {
    this.store.dispatch(AuthActions.loginWithCredentials({ login, password }));
  }

  logout(): void {
    return this.store.dispatch(AuthActions.logout());
  }
}
