import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorRouteQueryParam } from '@app/errors/constants/routes';
import { CoreRoute, CoreRouteParam } from '@app/core/constants';
import { TextbookDemoManagerProvider } from '@app/core/providers/textbook-manager.provider';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-demo-inactive-error',
  templateUrl: './demo-inactive-error.component.html',
  styleUrls: ['./demo-inactive-error.component.scss'],
  providers: [TextbookDemoManagerProvider],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemoInactiveErrorComponent implements OnDestroy {
  private readonly destroySubject = new Subject<void>();

  constructor(private readonly route: ActivatedRoute, private readonly router: Router) {
    this.route.queryParams
      .pipe(takeUntil(this.destroySubject.asObservable()))
      .subscribe((params) => {
        const textbookDemoId = this.route.snapshot.params[CoreRouteParam.TextbookId];
        if (Object.keys(params).includes(ErrorRouteQueryParam.RESOLVER_REDIRECTION)) {
          this.destroySubject.next();
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { [ErrorRouteQueryParam.RESOLVER_REDIRECTION]: null },
            queryParamsHandling: 'merge',
          });
        } else {
          this.router.navigate([CoreRoute.Demos, textbookDemoId]);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }
}
