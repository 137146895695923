import { createReducer, on } from '@ngrx/store';

import { ErrorActions } from '../actions';

export const errorFeatureKey = 'error';

export interface ErrorState {
  error: Error | undefined;
  hash: string | undefined;
  httpErrorStatus?: number;
}

export const initialState: ErrorState = {
  error: undefined,
  hash: undefined,
};

export const errorReducer = createReducer(
  initialState,
  on(ErrorActions.setError, (state, { error, hash }) => ({ ...state, error, hash }))
);
