<div
  #mobileOrientationBlocker
  *ngIf="
    (!mobileDetect.mobile() &&
      isIOSRequestingDesktopVersion &&
      (verticalViewAllowed$ubject | async) === false) ||
    (mobileDetect.mobile() &&
      (!mobileDetect.tablet() || (verticalViewAllowed$ubject | async) === false))
  "
  class="mobile-orientation-blocker"
>
  <div class="change-orientation-wrapper">
    <i class="fa-solid fa-rotate-right change-orientation-icon"></i>
    <div class="change-orientation-message"><strong>Proszę&nbsp;obrócić urządzenie</strong></div>
  </div>
</div>
