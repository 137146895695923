import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '@gwo/textbook-api-client/lib/interface/user.model';
import { environment } from '@environments/environment';
import { fadeInOut } from '@app/shared/animations';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut()],
})
export class HeaderComponent {
  @Input() user: Partial<User> | undefined | null;
  @Input() isLogOutVisible = true;

  @Output() logoutClick = new EventEmitter<void>();
  @Output() checkForUpdatesClick = new EventEmitter<void>();

  appMode = environment.appMode;
  isHelpOpened = false;

  constructor(private router: Router) {}

  onLogoutClick(): void {
    this.logoutClick.emit();
  }

  onHelpOpen(): void {
    this.isHelpOpened = true;
  }

  onHelpClose(): void {
    this.isHelpOpened = false;
  }

  onErrorsShowClick(): void {
    this.router.navigate(['error', 'log']);
  }
}
