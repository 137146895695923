import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authFeatureKey, AuthState } from '@app/core/store/reducers/auth.reducer';
import { AppState } from '../reducers';

export const selectAuthState = createFeatureSelector<AuthState>(authFeatureKey);

export const selectIsFormValid = createSelector<AppState, AuthState, AuthState['isFormValid']>(
  selectAuthState,
  ({ isFormValid }) => isFormValid
);
