import { createAction, props } from '@ngrx/store';
import { User } from '@gwo/textbook-api-client/lib/interface/user.model';

export const fetchSession = createAction('[Session] Fetch Session');

export const fetchProjection = createAction(
  '[Session] Fetch Projection',
  props<{ force: boolean }>()
);

export const getProjection = createAction('[Session Get] Projection', props<{ user: User }>());

export const isConnectedAndAnyRegistryUnit = createAction(
  '[Session Set] Is Connected And Any Registry Unit'
);
