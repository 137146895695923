import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, from, map, Observable, of } from 'rxjs';
import { filter, switchMap, switchMapTo } from 'rxjs/operators';

import {
  TEXTBOOK_MANAGER_TOKEN,
  TextbookManagerApi,
} from '@app/core/providers/textbook-manager.provider';

import { NetworkService } from '@app/shared/services/network.service';
import { RuntimeService } from '@app/shelf/services/runtime.service';
import { Task } from '../models/task.model';
import { TaskEnum } from '@app/shelf/models/task.enum';

const offlineMethodList = {
  uninstall: TaskEnum.UninstallManyTextbookAccesses,
};

@Injectable()
export class TaskRunnerService {
  constructor(
    @Inject(TEXTBOOK_MANAGER_TOKEN) private readonly textbookManager: TextbookManagerApi,
    private readonly runtimeService: RuntimeService,
    private readonly networkService: NetworkService
  ) {}

  private queue$ = new BehaviorSubject<Task[]>([]);

  initQueue(): Observable<void> {
    return from(this.runtimeService.readUserTasks()).pipe(
      switchMap((tasks) => this.loadTasks(tasks)),
      switchMapTo(this.runQueue())
    );
  }

  loadTasks(tasks: Task[]): Observable<void> {
    return of(tasks).pipe(map((data) => this.queue$.next(data)));
  }

  runQueue(): Observable<void> {
    return this.networkService.isOnlineMode$.pipe(
      filter(Boolean),
      switchMapTo(this.queue$.getValue()),
      switchMap((task) => this.runTask(task).pipe(map(() => this.deleteTaskFromQueue(task))))
    );
  }

  runTask(task: Task): Observable<unknown> {
    if (task.name.includes(offlineMethodList.uninstall)) {
      const accessIds = task.payload as number[];
      return this.textbookManager.uninstallManyTextbookAccesses$(accessIds);
    }
    return of(undefined);
  }

  deleteTaskFromQueue(taskToDelete: Task): void {
    this.runtimeService
      .writeUserTasks(this.getRestTasks(taskToDelete))
      .then(() => this.updateQueue());
  }

  async addTask(newTask: Task): Promise<unknown> {
    await this.runtimeService.writeUserTasks(this.handleAddedTask(newTask));
    return this.updateQueue();
  }

  handleAddedTask(newTask: Task): Task[] {
    const isOccurs = this.checkIfTaskOccursInQueue(newTask);
    if (isOccurs?.name.includes(offlineMethodList.uninstall)) {
      const mergeNewTaskWithOccur = this.handleUninstallTask(isOccurs, newTask);
      const restTasks = this.getRestTasks(newTask);
      return [...restTasks, mergeNewTaskWithOccur];
    } else {
      return [...this.queue$.getValue(), newTask];
    }
  }

  checkIfTaskOccursInQueue(newTask: Task): Task | undefined {
    return this.queue$.getValue().find((task) => task.name === newTask.name);
  }

  handleUninstallTask(occurTask: Task, newTask: Task): Task {
    const oldPayload = [...(occurTask?.payload as number[])];
    const newPayload = newTask.payload as number[];
    const uniqValue = new Set([...oldPayload, ...newPayload]);
    const mergeTask: Task = {
      name: offlineMethodList.uninstall,
      payload: [...(uniqValue as unknown as number[])],
    };
    return mergeTask;
  }

  getRestTasks(newTask: Task): Task[] {
    return this.queue$.getValue().filter((task) => task.name !== newTask.name);
  }

  updateQueue(): void {
    this.runtimeService.readUserTasks().then((updateQueue) => this.queue$.next(updateQueue));
  }
}
