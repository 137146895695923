import { ChangeDetectorRef, Component } from '@angular/core';
import { RuntimeService } from '@app/shelf/services';
import { from } from 'rxjs';
import { environment } from '@environments/environment';
import { NetworkService } from '@app/shared/services/network.service';
import { UserFacade } from '@app/core/store/facade/user.facade';
import { version } from '../../../../../package.json';

@Component({
  selector: 'app-error-log',
  templateUrl: './error-log.component.html',
  styleUrls: ['./error-log.component.scss'],
})
export class ErrorLogComponent {
  readonly platform = environment.appMode ? 'MOBILE' : 'WEB';
  readonly isOnlineMode$ = this.networkService.isOnlineMode$;
  readonly user$ = this.userFacade.selectUser$;
  readonly version = version;
  readonly productionMode = environment.production;

  constructor(
    private readonly runtimeService: RuntimeService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly networkService: NetworkService,
    private readonly userFacade: UserFacade
  ) {}

  errorsList$ = from(this.runtimeService.readErrorFolder());

  currentError?: string;
  currentErrorName?: string;

  async loadError(fileName: string): Promise<void> {
    const data = await this.runtimeService.readErrorFile(fileName);
    this.currentError = JSON.stringify(data, null, 2);
    this.currentErrorName = fileName;
    this.changeDetector.detectChanges();
  }

  exit(): void {
    this.currentError = undefined;
  }
}
