import { Inject, Injectable } from '@angular/core';
import { TextbookMorph } from '@app/shelf/models/textbook.model';
import { ActionOperators } from '@app/core/models';
import { DownloadRegistryFacade, TextbooksFacade } from '@app/shelf/store/facade';
import { Observable, of } from 'rxjs';
import { filter, map, mapTo, switchMap } from 'rxjs/operators';
import { Task } from '@app/shelf/models/task.model';
import { TaskRunnerService } from '@app/shelf/services/task-runner.service';
import {
  TEXTBOOK_MANAGER_TOKEN,
  TextbookManagerApi,
} from '@app/core/providers/textbook-manager.provider';
import { TaskEnum } from '@app/shelf/models/task.enum';

@Injectable()
export class DownloadAccessService {
  constructor(
    private readonly textbooksFacade: TextbooksFacade,
    private readonly taskRunnerService: TaskRunnerService,
    @Inject(TEXTBOOK_MANAGER_TOKEN) private textbookManager: TextbookManagerApi,
    private readonly downloadRegistryFacade: DownloadRegistryFacade
  ) {}

  downloadTextbookAccess(textbook: TextbookMorph): Observable<unknown> {
    const {
      access: { accessId },
    } = textbook;

    return of(textbook).pipe(
      switchMap((textbook) => this.isFirstDownloadAttempt(textbook)),
      map(() => this.updateDownloadsFieldInStore(textbook, ActionOperators.Increment)),
      switchMap(() => this.textbookManager.downloadTextbookAccess$(accessId))
    );
  }

  isFirstDownloadAttempt(textbook: TextbookMorph): Observable<boolean> {
    return this.downloadRegistryFacade.getDownloadRegistryUnit(textbook.index).pipe(
      map((unit) => !unit),
      filter(Boolean)
    );
  }

  uninstallManyTextbookAccesses(textbook: TextbookMorph): Observable<unknown> {
    const {
      access: { accessId },
    } = textbook;
    return of(textbook).pipe(
      map((textbook) => this.updateDownloadsFieldInStore(textbook, ActionOperators.Decrement)),
      mapTo(this.uninstallTextbook(accessId))
    );
  }

  updateDownloadsFieldInStore(textbook: TextbookMorph, operator: ActionOperators): void {
    const textbookWithUpdateDownloadField = {
      ...textbook,
      access: {
        ...textbook.access,
        downloads: DownloadAccessService.handleValue(textbook.access.downloads, operator),
      },
    };

    this.handleDownloadsFieldInStore(textbookWithUpdateDownloadField);
  }

  private static handleValue(downloads: number, operator: ActionOperators): number {
    return downloads + Number(operator);
  }

  private handleDownloadsFieldInStore(textbook: TextbookMorph): void {
    return this.textbooksFacade.setTextbook(textbook.index, textbook);
  }

  private uninstallTextbook(accessId: number): Promise<unknown> {
    const task: Task = {
      name: TaskEnum.UninstallManyTextbookAccesses,
      payload: [accessId],
    };
    return this.taskRunnerService.addTask(task);
  }
}
