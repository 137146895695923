import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ShelfStatus } from '@app/core/models';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ShelfService {
  readonly isActiveTextbookDetailsModal$ = new BehaviorSubject<boolean>(false);
  readonly isActiveOfflineWarningModal$ = new BehaviorSubject<boolean>(false);
  readonly isActiveConfirmDownloadModal$ = new BehaviorSubject<boolean>(false);
  readonly isActiveConfirmDeleteModal$ = new BehaviorSubject<boolean>(false);
  readonly isActiveFinishedOrExpiredModalModal$ = new BehaviorSubject<boolean>(false);
  readonly isActiveNoFreeMemoryModal$ = new BehaviorSubject<boolean>(false);
  readonly isActiveExhaustedDownloadLimitModal$ = new BehaviorSubject<boolean>(false);
  readonly isActiveUpdateTextbookModal$ = new BehaviorSubject<boolean>(false);
  readonly isActiveUpdateTextbookOnDemandModal$ = new BehaviorSubject<boolean>(false);
  private isShelfActive = new BehaviorSubject<boolean>(false);
  readonly isShelfActive$ = this.isShelfActive
    .asObservable()
    .pipe(tap((active) => !active && this.shelfInitialization.next(false)));
  private readonly shelfInitialization = new BehaviorSubject<boolean>(false);
  readonly shelfInitializationDone$ = this.shelfInitialization.asObservable();

  setShelfStatus(status: ShelfStatus): void {
    this.isShelfActive.next(status === ShelfStatus.Show);
  }

  shelfInitializationDone(): void {
    this.shelfInitialization.next(true);
  }
}
