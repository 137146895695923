import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthActions, UserActions } from '@app/core/store/actions';
import { catchError, combineLatestWith, concatMap, filter, pluck, switchMap } from 'rxjs/operators';
import { AuthService, TOKEN_KEY } from '@app/core/services';
import { map, of } from 'rxjs';
import { Router } from '@angular/router';
import { CoreRoute } from '@app/core/constants';
import { void$ } from '@app/shared/utils';
import { NetworkService } from '@app/shared/services/network.service';
import { RuntimeService } from '@app/shelf/services';
import { environment } from '@environments/environment';

@Injectable()
export class AuthEffects {
  loginWithCredential$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginWithCredentials),
      switchMap(({ login, password }) =>
        this.authService.loginWithCredentials$(login, password).pipe(
          pluck(TOKEN_KEY),
          switchMap((token) => {
            this.authService.setToken(token);
            this.authService.changeUserLoginStatus(false);
            return [UserActions.updateUserAndRedirectToShelf(), UserActions.updateUser()];
          }),
          catchError(() => of(AuthActions.setFormValid({ isFormValid: false })))
        )
      )
    )
  );

  loginWithToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginWithToken),
      switchMap(({ token, accessId }) =>
        this.authService.loginWithToken$(token, accessId).pipe(
          pluck(TOKEN_KEY),
          map((token) => {
            this.authService.setToken(token);
            this.authService.changeUserLoginStatus(false);
            return UserActions.updateUser();
          })
        )
      )
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      switchMap(() => this.authService.logout$().pipe(catchError(() => void$()))),
      switchMap(() => this.runtimeService.clearTemporaryData()),
      map(() => {
        this.authService.clearToken();
        return AuthActions.logoutSuccess();
      })
    )
  );

  logoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logoutSuccess),
        map(() => this.router.navigate([CoreRoute.Auth]))
      ),
    { dispatch: false }
  );

  recreateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.recreateUser),
      concatMap(({ accessId }) =>
        this.authService.recreateAuthenticatedUser(
          environment.editorPreviewMode ? undefined : accessId
        )
      ),
      combineLatestWith(this.networkService.isOnlineMode$),
      switchMap(([user, online]) =>
        online ? [UserActions.setUserData({ user }), AuthActions.recreateUserSuccess()] : []
      )
    )
  );

  recreateUserToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.recreateUserToken),
        switchMap(() => this.networkService.isOnlineMode$),
        filter(Boolean),
        switchMap(() => this.authService.recreateUserToken$()),
        map(({ token }) => this.authService.setToken(token))
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private readonly networkService: NetworkService,
    private readonly runtimeService: RuntimeService
  ) {}
}
