import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Marker } from '@gwo/textbook-api-client/lib/interface/marker.model';
import {
  TEXTBOOK_MANAGER_TOKEN,
  TextbookManagerApi,
} from '@app/core/providers/textbook-manager.provider';

@Injectable()
export class MarkerService {
  private currentActiveMarkerId$ubject = new BehaviorSubject<string | null>(null);

  constructor(@Inject(TEXTBOOK_MANAGER_TOKEN) private textbookManager: TextbookManagerApi) {}

  get currentActiveMarkerId$(): Observable<string | null> {
    return this.currentActiveMarkerId$ubject.asObservable();
  }

  createMarker$(
    accessId: number,
    pageId: string,
    distanceFromTop: number,
    distanceFromLeft: number,
    width: number,
    height: number
  ): Observable<Marker> {
    return this.textbookManager.createMarker$(
      accessId,
      pageId,
      distanceFromTop,
      distanceFromLeft,
      width,
      height
    );
  }

  updateMarker$(
    markerId: string,
    accessId: number,
    distanceFromTop: number,
    distanceFromLeft: number,
    width: number,
    height: number
  ): Observable<Marker> {
    return this.textbookManager.updateMarker$(
      markerId,
      accessId,
      distanceFromTop,
      distanceFromLeft,
      width,
      height
    );
  }

  updateActiveMarkerId(markerId: string | null): void {
    this.currentActiveMarkerId$ubject.next(markerId);
  }

  deleteMarker$(markerId: string, accessId: number): Observable<unknown> {
    return this.textbookManager.deleteMarker$(markerId, accessId);
  }
}
