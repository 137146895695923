import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, Observable } from 'rxjs';
import { SelectedTextbookActions } from '../actions';
import { DownloadRegistryUnit, ShelfState } from '../reducers';
import { SelectedTextbook } from '../reducers/selected-textbook';
import { SelectedTextbookSelectors } from '../selectors';

@Injectable()
export class SelectedTextbookFacade {
  constructor(private readonly store: Store<ShelfState>) {}

  setSelectedTextbook(textbook: DownloadRegistryUnit, isOutdated: boolean): void {
    this.store.dispatch(SelectedTextbookActions.setSelectedTextbook({ textbook, isOutdated }));
  }

  cleanSelectedTextbook(): void {
    this.store.dispatch(SelectedTextbookActions.setSelectedTextbook({ textbook: null }));
  }

  getSelectedTextbook(): Observable<SelectedTextbook> {
    return this.store.select(SelectedTextbookSelectors.selectSelectedTextbook).pipe(first());
  }

  isOutdatedTextbookSelected(): Observable<boolean> {
    return this.store.select(SelectedTextbookSelectors.isSelectedTextbookOutdated);
  }
}
