import { Observable, Subscriber } from 'rxjs';
import { ResizeObserver, ResizeObserverEntry } from '@juggle/resize-observer';

export const resizeObservable = (elem: HTMLElement): Observable<ResizeObserverEntry[]> =>
  new Observable((observer: Subscriber<ResizeObserverEntry[]>) => {
    const resizeObserver = new ResizeObserver((entries) => {
      observer.next(entries);
    });

    resizeObserver.observe(elem);
    return () => resizeObserver.unobserve(elem);
  });
